import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import { useForm, useStore } from "@tanstack/react-form";
import { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button } from "src/components/base/button";
import { Dialog, DialogActions, DialogBody, DialogProps, DialogTitle } from "src/components/base/dialog";
import { Field, Label } from "src/components/base/fieldset";
import FileInput from "src/components/base/file-input";
import Form from "src/components/base/form";
import { Text } from "src/components/base/text";
import { Listbox, ListboxLabel, ListboxOption } from "src/components/base/listbox";
import { ReorderItem, ReorderList } from "src/components/base/reorder-list";
import { Input } from "src/components/base/input";
import clsx from "clsx";

/**
 * Remap object that may be passed into the lucy exporter.
 */
export type RemapItem = null | {
    /**
     * Internal unique ID, not used inside the scripts but needed to identify
     * moving around items through react refs.
     */
    id: string;
    /**
     * Display name for the remap list, used in UI as well as in report whenever
     * indicating what the remap list displays.
     */
    name: string;
    /**
     * Fallback group for employees not inside the divisions list.
     */
    fallback: string;
    /**
     * Map of emails to the new divisions / groups that should be used instead
     * of the customer given divisions saved in lucy.
     */
    divisions: {
        [email: string]: string;
    };
};

/**
 * React props for RemapPicker
 */
export type RemapPickerProps = {
    /**
     * Current remap value.
     */
    value: RemapItem;
    /**
     * Called whenever the remap value should be changed (e.g. through user input)
     *
     * @param value New remap value
     * @returns Nothing
     */
    onChange: (value: RemapItem) => void;
};

/**
 * Allows replacing the native lucy list with a single remap result. Used
 * whenever there is only a single variant of a graph that should be replaced.
 *
 * @param props React props
 * @returns React component
 */
export function RemapPicker(props: RemapPickerProps) {
    const [t] = useTranslation("script-remap-picker");
    const [adding, setAdding] = useState(false);

    return (
        <>
            <div
                className={clsx([
                    "flex items-center justify-between",
                    // Basic layout
                    "relative block w-full appearance-none rounded-lg px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing[3])-1px)] sm:py-[calc(theme(spacing[1.5])-1px)]",
                    // Typography
                    "text-base/6 text-zinc-950 placeholder:text-zinc-500 sm:text-sm/6 dark:text-white",
                    // Border
                    "border border-zinc-950/10 data-[hover]:border-zinc-950/20 dark:border-white/10 dark:data-[hover]:border-white/20",
                    // Background color
                    "bg-transparent dark:bg-white/5",
                ])}
            >
                {props.value === null ? (
                    <div className="text-center">{t("label.raw-item")}</div>
                ) : (
                    <div className="text-center">{props.value.name}</div>
                )}
                <Button
                    plain={true}
                    onClick={() => {
                        if (props.value === null) setAdding(true);
                        else props.onChange(null);
                    }}
                >
                    {props.value === null ? (
                        <PencilIcon className="pointer-events-none" />
                    ) : (
                        <TrashIcon className="pointer-events-none" />
                    )}
                </Button>
            </div>
            <Suspense>
                <AddRemapDialog
                    open={adding}
                    onClose={() => setAdding(false)}
                    onSubmit={(v) => {
                        setAdding(false);
                        props.onChange(v);
                    }}
                />
            </Suspense>
        </>
    );
}

/**
 * React props for RemapPickerList
 */
export type RemapPickerListProps = {
    /**
     * Current remap value.
     */
    value: RemapItem[];
    /**
     * Called whenever the remap value should be changed (e.g. through user input)
     *
     * @param value New remap value
     * @returns Nothing
     */
    onChange: (value: RemapItem[]) => void;
};

/**
 * Allows inserting multiple remap lists as well as reordering them and making
 * it possible to move remaps before the raw lucy results. This is used whenever
 * multiple variants of the graphs are generated (e.g. in a report).
 *
 * @param props React props
 * @returns React component
 */
export function RemapPickerList(props: RemapPickerListProps) {
    const [t] = useTranslation("script-remap-picker");
    const [adding, setAdding] = useState(false);

    return (
        <div className="flex flex-col">
            <ReorderList axis={"y"} values={props.value} onReorder={props.onChange} className="my-4">
                {props.value.map((map, idx) =>
                    map === null ? (
                        <ReorderItem key={"raw"} value={map}>
                            <div className="text-center">{t("label.raw-item")}</div>
                        </ReorderItem>
                    ) : (
                        <ReorderItem key={map.id} value={map} className="flex items-center justify-between">
                            <div>{map.name}</div>
                            <Button
                                plain={true}
                                onClick={() => {
                                    const newList = [...props.value];
                                    newList.splice(idx, 1);
                                    props.onChange(newList);
                                }}
                            >
                                <TrashIcon className="pointer-events-none" />
                            </Button>
                        </ReorderItem>
                    ),
                )}
            </ReorderList>
            <Button outline className="w-50 m-auto mb-3 !block" onClick={() => setAdding(true)}>
                {t("button.add")}
            </Button>
            <Suspense>
                <AddRemapDialog
                    open={adding}
                    onClose={() => setAdding(false)}
                    onSubmit={(v) => {
                        setAdding(false);
                        props.onChange([...props.value, v]);
                    }}
                />
            </Suspense>
        </div>
    );
}

/**
 * React props for AddRemapDialog
 */
type AddRemapDialogProps = Omit<DialogProps, "onSubmit"> & {
    /**
     * Called when dialog is confirmed.
     *
     * @param item Result RemapItem (never null)
     * @returns Nothing
     */
    onSubmit: (item: RemapItem & {}) => void;
};

/**
 * Dialog that allows uploading a remap CSV file. Performs the parsing
 * immediately and gives out the parsed content that can be passed to the
 * scripts on submit.
 *
 * @param param0 react props
 * @param param0.onSubmit onSubmit callback
 * @returns React component
 */
function AddRemapDialog({ onSubmit, ...dialogProps }: AddRemapDialogProps) {
    const [t] = useTranslation("script-remap-picker");

    const [csv, setCsv] = useState<string[][]>([]);
    const headers = csv[0] || [];

    const form = useForm({
        defaultValues: {
            file: null as File | null,
            name: "zusammengefasste Gruppen",
            fallbackGroup: "",
            emailColumn: 0,
            remapColumn: 1,
        },

        // eslint-disable-next-line
        onSubmit: ({ value }) => {
            if (!value.file) return toast.error(t("error.no-file-selected"));
            onSubmit({
                id: value.file.name + value.file.lastModified,
                name: value.name,
                fallback: value.fallbackGroup,
                divisions: Object.fromEntries(
                    csv.slice(1).map((entry) => [entry[value.emailColumn], entry[value.remapColumn]]),
                ),
            });
        },
    });

    useEffect(() => {
        setCsv([]);
        form.reset();
    }, [dialogProps.open]);

    const file = useStore(form.store, (f) => f.values.file);

    return (
        <Dialog {...dialogProps}>
            <Form onSubmit={form.handleSubmit}>
                <DialogTitle>{t("label.dialog-heading")}</DialogTitle>
                <DialogBody className={"flex flex-col gap-6"}>
                    <form.Field name={"file"}>
                        {(fieldApi) => (
                            <Field>
                                <Label>{t("label.file")}</Label>
                                <FileInput
                                    accept={"text/csv"}
                                    onChange={(e) => {
                                        if (!e.target.files) return fieldApi.handleChange(null);
                                        const f = e.target.files[0];
                                        setCsv([]);
                                        fieldApi.handleChange(null);
                                        toast.promise(
                                            decodeText(f).then((decoded) => {
                                                setCsv(parseCSV(decoded, f.type));
                                                fieldApi.handleChange(f);
                                            }),
                                            {
                                                pending: t("label.parsing-csv"),
                                                error: t("error.parse-error"),
                                            },
                                        );
                                    }}
                                />
                            </Field>
                        )}
                    </form.Field>
                    <form.Field name={"emailColumn"}>
                        {(fieldApi) => (
                            <Field disabled={file === null}>
                                <Label>{t("label.email-column")}</Label>
                                <Listbox value={fieldApi.state.value} onChange={(e) => fieldApi.handleChange(e)}>
                                    {headers.map((header, i) => (
                                        <ListboxOption value={i}>
                                            <ListboxLabel>{header || `(column #${i + 1})`}</ListboxLabel>
                                        </ListboxOption>
                                    ))}
                                </Listbox>
                            </Field>
                        )}
                    </form.Field>
                    <form.Field name={"remapColumn"}>
                        {(fieldApi) => (
                            <Field disabled={file === null}>
                                <Label>{t("label.remap-column")}</Label>
                                <Listbox value={fieldApi.state.value} onChange={(e) => fieldApi.handleChange(e)}>
                                    {headers.map((header, i) => (
                                        <ListboxOption value={i}>
                                            <ListboxLabel>{header || `(column #${i + 1})`}</ListboxLabel>
                                        </ListboxOption>
                                    ))}
                                </Listbox>
                            </Field>
                        )}
                    </form.Field>
                    <form.Field name={"fallbackGroup"}>
                        {(fieldApi) => (
                            <Field disabled={file === null}>
                                <Label>{t("label.fallback-group")}</Label>
                                <Input
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                                <Text>{t("label.fallback-group-description")}</Text>
                            </Field>
                        )}
                    </form.Field>
                    <form.Field name={"name"}>
                        {(fieldApi) => (
                            <Field disabled={file === null}>
                                <Label>{t("label.remap-name")}</Label>
                                <Input
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                                <Text>{t("label.remap-name-description")}</Text>
                            </Field>
                        )}
                    </form.Field>
                </DialogBody>
                <DialogActions>
                    <Button type={"submit"} color={"blue"}>
                        {t("button.confirm-add")}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

/**
 * Parses a CSV file.
 *
 * @param content Full CSV file content
 * @param type Encoding type, just used in case of error, indicating a different
 * error in case the user did not upload a CSV.
 * @returns The CSV file parsed as array. Each item is a row, each row has
 * multiple cells. The array may be ragged, depending on the input content.
 */
function parseCSV(content: string, type: string): string[][] {
    const allowed_seps = ["\t", ";", ","];
    const res: string[][] = [];
    let separator: string | undefined = undefined;
    for (const line of content.split(/[\r\n]+/g)) {
        if (line.trim().length == 0) continue;
        if (separator === undefined) {
            for (const sep of allowed_seps) {
                if (line.split(sep).length > 1) {
                    separator = sep;
                    break;
                }
            }
        }
        if (separator === undefined) {
            if (type.startsWith("application/vnd"))
                throw new Error("did not upload a CSV file, uploading excel files is not supported.");
            throw new Error("malformed CSV, no separator");
        }

        res.push(splitQuoted(line, separator));
    }
    return res;
}

/**
 * Splits a string by separator, allowing to use quotes for values (CSV format)
 *
 * Explicitly does not support multiline values.
 *
 * Automatically resolves certain kinds of encoding issues that happen in some
 * CSV/table editors. This is currently having the entire line quoted as a
 * string (e.g. `"value;value;value;value"`) which happens way more often than I
 * would have ever assumed.
 *
 * @param line CSV line input
 * @param separator Separator to use
 * @returns `line.split(separator)`, but not splitting inside quoted values.
 */
function splitQuoted(line: string, separator: string): string[] {
    let quote = line.indexOf('"');
    if (quote === -1) return line.split(separator);

    const res: string[] = [];
    let start = 0;
    while (quote != -1) {
        let before = line.substring(start, quote);
        if (before.trim().length != 0) {
            if (before.endsWith(separator)) before = before.substring(0, before.length - 1);
            res.push(...before.split(separator));
        }
        let end = line.indexOf('"', quote + 1);
        while (end != -1 && line[end + 1] === '"') end = line.indexOf('"', end + 2);
        if (end == -1) throw new Error("malformed CSV, broken quotes or multiline text?");
        res.push(line.substring(quote + 1, end).replace(/""/g, '"'));
        start = end + 1;
        if (line[start] === separator) start++;
        quote = line.indexOf('"', start);
    }
    const remaining = line.substring(start);
    if (remaining.trim().length != 0) {
        res.push(...remaining.split(separator));
    }

    if (res.length == 1 && line.startsWith('"') && line.endsWith('"'))
        return splitQuoted(line.substring(1, line.length - 1), separator);

    return res;
}

/**
 * Attemps to decode the the given File/Blob with multiple common text encodings
 * that our customers use. Falls back to UTF-8 if no encoding decodes without
 * issues.
 *
 * @param f File object / Blob to read without known text encoding
 * @returns The text content of the File/Blob with best guessed encoding
 */
async function decodeText(f: Blob): Promise<string> {
    const encodings = ["utf-8", "windows-1252"];
    const bytes = await f.arrayBuffer();
    let fallback: string | undefined = undefined;
    for (const encoding of encodings) {
        const decoder = new TextDecoder(encoding);
        const res = decoder.decode(bytes);
        if (fallback === undefined) fallback = res;
        if (res.indexOf("�") === -1) return res;
    }
    return fallback!;
}
