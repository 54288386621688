import { createFileRoute } from "@tanstack/react-router";

import React, { Suspense } from "react";
import { Api, UUID } from "src/api/api";
import { toast } from "react-toastify";
import { SimpleOfferPotentialTopic } from "src/api/generated";
import { useTranslation } from "react-i18next";
import HeadingLayout from "src/components/base/heading-layout";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from "src/components/base/dropdown";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Button } from "src/components/base/button";
import DialogCreateOfferSuggestion from "src/components/dialogs/internal/dialog-create-offer-suggestion";
import DialogDelete from "src/components/dialogs/dialog-delete";
import { Dialog, DialogActions, DialogTitle } from "src/components/base/dialog";
import Form from "src/components/base/form";
import { ErrorMessage, Field, FieldGroup, Fieldset, Label, RequiredLabel } from "src/components/base/fieldset";
import { useForm } from "@tanstack/react-form";
import { Input } from "src/components/base/input";

/**
 * The properties for {@link OffersOverview}
 */
export type OffersOverviewProps = {};

/**
 * The overview of all offers
 */
function OffersOverview(props: OffersOverviewProps) {
    const [t] = useTranslation("internal-offer-suggestions-overview");
    const [tg] = useTranslation();

    const [offerSuggestions, setOfferSuggestions] = React.useState<Array<SimpleOfferPotentialTopic>>([]);
    const [openCreate, setOpenCreate] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState<SimpleOfferPotentialTopic>();
    const [openEdit, setOpenEdit] = React.useState<SimpleOfferPotentialTopic>();

    const form = useForm({
        defaultValues: {
            name: openEdit ? openEdit.name : "",
            category: openEdit ? openEdit.category : "",
        },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            if (!openEdit) {
                return;
            }

            const res = await Api.internal.offerPotentialTopics.update(openEdit.uuid, {
                name: value.name,
                category: value.category,
            });
            if (res.isErr) {
                toast.error(res.err.message);
                return;
            }

            if (res.ok.result === "Err") {
                formApi.setFieldMeta("name", (meta) => {
                    meta.errors.push(tg("error.name-in-use"));
                    return meta;
                });
            } else {
                await refreshOfferSuggestions();
                setOpenEdit(undefined);
                form.reset();
            }
        },
    });

    /**
     * Refresh the offers
     */
    const refreshOfferSuggestions = async () => {
        const res = await Api.internal.offerPotentialTopics.all();
        if (res.isErr) {
            toast.error(res.err.message);
        } else {
            setOfferSuggestions(res.ok.list);
        }
    };

    /**
     * Delete an offer suggestion
     *
     * @param uuid The uuid of the suggestion
     */
    const deleteOfferSuggestion = async (uuid: UUID) => {
        const _ = uuid;
        toast.info("not implemented yet");
    };

    React.useEffect(() => {
        refreshOfferSuggestions().then();
    }, []);

    return (
        <>
            <HeadingLayout
                heading={t("heading.offers-overview")}
                headingDescription={t("description.offers-overview")}
                headingChildren={<Button onClick={() => setOpenCreate(true)}>{t("button.create-offer")}</Button>}
            >
                <Table dense={true}>
                    <TableHead>
                        <TableRow>
                            <TableHeader>{t("label.offer-name")}</TableHeader>
                            <TableHeader>{t("label.category")}</TableHeader>
                            <TableHeader>{t("label.type")}</TableHeader>
                            <TableHeader>{t("label.label")}</TableHeader>
                            <TableHeader className={"w-0"}>
                                <span className={"sr-only"}>{tg("accessibility.actions")}</span>
                            </TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {offerSuggestions.map((o) => (
                            <TableRow key={o.uuid}>
                                <TableCell>{o.name}</TableCell>
                                <TableCell>{o.category}</TableCell>
                                <TableCell>
                                    {o.data.type === "None"
                                        ? t("label.type-none")
                                        : o.data.type === "Size"
                                          ? t("label.type-size")
                                          : t("label.type-custom")}
                                </TableCell>
                                <TableCell>
                                    {o.data.type === "Size" || o.data.type === "Custom" ? o.data.label : ""}
                                </TableCell>
                                <TableCell>
                                    <Dropdown>
                                        <DropdownButton plain={true}>
                                            <EllipsisVerticalIcon />
                                        </DropdownButton>
                                        <DropdownMenu anchor={"bottom end"}>
                                            <DropdownItem onClick={() => setOpenEdit(o)}>
                                                <DropdownLabel>{t("button.edit")}</DropdownLabel>
                                            </DropdownItem>
                                            <DropdownItem onClick={() => setOpenDelete(o)}>
                                                <DropdownLabel>{tg("button.delete")}</DropdownLabel>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </HeadingLayout>

            {openDelete && (
                <DialogDelete
                    title={t("heading.delete-offer", { name: openDelete.name })}
                    onYes={() => {
                        openDelete && deleteOfferSuggestion(openDelete.uuid).then(() => setOpenDelete(undefined));
                    }}
                    onNo={() => setOpenDelete(undefined)}
                    onClose={() => setOpenDelete(undefined)}
                />
            )}

            {openCreate && (
                <Suspense>
                    <DialogCreateOfferSuggestion
                        open={true}
                        onCreate={async () => {
                            await refreshOfferSuggestions();
                            setOpenCreate(false);
                        }}
                        onClose={() => setOpenCreate(false)}
                    />
                </Suspense>
            )}

            {openEdit && (
                <Dialog open={true} onClose={() => setOpenEdit(undefined)}>
                    <Form onSubmit={form.handleSubmit}>
                        <Fieldset>
                            <DialogTitle>{t("heading.edit-offer-suggestion")}</DialogTitle>
                            <FieldGroup>
                                <form.Field name={"name"}>
                                    {(fieldApi) => (
                                        <Field>
                                            <RequiredLabel>{tg("label.name")}</RequiredLabel>
                                            <Input
                                                autoFocus={true}
                                                required={true}
                                                value={fieldApi.state.value}
                                                onChange={(e) => fieldApi.handleChange(e.target.value)}
                                                invalid={fieldApi.state.meta.errors.length > 0}
                                            />
                                            {fieldApi.state.meta.errors.map((err) => (
                                                <ErrorMessage>{err}</ErrorMessage>
                                            ))}
                                        </Field>
                                    )}
                                </form.Field>

                                <form.Field name={"category"}>
                                    {(fieldApi) => (
                                        <Field>
                                            <Label>{t("label.category")}</Label>
                                            <Input
                                                value={fieldApi.state.value}
                                                onChange={(e) => fieldApi.handleChange(e.target.value)}
                                            />
                                        </Field>
                                    )}
                                </form.Field>

                                <DialogActions>
                                    <Button type={"submit"}>{t("button.edit")}</Button>
                                </DialogActions>
                            </FieldGroup>
                        </Fieldset>
                    </Form>
                </Dialog>
            )}
        </>
    );
}

export const Route = createFileRoute("/_internal/i/upselling/offer-suggestions")({
    component: OffersOverview,
});
