import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Api, UUID } from "src/api/api";
import { Campaign } from "src/api/generated";
import { useForm } from "@tanstack/react-form";
import { toast } from "react-toastify";
import RunScript from "src/components/internal/scripts";
import { ErrorMessage, Field, Label, RequiredLabel } from "src/components/base/fieldset";
import { Combobox, ComboboxOption } from "src/components/base/combobox";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { Button } from "src/components/base/button";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { Checkbox, CheckboxField } from "src/components/base/checkbox";

/**
 * The properties for {@link ScriptGenerateRawCsv}
 */
export type ScriptGenerateRawCsvProps = {};

/** Representation of a file */
type DownloadFile = {
    /** name of the file */
    name: string;
    /** UUID for reference in media */
    uuid: UUID;
};

/**
 * The result of the script run
 */
type GenerateRawCsvResult = {
    /** UUID to the CSV file */
    csv: DownloadFile;
};

/**
 * Run script: Generate raw CSV
 */
function ScriptGenerateRawCsv(props: ScriptGenerateRawCsvProps) {
    const [t] = useTranslation("internal-scripts-generate-raw-csv");
    const [tg] = useTranslation();

    const [runningScript, setRunningScript] = React.useState<UUID>();
    const [campaigns, setCampaigns] = React.useState<Array<Campaign>>([]);

    const [campaignSearch, setCampaignSearch] = React.useState("");

    const form = useForm({
        defaultValues: {
            campaign: null as null | Campaign,
            clearCache: false,
        },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            if (value.campaign === null) {
                formApi.setFieldMeta("campaign", (meta) => {
                    meta.errors.push(t("error.must-be-filled"));
                    return meta;
                });
                return;
            }

            const res = await Api.internal.lucy.runGenerateRawCsv({
                campaign: value.campaign.id,
                clear_cache: value.clearCache,
            });
            if (res.isErr) {
                toast.error(res.err.message);
                return;
            }

            setRunningScript(res.ok.uuid);
        },
    });

    /**
     * Fetch the campaigns from lucy
     */
    const fetchCampaigns = async () => {
        const res = await Api.internal.lucy.getCampaigns();
        if (res.isErr) {
            toast.error(res.err.message);
            return;
        }

        setCampaigns(res.ok.list.filter((c) => !c.name.startsWith("[Template]")));
    };

    useEffect(() => {
        fetchCampaigns().then();
    }, []);

    const campaignOptions =
        campaignSearch === ""
            ? campaigns
            : campaigns.filter((campaign) => campaign.name.toLowerCase().includes(campaignSearch.toLowerCase()));

    return (
        <RunScript<GenerateRawCsvResult>
            runningScript={runningScript}
            setRunningScript={setRunningScript}
            scriptKilled={() => {}}
            renderResult={(res) => (
                <Table dense={true}>
                    <TableHead>
                        <TableRow>
                            <TableHeader>{tg("accessibility.actions")}</TableHeader>
                            <TableHeader className={"w-0"}></TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={res.csv.uuid}>
                            <TableCell>{t("button.download-csv")}</TableCell>
                            <TableCell className={"text-right"}>
                                <Button
                                    href={`/api/frontend/v1/internal/scripts/file/${res.csv.uuid}`}
                                    plain={true}
                                    target={"_blank"}
                                    download={res.csv.name}
                                >
                                    <ArrowTopRightOnSquareIcon />
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )}
            scriptName={t("heading.generate-csv")}
            onSubmit={form.handleSubmit}
        >
            <form.Field name={"campaign"}>
                {(fieldApi) => (
                    <Field>
                        <RequiredLabel>{t("label.campaign")}</RequiredLabel>
                        <Combobox
                            invalid={fieldApi.state.meta.errors.length > 0}
                            value={fieldApi.state.value}
                            onChange={fieldApi.handleChange}
                            onClose={() => setCampaignSearch("")}
                            onQueryChange={(query: string) => setCampaignSearch(query)}
                            queryDisplay={(q) => (q ? q.name : "")}
                        >
                            {campaignOptions.map((c) => (
                                <ComboboxOption key={c.id} value={c}>
                                    <Label>{c.name}</Label>
                                </ComboboxOption>
                            ))}
                        </Combobox>
                        {fieldApi.state.meta.errors.map((error) => (
                            <ErrorMessage>{error}</ErrorMessage>
                        ))}
                    </Field>
                )}
            </form.Field>

            <form.Field name={"clearCache"}>
                {(fieldApi) => (
                    <Field>
                        <CheckboxField>
                            <Label>{t("label.clear-cache")}</Label>
                            <Checkbox checked={fieldApi.state.value} onChange={fieldApi.handleChange} />
                        </CheckboxField>
                    </Field>
                )}
            </form.Field>
        </RunScript>
    );
}

export const Route = createFileRoute("/_internal/i/phishing/scripts/generate-raw-csv")({
    component: ScriptGenerateRawCsv,
});
