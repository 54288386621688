import React from "react";
import { useTranslation } from "react-i18next";
import HeadingLayout from "src/components/base/heading-layout";
import Form from "src/components/base/form";
import { Fieldset } from "@headlessui/react";
import { Field, FieldGroup, Label } from "src/components/base/fieldset";
import { Button } from "src/components/base/button";
import { RocketLaunchIcon, StopIcon } from "@heroicons/react/24/outline";
import { Api, UUID } from "src/api/api";
import { toast } from "react-toastify";
import WS from "src/api/ws";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "src/components/base/dialog";
import { Badge } from "src/components/base/badge";
import { clsx } from "clsx";
import { Textarea } from "src/components/base/textarea";
import CONSOLE from "src/utils/console";
import { WsServerMsgOneOf3 } from "src/api/generated";

/**
 * The properties for {@link RunScript}
 */
export type RunScriptProps<Result> = {
    /** The form that should be rendered */
    children: React.ReactNode;
    /** Callback on form submit */
    onSubmit: () => Promise<void>;
    /** The UUID of the current running script */
    runningScript?: UUID;
    /** Setter for running script */
    setRunningScript: (script: UUID | undefined) => void;
    /** Callback when the script was killed */
    scriptKilled: () => void;
    /** Translated name of the script */
    scriptName: string;
    /** Render the results */
    renderResult: (result: Result) => React.ReactNode;
    /** If true, form has unlimited width, otherwise limits width to max xl */
    fullWidth?: boolean;
    /** If true, disables run button (e.g. if inputs are invalid) */
    disableRun?: boolean;
};

/**
 * Utility to run scripts in the backend
 */
export default function RunScript<Result>(props: RunScriptProps<Result>) {
    const [t] = useTranslation("internal-run-script");
    const [tg] = useTranslation();

    const { runningScript, setRunningScript, disableRun } = props;

    const [openLogs, setOpenLogs] = React.useState<boolean>(false);
    const [logs, setLogs] = React.useState<Array<string>>([]);
    const [result, setResult] = React.useState<Result>();
    const [failed, setFailed] = React.useState(false);

    const logsRef = React.useRef<HTMLTextAreaElement>(null);

    /**
     * Helper method to kill a running script
     *
     * @param script The uuid of the script to kill
     */
    const killScript = async (script: UUID) => {
        const res = await Api.internal.scripts.kill(script);
        if (res.isErr) {
            toast.error(res.err.message);
            return;
        }

        reset();
        props.scriptKilled();
    };

    /**
     * Reset the script
     */
    const reset = () => {
        setOpenLogs(false);
        setLogs([]);
        setResult(undefined);
        setFailed(false);
    };

    React.useEffect(() => {
        if (runningScript === undefined) return;

        const handleLog = WS.addEventListener("message.ScriptLog", ({ script: s, log }) => {
            if (s !== runningScript) return;
            setLogs((logs) => [...logs, log]);
        });
        const handleFinished = WS.addEventListener("message.ScriptResult", ({ script: s, result: result }) => {
            if (s !== runningScript) return;
            setResult(result);
            setRunningScript(undefined);
        });
        const handleFailed = WS.addEventListener("message.ScriptError", ({ script: s }) => {
            if (s !== runningScript) return;
            setFailed(true);
            setRunningScript(undefined);
        });

        setOpenLogs(true);

        return () => {
            WS.removeEventListener(handleLog);
            WS.removeEventListener(handleFinished);
            WS.removeEventListener(handleFailed);
        };
    }, [runningScript]);

    React.useEffect(() => {
        logsRef.current?.scrollTo({ top: logsRef.current?.scrollHeight, behavior: "smooth" });
    }, [logs]);

    return (
        <HeadingLayout heading={t("heading.run", { scriptName: props.scriptName })}>
            <Form onSubmit={async () => await props.onSubmit()} className={props.fullWidth ? "" : "max-w-xl"}>
                <Fieldset disabled={runningScript !== undefined}>
                    <FieldGroup>{props.children}</FieldGroup>
                </Fieldset>

                <div className={"mt-6 flex gap-3"}>
                    <Button type={"submit"} disabled={disableRun || runningScript !== undefined} outline={true}>
                        <RocketLaunchIcon className={"stroke-emerald-500"} />
                        <span>{tg("button.run")}</span>
                    </Button>
                    <Button
                        disabled={!runningScript}
                        outline={true}
                        onClick={async () => runningScript && (await killScript(runningScript))}
                    >
                        <StopIcon className={"fill-red-600/30 stroke-red-500"} />
                        <span>{tg("button.stop")}</span>
                    </Button>
                </div>
            </Form>

            <Dialog open={openLogs} onClose={() => {}}>
                <DialogTitle>{t("heading.run", { scriptName: props.scriptName })}</DialogTitle>

                <Badge
                    color={runningScript ? "green" : failed ? "red" : "zinc"}
                    className={clsx("mt-2", runningScript && "animate-pulse")}
                >
                    {runningScript ? t("label.running") : failed ? t("label.failed") : t("label.exited")}
                </Badge>

                <DialogBody>
                    <Field>
                        <Label>{t("label.logs")}</Label>
                        <Textarea
                            ref={logsRef}
                            className={"font-mono [&>*]:text-xs"}
                            rows={runningScript ? 15 : 4}
                            resizable={runningScript === undefined}
                            readOnly={true}
                            value={logs.join("\n")}
                        />
                    </Field>

                    {result && <div className={"mt-6"}>{props.renderResult(result)}</div>}
                </DialogBody>
                <DialogActions>
                    <Button
                        disabled={!runningScript}
                        outline={true}
                        onClick={async () => runningScript && (await killScript(runningScript))}
                    >
                        <StopIcon className={"fill-red-600/30 stroke-red-500"} />
                        <span>{tg("button.stop")}</span>
                    </Button>
                    <Button onClick={reset} disabled={runningScript !== undefined}>
                        {tg("button.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </HeadingLayout>
    );
}

/**
 * Waits for the spawned script to finish and returns its result.
 * Hangs indefinitely if you run this after the script has already finished.
 *
 * @param uuid UUID of the spawned script
 *
 * @returns The script result
 */
export function waitForScript(uuid: string): Promise<WsServerMsgOneOf3["result"]> {
    let handleLog, handleFinished, handleFailed;
    return new Promise((resolve, reject) => {
        handleLog = WS.addEventListener("message.ScriptLog", ({ script: s, log }) => {
            if (s !== uuid) return;
            CONSOLE.log("Script " + uuid, log);
        });
        handleFinished = WS.addEventListener("message.ScriptResult", ({ script: s, result: result }) => {
            if (s !== uuid) return;
            resolve(result);
        });
        handleFailed = WS.addEventListener("message.ScriptError", ({ script: s }) => {
            if (s !== uuid) return;
            reject();
        });
    }).finally(() => {
        WS.removeEventListener(handleLog!);
        WS.removeEventListener(handleFinished!);
        WS.removeEventListener(handleFailed!);
    });
}
