import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Api, UUID } from "src/api/api";
import { Campaign } from "src/api/generated";
import { useForm } from "@tanstack/react-form";
import { toast } from "react-toastify";
import RunScript from "src/components/internal/scripts";
import { ErrorMessage, Field, Label, RequiredLabel } from "src/components/base/fieldset";
import { Combobox, ComboboxOption } from "src/components/base/combobox";
import { Checkbox, CheckboxField } from "src/components/base/checkbox";
import { Listbox, ListboxLabel, ListboxOption } from "src/components/base/listbox";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { Button } from "src/components/base/button";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";

/**
 * The properties for {@link GenerateTrainingStatus}
 */
export type GenerateTrainingStatusProps = {};

/**
 * The result from the script
 */
type GenerateTrainingStatusResult = {
    /** Mail text */
    message: string;
};

/**
 * Type of the training method
 */
type TrainingMethod = "clicked_trainings" | "started_trainings" | "finished_trainings";

/**
 * Converts a text message from a HTML node to a string
 *
 * @param d The node to format to a string
 * @returns A string representation of the node, similar to textContent but optimized for very simple HTML only consisting of P, BR and Tables
 */
function mailHtmlToText(d: Node): string {
    if (d.nodeType == Node.ELEMENT_NODE) {
        const n = d as HTMLElement;
        if (n.tagName == "P") {
            return "\n" + n.textContent?.trim() + "\n";
        } else if (n.tagName == "TABLE") {
            let table = "\n";
            for (const row of n.querySelectorAll("tr")) {
                let start = true;
                for (const cell of row.querySelectorAll("td,th")) {
                    if (start) start = false;
                    else table += "\t";
                    table += cell.textContent?.trim().replace(/[\r\n]+/g, " ") || "";
                }
                table += "\n";
            }
            return table;
        } else if (n.tagName == "BR") {
            return "\n";
        } else {
            let r = "";
            for (const node of d.childNodes) {
                r += mailHtmlToText(node);
            }
            return r;
        }
    } else {
        return d.textContent?.trim() || "";
    }
}

/**
 * Run script: Generate training status
 */
function GenerateTrainingStatus(props: GenerateTrainingStatusProps) {
    const [t] = useTranslation("internal-scripts-generate-training-status");
    const [tg] = useTranslation();

    const [runningScript, setRunningScript] = React.useState<UUID>();
    const [campaigns, setCampaigns] = React.useState<Array<Campaign>>([]);

    const [campaignSearch, setCampaignSearch] = React.useState("");

    const form = useForm({
        defaultValues: {
            campaign: null as null | Campaign,
            onlyMissing: true,
            trackingMethod: "finished_trainings" as TrainingMethod,
            clearCache: false,
        },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            if (value.campaign === null) {
                formApi.setFieldMeta("campaign", (meta) => {
                    meta.errors.push(t("error.must-be-filled"));
                    return meta;
                });
                return;
            }

            const res = await Api.internal.lucy.runGenerateTrainingStatus({
                campaign: value.campaign.id,
                only_missing: value.onlyMissing,
                tracking_method:
                    value.trackingMethod === "clicked_trainings"
                        ? 0
                        : value.trackingMethod === "started_trainings"
                          ? 1
                          : 2,
                clear_cache: value.clearCache,
            });
            if (res.isErr) {
                toast.error(res.err.message);
                return;
            }

            setRunningScript(res.ok.uuid);
        },
    });

    /**
     * Fetch the campaigns from lucy
     */
    const fetchCampaigns = async () => {
        const res = await Api.internal.lucy.getCampaigns();
        if (res.isErr) {
            toast.error(res.err.message);
            return;
        }

        setCampaigns(
            res.ok.list.filter((c) => !c.name.startsWith("[Template]")).filter((c) => !c.name.startsWith("Attack")),
        );
    };

    useEffect(() => {
        fetchCampaigns().then();
    }, []);

    const campaignOptions =
        campaignSearch === ""
            ? campaigns
            : campaigns.filter((campaign) => campaign.name.toLowerCase().includes(campaignSearch.toLowerCase()));

    return (
        <RunScript<GenerateTrainingStatusResult>
            runningScript={runningScript}
            setRunningScript={setRunningScript}
            scriptKilled={() => {}}
            renderResult={(res) => (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeader>{tg("accessibility.actions")}</TableHeader>
                            <TableHeader className={"w-0"}></TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{t("button.copy-mail")}</TableCell>
                            <TableCell>
                                <Button
                                    plain={true}
                                    onClick={async () => {
                                        const textVersion = mailHtmlToText(
                                            new DOMParser().parseFromString(res.message, "text/html").documentElement,
                                        );
                                        await navigator.clipboard.write([
                                            new ClipboardItem({
                                                "text/plain": new Blob([textVersion], { type: "text/plain" }),
                                                "text/html": new Blob([res.message], { type: "text/html" }),
                                            }),
                                        ]);
                                        toast.success(tg("toast.copied-to-clipboard"));
                                    }}
                                >
                                    <ClipboardDocumentIcon />
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )}
            scriptName={t("heading.generate-training-status")}
            onSubmit={form.handleSubmit}
        >
            <form.Field name={"campaign"}>
                {(fieldApi) => (
                    <Field>
                        <RequiredLabel>{t("label.campaign")}</RequiredLabel>
                        <Combobox
                            invalid={fieldApi.state.meta.errors.length > 0}
                            value={fieldApi.state.value}
                            onChange={fieldApi.handleChange}
                            onClose={() => setCampaignSearch("")}
                            onQueryChange={(query: string) => setCampaignSearch(query)}
                            queryDisplay={(q) => (q ? q.name : "")}
                        >
                            {campaignOptions.map((c) => (
                                <ComboboxOption key={c.id} value={c}>
                                    <Label>{c.name}</Label>
                                </ComboboxOption>
                            ))}
                        </Combobox>
                        {fieldApi.state.meta.errors.map((err) => (
                            <ErrorMessage>{err}</ErrorMessage>
                        ))}
                    </Field>
                )}
            </form.Field>

            <form.Field name={"onlyMissing"}>
                {(fieldApi) => (
                    <Field>
                        <CheckboxField>
                            <Label>{t("label.only-missing")}</Label>
                            <Checkbox checked={fieldApi.state.value} onChange={fieldApi.handleChange} />
                        </CheckboxField>
                    </Field>
                )}
            </form.Field>

            <form.Field name={"trackingMethod"}>
                {(fieldApi) => (
                    <Field>
                        <RequiredLabel>{t("label.tracking-method")}</RequiredLabel>
                        <Listbox value={fieldApi.state.value} onChange={fieldApi.handleChange}>
                            <ListboxOption value={"clicked_trainings"}>
                                <ListboxLabel>{t("label.clicked-trainings")}</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption value={"started_trainings"}>
                                <ListboxLabel>{t("label.started-trainings")}</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption value={"finished_trainings"}>
                                <ListboxLabel>{t("label.finished-trainings")}</ListboxLabel>
                            </ListboxOption>
                        </Listbox>
                    </Field>
                )}
            </form.Field>

            <form.Field name={"clearCache"}>
                {(fieldApi) => (
                    <Field>
                        <CheckboxField>
                            <Label>{t("label.clear-cache")}</Label>
                            <Checkbox checked={fieldApi.state.value} onChange={fieldApi.handleChange} />
                        </CheckboxField>
                    </Field>
                )}
            </form.Field>
        </RunScript>
    );
}

export const Route = createFileRoute("/_internal/i/phishing/scripts/generate-training-status")({
    component: GenerateTrainingStatus,
});
