import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import RunScript from "src/components/internal/scripts";
import { ErrorMessage, Field, Label, RequiredLabel } from "src/components/base/fieldset";
import { Input } from "src/components/base/input";
import { useForm, useStore } from "@tanstack/react-form";
import { Listbox, ListboxLabel, ListboxOption } from "src/components/base/listbox";
import { Api, UUID } from "src/api/api";
import { toast } from "react-toastify";
import { Radio, RadioField } from "src/components/base/radio";
import * as Headless from "@headlessui/react";
import { Combobox, ComboboxOption } from "src/components/base/combobox";
import { Checkbox, CheckboxField, CheckboxGroup } from "src/components/base/checkbox";
import { Button } from "src/components/base/button";
import { ArrowPathRoundedSquareIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { Divider } from "src/components/base/divider";

/**
 * The properties for {@link CreateCampaign}
 */
export type CreateCampaignProps = {};

/** Kind of a campaign */
type CampaignType = "attack" | "training" | "both";

/** The language for the campaigns */
type Language = "default" | "German" | "English";

/** Possible values for campaign numbers */
type CampaignNumber = "1" | "2" | "3" | "4" | "special";

/** Result of a create campaign call */
type CreateCampaignResult = {
    /** URL to the campaign */
    campaign_url: string;
    /** URL to the recipient group */
    recipient_group_url: string;
    /** The text of the mail to copy */
    message: string;
};

/**
 * Create a new phishing campaign
 */
function CreateCampaign(props: CreateCampaignProps) {
    const [t] = useTranslation("internal-scripts-create-campaign");
    const [tg] = useTranslation();

    const [runningScript, setRunningScript] = React.useState<UUID>();

    const [ssoOptions, setSSOOptions] = React.useState<Array<string>>([]);
    const [featureOptions, setFeatureOptions] = React.useState<Array<string>>([]);
    const [moduleOptions, setModuleOptions] = React.useState<Array<string>>([]);
    const [clients, setClients] = React.useState<Array<string>>([]);
    const [clientSearch, setClientSearch] = React.useState("");
    const [trainingModuleDirty, setTrainingModuleDirty] = React.useState(false);

    const defaultTrainings: Record<CampaignNumber, string> = {
        1: "Phishing (Single SCORM)",
        2: "Social Engineering + Quiz",
        3: "",
        4: "",
        special: "Phishing (Single SCORM)",
    };

    const form = useForm({
        defaultValues: {
            campaignType: "both" as CampaignType,
            client: null as string | null,
            campaignNumber: "1" as CampaignNumber,
            sso: "MS365",
            features: [] as Array<string>,
            attackStartDate: "",
            attackSendStartTime: "",
            attackSendEndTime: "",
            trainingStartDate: "",
            trainingSendTime: "09:00",
            trainingModule: defaultTrainings["1"],
            spoofingDomain: "trusted-mail.live",
            langInternal: "default" as Language,
            langExternal: "default" as Language,
        },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            if (value.client === null) {
                formApi.setFieldMeta("client", (meta) => {
                    meta.errors.push(t("error.must-be-filled"));
                    return meta;
                });
                return;
            }
            if (
                value.campaignType !== "training" &&
                !(value.attackStartDate === "" && value.attackSendStartTime === "" && value.attackSendEndTime === "") &&
                !(value.attackStartDate !== "" && value.attackSendStartTime !== "" && value.attackSendEndTime !== "")
            ) {
                value.attackStartDate === "" &&
                    formApi.setFieldMeta("attackStartDate", (meta) => {
                        meta.errors.push(t("error.all-must-be-filled"));
                        return meta;
                    });
                value.attackSendStartTime === "" &&
                    formApi.setFieldMeta("attackSendStartTime", (meta) => {
                        meta.errors.push(t("error.all-must-be-filled"));
                        return meta;
                    });
                value.attackSendEndTime === "" &&
                    formApi.setFieldMeta("attackSendEndTime", (meta) => {
                        meta.errors.push(t("error.all-must-be-filled"));
                        return meta;
                    });
                return;
            }
            if (
                value.campaignType !== "attack" &&
                !(value.trainingStartDate === "" && value.trainingSendTime === "") &&
                !(value.trainingStartDate !== "" && value.trainingSendTime !== "")
            ) {
                value.trainingStartDate === "" &&
                    formApi.setFieldMeta("trainingStartDate", (meta) => {
                        meta.errors.push(t("error.all-must-be-filled"));
                        return meta;
                    });
                value.trainingSendTime === "" &&
                    formApi.setFieldMeta("trainingSendTime", (meta) => {
                        meta.errors.push(t("error.all-must-be-filled"));
                        return meta;
                    });
                return;
            }

            const res = await Api.internal.lucy.runCreateCampaign({
                campaign_type: value.campaignType,
                client_name: value.client,
                campaign_number: value.campaignNumber === "special" ? "special" : parseInt(value.campaignNumber),
                sso: value.sso,
                features: value.features,
                attack_start_date: value.attackStartDate,
                attack_send_start_time: value.attackSendStartTime,
                attack_send_end_time: value.attackSendEndTime,
                training_start_date: value.trainingStartDate,
                training_send_time: value.trainingSendTime,
                training_module: value.trainingModule,
                spoofing_domain: value.spoofingDomain,
                lang_internal:
                    value.langInternal === "default"
                        ? undefined
                        : value.langInternal === "German"
                          ? "German"
                          : "English",
                lang_external:
                    value.langExternal === "default"
                        ? undefined
                        : value.langExternal === "German"
                          ? "German"
                          : "English",
            });

            if (res.isErr) {
                toast.error(res.err.message);
                return;
            }

            setRunningScript(res.ok.uuid);
        },
    });
    const campaignType = useStore(form.store, (store) => store.values.campaignType);

    /**
     * Fetch relevant data for creating a campaign
     */
    const fetchOptions = async () => {
        const res = await Api.internal.lucy.getCreateCampaignOptions();
        if (res.isErr) {
            toast.error(res.err.message);
            return;
        }

        setSSOOptions(res.ok.sso);
        setFeatureOptions(res.ok.features);
        setClients(res.ok.clients);
        setModuleOptions(res.ok.training_modules);
    };

    const selectedClient = useStore(form.store, (store) => store.values.client);
    const campaignNumber = useStore(form.store, (store) => store.values.campaignNumber);

    useEffect(() => {
        fetchOptions().then();
    }, []);

    useEffect(() => {
        if (!trainingModuleDirty) form.setFieldValue("trainingModule", defaultTrainings[campaignNumber]);
    }, [campaignNumber]);

    useEffect(() => {
        setTrainingModuleDirty(false);
    }, [selectedClient]);

    const clientOptions =
        clientSearch === ""
            ? clients
            : clients.filter((client) => client.toLowerCase().includes(clientSearch.toLowerCase()));

    return (
        <RunScript<CreateCampaignResult>
            scriptName={t("heading.create-campaign")}
            onSubmit={async () => {
                await form.handleSubmit();
            }}
            runningScript={runningScript}
            setRunningScript={setRunningScript}
            scriptKilled={() => {}}
            renderResult={(result) => (
                <Table dense={true}>
                    <TableHead>
                        <TableRow>
                            <TableHeader></TableHeader>
                            <TableHeader>{tg("accessibility.actions")}</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{t("button.campaign-url")}</TableCell>
                            <TableCell>
                                <Button href={result.campaign_url} target={"_blank"} plain={true}>
                                    <ArrowTopRightOnSquareIcon />
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t("button.recipient-group-url")}</TableCell>
                            <TableCell>
                                <Button href={result.recipient_group_url} target={"_blank"} plain={true}>
                                    <ArrowTopRightOnSquareIcon />
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t("button.copy-mail")}</TableCell>
                            <TableCell>
                                <Button
                                    plain={true}
                                    onClick={async () => {
                                        await navigator.clipboard.writeText(result.message);
                                        toast.success(tg("toast.copied-to-clipboard"));
                                    }}
                                >
                                    <ClipboardDocumentIcon />
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )}
        >
            <form.Field name={"campaignType"}>
                {(fieldApi) => (
                    <Field>
                        <RequiredLabel>{t("label.campaign-type")}</RequiredLabel>
                        <Listbox value={fieldApi.state.value} onChange={(e) => fieldApi.handleChange(e)}>
                            <ListboxOption value={"attack"}>
                                <ListboxLabel>{t("label.attack")}</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption value={"training"}>
                                <ListboxLabel>{t("label.training")}</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption value={"both"}>
                                <ListboxLabel>{t("label.both")}</ListboxLabel>
                            </ListboxOption>
                        </Listbox>
                    </Field>
                )}
            </form.Field>

            <form.Field name={"client"}>
                {(fieldApi) => (
                    <Field>
                        <RequiredLabel>{t("label.client")}</RequiredLabel>
                        <Combobox
                            value={fieldApi.state.value}
                            onChange={(e) => fieldApi.handleChange(e)}
                            onClose={() => setClientSearch("")}
                            queryDisplay={(e) => (e ? e : "")}
                            onQueryChange={setClientSearch}
                        >
                            {clientOptions.map((client) => (
                                <ComboboxOption value={client} key={client}>
                                    <Label>{client}</Label>
                                </ComboboxOption>
                            ))}
                        </Combobox>
                        {fieldApi.state.meta.errors.map((err) => (
                            <ErrorMessage>{err}</ErrorMessage>
                        ))}
                    </Field>
                )}
            </form.Field>

            {campaignType !== "training" && (
                <form.Field name={"spoofingDomain"}>
                    {(fieldApi) => (
                        <Field>
                            <RequiredLabel>{t("label.spoofing-domain")}</RequiredLabel>
                            <Input
                                required={true}
                                value={fieldApi.state.value}
                                onChange={(e) => fieldApi.handleChange(e.target.value)}
                            />
                        </Field>
                    )}
                </form.Field>
            )}

            <form.Field name={"campaignNumber"}>
                {(fieldApi) => (
                    <Field>
                        <RequiredLabel>{t("label.campaign-number")}</RequiredLabel>

                        <Headless.RadioGroup
                            className={"mt-3 flex gap-6"}
                            defaultValue={fieldApi.state.value}
                            onChange={(e) => fieldApi.handleChange(e)}
                        >
                            {["1", "2", "3", "4"].map((x) => (
                                <Headless.Field className={"flex items-center gap-2"} key={x}>
                                    <Radio value={x} />
                                    <Label>{x}</Label>
                                </Headless.Field>
                            ))}
                            <RadioField>
                                <Radio value={"special"} />
                                <Label>{t("label.special")}</Label>
                            </RadioField>
                        </Headless.RadioGroup>
                    </Field>
                )}
            </form.Field>

            {campaignType !== "training" && (
                <>
                    <form.Field name={"langInternal"}>
                        {(fieldApi) => (
                            <Field>
                                <RequiredLabel>{t("label.language-internal")}</RequiredLabel>

                                <Listbox value={fieldApi.state.value} onChange={(e) => fieldApi.handleChange(e)}>
                                    <ListboxOption value={"default"}>
                                        <ListboxLabel>{t("label.use-default")}</ListboxLabel>
                                    </ListboxOption>
                                    <ListboxOption value={"English"} className={"gap-3"}>
                                        🇺🇸
                                        <ListboxLabel>{t("label.english")}</ListboxLabel>
                                    </ListboxOption>
                                    <ListboxOption value={"German"} className={"gap-3"}>
                                        🇩🇪
                                        <ListboxLabel>{t("label.german")}</ListboxLabel>
                                    </ListboxOption>
                                </Listbox>
                            </Field>
                        )}
                    </form.Field>

                    <form.Field name={"langExternal"}>
                        {(fieldApi) => (
                            <Field>
                                <RequiredLabel>{t("label.language-external")}</RequiredLabel>

                                <Listbox value={fieldApi.state.value} onChange={(e) => fieldApi.handleChange(e)}>
                                    <ListboxOption value={"default"} className={"gap-3"}>
                                        <ListboxLabel>{t("label.use-default")}</ListboxLabel>
                                    </ListboxOption>
                                    <ListboxOption value={"English"} className={"gap-3"}>
                                        🇺🇸
                                        <ListboxLabel>{t("label.english")}</ListboxLabel>
                                    </ListboxOption>
                                    <ListboxOption value={"German"} className={"gap-3"}>
                                        🇩🇪
                                        <ListboxLabel>{t("label.german")}</ListboxLabel>
                                    </ListboxOption>
                                </Listbox>
                            </Field>
                        )}
                    </form.Field>

                    <Divider />

                    <form.Field name={"sso"}>
                        {(fieldApi) => (
                            <Field>
                                <Label>{t("label.sso-provider")}</Label>
                                <div className={"flex items-center gap-3"}>
                                    <Listbox value={fieldApi.state.value} onChange={(e) => fieldApi.handleChange(e)}>
                                        {ssoOptions.map((sso) => (
                                            <ListboxOption value={sso} key={sso}>
                                                <ListboxLabel>{sso}</ListboxLabel>
                                            </ListboxOption>
                                        ))}
                                    </Listbox>

                                    <Button plain={true} onClick={() => fieldApi.handleChange("")}>
                                        <ArrowPathRoundedSquareIcon />
                                    </Button>
                                </div>
                            </Field>
                        )}
                    </form.Field>
                    <form.Field name={"features"}>
                        {(fieldApi) => (
                            <Field>
                                <Label>{t("label.features")}</Label>
                                <CheckboxGroup>
                                    {featureOptions.map((feature) => (
                                        <CheckboxField>
                                            <Checkbox
                                                value={feature}
                                                checked={fieldApi.state.value.includes(feature)}
                                                onChange={(e) =>
                                                    e
                                                        ? fieldApi.handleChange([...fieldApi.state.value, feature])
                                                        : fieldApi.handleChange(
                                                              fieldApi.state.value.filter((x) => x !== feature),
                                                          )
                                                }
                                            />
                                            <Label>{feature}</Label>
                                        </CheckboxField>
                                    ))}
                                </CheckboxGroup>
                            </Field>
                        )}
                    </form.Field>

                    <Divider />

                    <form.Field name={"attackStartDate"}>
                        {(fieldApi) => (
                            <Field>
                                <Label>{t("label.attack-start-date")}</Label>
                                <Input
                                    invalid={fieldApi.state.meta.errors.length > 0}
                                    type={"date"}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                                {fieldApi.state.meta.errors.map((err) => (
                                    <ErrorMessage>{err}</ErrorMessage>
                                ))}
                            </Field>
                        )}
                    </form.Field>
                    <form.Field name={"attackSendStartTime"}>
                        {(fieldApi) => (
                            <Field>
                                <Label>{t("label.send-start-time")}</Label>
                                <Input
                                    invalid={fieldApi.state.meta.errors.length > 0}
                                    type={"time"}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                                {fieldApi.state.meta.errors.map((err) => (
                                    <ErrorMessage>{err}</ErrorMessage>
                                ))}
                            </Field>
                        )}
                    </form.Field>
                    <form.Field name={"attackSendEndTime"}>
                        {(fieldApi) => (
                            <Field>
                                <Label>{t("label.send-stop-time")}</Label>
                                <Input
                                    invalid={fieldApi.state.meta.errors.length > 0}
                                    type={"time"}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                                {fieldApi.state.meta.errors.map((err) => (
                                    <ErrorMessage>{err}</ErrorMessage>
                                ))}
                            </Field>
                        )}
                    </form.Field>
                </>
            )}

            {campaignType !== "attack" && (
                <>
                    <Divider />
                    <form.Field name={"trainingModule"}>
                        {(fieldApi) => (
                            <Field>
                                <RequiredLabel>{t("label.training-module")}</RequiredLabel>
                                <Listbox
                                    value={fieldApi.state.value}
                                    onChange={(e) => {
                                        fieldApi.handleChange(e);
                                        setTrainingModuleDirty(true);
                                    }}
                                >
                                    {moduleOptions.map((module) => (
                                        <ListboxOption value={module} key={module}>
                                            <ListboxLabel>{module}</ListboxLabel>
                                        </ListboxOption>
                                    ))}
                                </Listbox>
                            </Field>
                        )}
                    </form.Field>
                    <form.Field name={"trainingStartDate"}>
                        {(fieldApi) => (
                            <Field>
                                <Label>{t("label.training-start-date")}</Label>
                                <Input
                                    invalid={fieldApi.state.meta.errors.length > 0}
                                    type={"date"}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                                {fieldApi.state.meta.errors.map((err) => (
                                    <ErrorMessage>{err}</ErrorMessage>
                                ))}
                            </Field>
                        )}
                    </form.Field>
                    <form.Field name={"trainingSendTime"}>
                        {(fieldApi) => (
                            <Field>
                                <Label>{t("label.training-send-time")}</Label>
                                <Input
                                    invalid={fieldApi.state.meta.errors.length > 0}
                                    type={"time"}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                                {fieldApi.state.meta.errors.map((err) => (
                                    <ErrorMessage>{err}</ErrorMessage>
                                ))}
                            </Field>
                        )}
                    </form.Field>
                </>
            )}
        </RunScript>
    );
}

export const Route = createFileRoute("/_internal/i/phishing/scripts/create-campaign")({
    component: CreateCampaign,
});
