import {
    AddPentestTargetRequest,
    AuthApi,
    Configuration,
    CreateCustomerAddressRequest,
    CreateCustomerContactRequest,
    CreateCustomerRequest,
    CreateOfferPotentialTopicRequest,
    CreatePentestProjectRequest,
    CreateQuestionChoice,
    CreateQuestionGroupRequest,
    CreateQuestionnaireTemplateRequest,
    CreateQuestionRequest,
    CreateTotpRequest,
    CreateUserInviteRequest,
    CustomerAddressesApi,
    CustomerContactsApi,
    CustomerOfferPotentialsApi,
    CustomersApi,
    EnterPentestDebriefResultsV0Request,
    EnterPentestDialogResultsV0Request,
    EnterPentestDialogResultsV1Request,
    EnterPentestKickoffResultsV0Request,
    EnterPentestKickoffResultsV1Request,
    EnterPentestMgmtPresentationResultsV0Request,
    EnterPentestMgmtPresentationResultsV1Request,
    FullKarlaSettings,
    FullMailSettings,
    FullMatrixSettings,
    GetAllTasksRequest,
    InternalGroups,
    KarlaSettingsApi,
    LucyAutomationApi,
    MailSettingsApi,
    MatrixSettingsApi,
    OfferPotentialTopicsApi,
    PentestProjectsApi,
    PentestTargetsApi,
    PentestTasksApi,
    ProjectContactCategory,
    ProjectsApi,
    QuestionnaireQuestionGroupsApi,
    QuestionnaireQuestionsApi,
    QuestionnairesApi,
    QuestionnaireTemplatesApi,
    RequiredError,
    ResponseError,
    ScheduleDebriefV0Request,
    ScheduleDialogV0Request,
    ScheduleKickoffV0Request,
    ScheduleKickoffV1Request,
    ScheduleMgmtPresentationV0Request,
    ScriptsApi,
    SetPasswordRequest,
    SubmitCurrentAnswerRequest,
    SudoModeApi,
    TasksApi,
    UpdateCustomerAddressRequest,
    UpdateCustomerContactRequest,
    UpdateCustomerInternalRequest,
    UpdateMeInternalRequest,
    UpdateMeRequest,
    UpdateOfferPotentialTopicRequest,
    UpdatePentestProjectRequest,
    UpdateQuestionChoiceRequest,
    UpdateQuestionGroupRequest,
    UpdateQuestionnaireTemplateRequest,
    UpdateQuestionRequest,
    UserInvitesApi,
    UserResetsApi,
    UsersApi,
    UserTOTPKeysApi,
    UserWebAuthnKeysApi,
} from "src/api/generated";
import { ApiError, parseError, StatusCode } from "src/api/error";
import CONSOLE from "src/utils/console";
import { Err, Ok, Result } from "src/utils/result";

/** Database id i.e. and u32 */
export type ID = number;

/** Hyphen separated uuid */
export type UUID = string;

const configuration = new Configuration({
    basePath: window.location.origin,
});

const authApi = new AuthApi(configuration);
const totpApi = new UserTOTPKeysApi(configuration);
const webauthnApi = new UserWebAuthnKeysApi(configuration);
const customersApi = new CustomersApi(configuration);
const customerContactsApi = new CustomerContactsApi(configuration);
const customerAddressApi = new CustomerAddressesApi(configuration);
const customerOfferPotentialsApi = new CustomerOfferPotentialsApi(configuration);
const usersApi = new UsersApi(configuration);
const mailSettingsApi = new MailSettingsApi(configuration);
const matrixSettingsApi = new MatrixSettingsApi(configuration);
const karlaSettingsApi = new KarlaSettingsApi(configuration);
const userInvitesApi = new UserInvitesApi(configuration);
const userResetsApi = new UserResetsApi(configuration);
const pentestProjectsApi = new PentestProjectsApi(configuration);
const pentestTargetsApi = new PentestTargetsApi(configuration);
const projectsApi = new ProjectsApi(configuration);
const questionnaireGroupsApi = new QuestionnaireQuestionGroupsApi(configuration);
const questionnaireTemplatesApi = new QuestionnaireTemplatesApi(configuration);
const questionnaireQuestionApi = new QuestionnaireQuestionsApi(configuration);
const questionnairesApi = new QuestionnairesApi(configuration);
const tasksApi = new TasksApi(configuration);
const pentestTasksApi = new PentestTasksApi(configuration);
const sudoModeApi = new SudoModeApi(configuration);
const offerPotentialTopicsApi = new OfferPotentialTopicsApi(configuration);
const lucyAutomationApi = new LucyAutomationApi(configuration);
const scriptsApi = new ScriptsApi(configuration);

/* eslint-disable */
export const Api = {
    auth: {
        getLoginFlows: (mail: string) =>
            handleError(
                authApi.getLoginFlows({
                    LoginFlowsRequest: { mail },
                }),
            ),
        performPasswordLogin: (mail: string, password: string) =>
            handleError(
                authApi.loginPassword({
                    LoginPasswordRequest: {
                        mail,
                        password,
                    },
                }),
            ),
        verifyTOTP: (token: string) => handleError(authApi.verifyTotp({ VerifyTotpRequest: { token } })),
        logout: () => handleError(authApi.logout()),
    },
    admin: {
        customers: {
            all: () => handleError(customersApi.getAllCustomersAdmin()),
        },
        mailSettings: {
            get: () => handleError(mailSettingsApi.getMailSettings()),
            set: (fullMailSettings: FullMailSettings) =>
                handleError(mailSettingsApi.setMailSettings({ FullMailSettings: fullMailSettings })),
            delete: () => handleError(mailSettingsApi.deleteMailSettings()),
            test: (to: string) =>
                handleError(
                    mailSettingsApi.testMailSettings({
                        TestMailSettingsRequest: { to },
                    }),
                ),
        },
        matrixSettings: {
            get: () => handleError(matrixSettingsApi.getMatrixSettings()),
            set: (fullMatrixSettings: FullMatrixSettings) =>
                handleError(matrixSettingsApi.setMatrixSettings({ FullMatrixSettings: fullMatrixSettings })),
            delete: () => handleError(matrixSettingsApi.deleteMatrixSettings()),
            test: (user_id: string) =>
                handleError(
                    matrixSettingsApi.testMatrixSettings({
                        TestMatrixSettingsRequest: { user_id },
                    }),
                ),
        },
        karlaSettings: {
            get: () => handleError(karlaSettingsApi.getKarlaSettings()),
            set: (fullKarlaSettings: FullKarlaSettings) =>
                handleError(karlaSettingsApi.setKarlaSettings({ FullKarlaSettings: fullKarlaSettings })),
            delete: () => handleError(karlaSettingsApi.deleteKarlaSettings()),
        },
        userInvites: {
            all: () => handleError(userInvitesApi.getAllUserInvites()),
            create: (createUserInviteRequest: CreateUserInviteRequest) =>
                handleError(
                    userInvitesApi.createUserInvite({
                        CreateUserInviteRequest: createUserInviteRequest,
                    }),
                ),
            delete: (uuid: UUID) => handleError(userInvitesApi.deleteUserInvite({ uuid })),
        },
        users: {
            all: () => handleError(usersApi.getAllUsers()),
            setInternalGroups: (uuid: UUID, internalGroups: InternalGroups) =>
                handleError(
                    usersApi.setInternalGroups({
                        uuid,
                        InternalGroups: internalGroups,
                    }),
                ),
            setKrakenUuid: (user: UUID, krakenUuid: UUID) =>
                handleError(
                    usersApi.setKrakenUuid({
                        uuid: user,
                        SingleUuid: { uuid: krakenUuid },
                    }),
                ),
            delete: (uuid: UUID) => handleError(usersApi.deleteUser({ uuid })),
            resetCredentials: (uuid: UUID) =>
                handleError(userResetsApi.createUserReset({ CreateUserResetRequest: { user: uuid } })),

            me: {
                update: (updateMeRequest: UpdateMeRequest) =>
                    handleError(usersApi.updateMeCommon({ UpdateMeRequest: updateMeRequest })),
            },
        },
    },
    common: {
        user: {
            me: {
                get: () => handleError(usersApi.getMe()),
            },
            setPassword: (password: string) =>
                handleError(
                    usersApi.setPasswordMe({
                        SetPasswordRequest: {
                            password,
                        },
                    }),
                ),
            removePassword: () => handleError(usersApi.deletePasswordMe()),
            totp: {
                add: (createTotpRequest: CreateTotpRequest) =>
                    handleError(totpApi.createTotpKey({ CreateTotpRequest: createTotpRequest })),
                all: () => handleError(totpApi.listTotpKeys()),
                delete: (uuid: UUID) => handleError(totpApi.deleteTotpKey({ uuid })),
            },
            webauthn: {
                all: () => handleError(webauthnApi.listWebauthnKeys()),
                delete: (uuid: UUID) => handleError(webauthnApi.deleteWebauthnKey({ uuid })),
            },
            profileImage: {
                upload: (body: Blob) => handleError(usersApi.setProfileImage({ body })),
                delete: () => handleError(usersApi.deleteProfileImage()),
            },
        },
        userInvites: {
            prepare: (uuid: UUID) => handleError(userInvitesApi.prepareUserInvite({ uuid })),
            setPassword: (req: SetPasswordRequest) =>
                handleError(
                    userInvitesApi.setPasswordInvite({
                        SetPasswordRequest: req,
                    }),
                ),
            removePassword: () => handleError(userInvitesApi.deletePasswordInvite()),
            addTotp: (req: CreateTotpRequest) =>
                handleError(userInvitesApi.createTotpKeyInvite({ CreateTotpRequest: req })),
            removeTotp: (uuid: UUID) => handleError(userInvitesApi.deleteTotpKeyInvite({ uuid })),
            removeWebauthn: (uuid: UUID) => handleError(userInvitesApi.deleteWebauthnKeyInvite({ uuid })),
            accept: () => handleError(userInvitesApi.acceptInvite()),
        },
        resetCredentials: {
            get: (uuid: UUID) => handleError(userResetsApi.prepareUserReset({ uuid })),
            setPassword: (password: string) =>
                handleError(
                    userResetsApi.setPasswordReset({
                        SetPasswordRequest: { password },
                    }),
                ),
            deletePassword: () => handleError(userResetsApi.deletePasswordReset()),
            deleteWebauthnKey: (uuid: UUID) => handleError(userResetsApi.deleteWebauthnKeyReset({ uuid })),
            deleteTOTP: (uuid: UUID) => handleError(userResetsApi.deleteTotpKeyReset({ uuid })),
            addTotp: (secret: string, token: string, label: string) =>
                handleError(
                    userResetsApi.createTotpKeyReset({
                        CreateTotpRequest: {
                            token,
                            label,
                            secret,
                        },
                    }),
                ),
            submit: () => handleError(userResetsApi.submitReset()),
        },
        sudoMode: {
            getOptions: () => handleError(sudoModeApi.getSudoOptions()),
            passwordVerify: (password: string) =>
                handleError(sudoModeApi.enterSudoPassword({ EnterSudoPasswordRequest: { password } })),
            totpVerify: (token: string) => handleError(sudoModeApi.enterSudoTotp({ EnterSudoTotpRequest: { token } })),
            status: () => handleError(sudoModeApi.getSudoStatus()),
        },
    },
    customer: {
        contacts: {
            all: () => handleError(customerContactsApi.getAllCustomerContactsCustomer({})),
            get: (uuid: UUID) => handleError(customerContactsApi.getCustomerContactCustomer({ uuid })),
            create: (createCustomerContactRequest: CreateCustomerContactRequest) =>
                handleError(
                    customerContactsApi.createCustomerContactCustomer({
                        CreateCustomerContactRequest: createCustomerContactRequest,
                    }),
                ),
            invite: (uuid: UUID) => handleError(customerContactsApi.inviteCustomerContactCustomer({ uuid })),
        },
        customers: {
            get: () => handleError(customersApi.getCustomerCustomer()),
        },
        pentest: {
            targets: {
                get: (uuid: UUID) => handleError(pentestTargetsApi.getPentestTargetsCustomer({ uuid })),
                add: (uuid: UUID, addPentestTargetRequest: AddPentestTargetRequest) =>
                    handleError(
                        pentestTargetsApi.addPentestTarget({
                            uuid,
                            AddPentestTargetRequest: addPentestTargetRequest,
                        }),
                    ),
                remove: (uuid: UUID) => handleError(pentestTargetsApi.removePentestTarget({ uuid })),
                submit: (uuid: UUID) => handleError(pentestTargetsApi.submitPentestTargets({ uuid })),
            },
        },
        projects: {
            all: () => handleError(projectsApi.getAllProjectsCustomer()),
            pentest: {
                get: (uuid: UUID) => handleError(pentestProjectsApi.getPentestProjectCustomer({ uuid })),
            },
            contacts: {
                get: (project: UUID) => handleError(projectsApi.getProjectContactsCustomer({ uuid: project })),
                add: (uuid: UUID, contact_uuid: UUID, category: ProjectContactCategory) =>
                    handleError(
                        projectsApi.addProjectContactCustomer({
                            uuid,
                            contact_uuid,
                            category,
                        }),
                    ),
                remove: (uuid: UUID, contact_uuid: UUID, category: ProjectContactCategory) =>
                    handleError(
                        projectsApi.removeProjectContactCustomer({
                            uuid,
                            contact_uuid,
                            category,
                        }),
                    ),
            },
        },
        questionnaires: {
            getCurrQuestion: (uuid: UUID) => handleError(questionnairesApi.getCurrentQuestion({ uuid })),
            submitCurrQuestion: (uuid: UUID, submitCurrentAnswerRequest: SubmitCurrentAnswerRequest) =>
                handleError(
                    questionnairesApi.submitCurrentAnswer({
                        uuid,
                        SubmitCurrentAnswerRequest: submitCurrentAnswerRequest,
                    }),
                ),
        },
        tasks: {
            ready: () => handleError(tasksApi.getReadyTasksCustomer()),
            getReady: (uuid: UUID) => handleError(tasksApi.getReadyTaskCustomer({ uuid })),
            assignProjectContactsV0: (uuid: UUID) => handleError(tasksApi.submitAssignProjectContactsV0({ uuid })),
        },
        users: {
            me: {
                update: (updateMeRequest: UpdateMeRequest) =>
                    handleError(usersApi.updateMeCommon({ UpdateMeRequest: updateMeRequest })),
            },
        },
    },
    internal: {
        customers: {
            all: () => handleError(customersApi.getAllCustomersInternal()),
            get: (uuid: UUID) => handleError(customersApi.getCustomerInternal({ uuid })),
            create: (createCustomerRequest: CreateCustomerRequest) =>
                handleError(customersApi.createCustomer({ CreateCustomerRequest: createCustomerRequest })),
            update: (uuid: UUID, updateCustomerInternalRequest: UpdateCustomerInternalRequest) =>
                handleError(
                    customersApi.updateCustomerInternal({
                        uuid,
                        UpdateCustomerInternalRequest: updateCustomerInternalRequest,
                    }),
                ),
            contacts: {
                get: (uuid: UUID) => handleError(customerContactsApi.getCustomerContactInternal({ uuid })),
                create: (uuid: UUID, createCustomerContactRequest: CreateCustomerContactRequest) =>
                    handleError(
                        customerContactsApi.createCustomerContactInternal({
                            uuid,
                            CreateCustomerContactRequest: createCustomerContactRequest,
                        }),
                    ),
                update: (uuid: UUID, updateCustomerContactRequest: UpdateCustomerContactRequest) =>
                    handleError(
                        customerContactsApi.updateCustomerContactInternal({
                            uuid,
                            UpdateCustomerContactRequest: updateCustomerContactRequest,
                        }),
                    ),
                delete: (uuid: UUID) => handleError(customerContactsApi.deleteCustomerContactInternal({ uuid })),
                invite: (uuid: UUID) => handleError(customerContactsApi.inviteCustomerContactInternal({ uuid })),
                resetCredentials: (uuid: UUID) =>
                    handleError(customerContactsApi.resetCustomerContactInternal({ uuid })),
            },
            addresses: {
                create: (uuid: UUID, createCustomerAddressRequest: CreateCustomerAddressRequest) =>
                    handleError(
                        customerAddressApi.createCustomerAddress({
                            uuid,
                            CreateCustomerAddressRequest: createCustomerAddressRequest,
                        }),
                    ),
                update: (uuid: UUID, updateCustomerAddressRequest: UpdateCustomerAddressRequest) =>
                    handleError(
                        customerAddressApi.updateCustomerAddress({
                            uuid,
                            UpdateCustomerAddressRequest: updateCustomerAddressRequest,
                        }),
                    ),
                delete: (uuid: UUID) => handleError(customerAddressApi.deleteCustomerAddress({ uuid })),
            },
            offerPotentials: {
                all: (customerUuid: UUID) =>
                    handleError(customerOfferPotentialsApi.getCustomerOfferPotentials({ uuid: customerUuid })),
                delete: (offerUuid: UUID) =>
                    handleError(customerOfferPotentialsApi.deleteOfferPotentials({ uuid: offerUuid })),
            },
        },
        internalUsers: {
            all: () => handleError(usersApi.getAllInternalUsers()),
        },
        projects: {
            pentest: {
                create: (createPentestProjectRequest: CreatePentestProjectRequest) =>
                    handleError(
                        pentestProjectsApi.createPentestProject({
                            CreatePentestProjectRequest: createPentestProjectRequest,
                        }),
                    ),
                get: (uuid: UUID) => handleError(pentestProjectsApi.getPentestProjectInternal({ uuid })),
                update: (uuid: UUID, updatePentestProjectRequest: UpdatePentestProjectRequest) =>
                    handleError(
                        pentestProjectsApi.updatePentestProject({
                            uuid,
                            UpdatePentestProjectRequest: updatePentestProjectRequest,
                        }),
                    ),
                scheduleKickoffV0: (uuid: UUID, scheduleKickoff: ScheduleKickoffV0Request) =>
                    handleError(
                        pentestTasksApi.scheduleKickoffV0({
                            uuid,
                            ScheduleKickoffV0Request: scheduleKickoff,
                        }),
                    ),
                scheduleKickoffV1: (uuid: UUID, scheduleKickoff: ScheduleKickoffV1Request) =>
                    handleError(
                        pentestTasksApi.scheduleKickoffV1({
                            uuid,
                            ScheduleKickoffV1Request: scheduleKickoff,
                        }),
                    ),
                scheduleDialogV0: (uuid: UUID, scheduleDialog: ScheduleDialogV0Request) =>
                    handleError(
                        pentestTasksApi.scheduleDialogV0({
                            uuid,
                            ScheduleDialogV0Request: scheduleDialog,
                        }),
                    ),
                scheduleDebriefV0: (uuid: UUID, scheduleDebrief: ScheduleDebriefV0Request) =>
                    handleError(
                        pentestTasksApi.scheduleDebriefV0({
                            uuid,
                            ScheduleDebriefV0Request: scheduleDebrief,
                        }),
                    ),
                scheduleMgmtPresentationV0: (uuid: UUID, scheduleMgmtPresentation: ScheduleMgmtPresentationV0Request) =>
                    handleError(
                        pentestTasksApi.scheduleMgmtPresentationV0({
                            uuid,
                            ScheduleMgmtPresentationV0Request: scheduleMgmtPresentation,
                        }),
                    ),
                setKickoffResultsV0: (
                    uuid: UUID,
                    enterPentestKickoffResultsRequests: EnterPentestKickoffResultsV0Request,
                ) =>
                    handleError(
                        pentestTasksApi.enterPentestKickoffResultsV0({
                            uuid,
                            EnterPentestKickoffResultsV0Request: enterPentestKickoffResultsRequests,
                        }),
                    ),
                setKickoffResultsV1: (
                    uuid: UUID,
                    enterPentestKickoffResultsRequests: EnterPentestKickoffResultsV1Request,
                ) =>
                    handleError(
                        pentestTasksApi.enterPentestKickoffResultsV1({
                            uuid,
                            EnterPentestKickoffResultsV1Request: enterPentestKickoffResultsRequests,
                        }),
                    ),
                setDialogResultsV0: (
                    uuid: UUID,
                    enterPentestDialogResultsRequest: EnterPentestDialogResultsV0Request,
                ) =>
                    handleError(
                        pentestTasksApi.enterPentestDialogResultsV0({
                            uuid,
                            EnterPentestDialogResultsV0Request: enterPentestDialogResultsRequest,
                        }),
                    ),
                setDialogResultsV1: (
                    uuid: UUID,
                    enterPentestDialogResultsRequest: EnterPentestDialogResultsV1Request,
                ) =>
                    handleError(
                        pentestTasksApi.enterPentestDialogResultsV1({
                            uuid,
                            EnterPentestDialogResultsV1Request: enterPentestDialogResultsRequest,
                        }),
                    ),
                setDebriefResultsV0: (uuid: UUID, enterPentestDebriefResults: EnterPentestDebriefResultsV0Request) =>
                    handleError(
                        pentestTasksApi.enterPentestDebriefResultsV0({
                            uuid,
                            EnterPentestDebriefResultsV0Request: enterPentestDebriefResults,
                        }),
                    ),
                setManagementPresentationResultsV0: (
                    uuid: UUID,
                    enterPentestMgmtPresentationResultsRequest: EnterPentestMgmtPresentationResultsV0Request,
                ) =>
                    handleError(
                        pentestTasksApi.enterPentestMgmtPresentationResultsV0({
                            uuid,
                            EnterPentestMgmtPresentationResultsV0Request: enterPentestMgmtPresentationResultsRequest,
                        }),
                    ),
                setManagementPresentationResultsV1: (
                    uuid: UUID,
                    enterPentestMgmtPresentationResults: EnterPentestMgmtPresentationResultsV1Request,
                ) =>
                    handleError(
                        pentestTasksApi.enterPentestMgmtPresentationResultsV1({
                            uuid,
                            EnterPentestMgmtPresentationResultsV1Request: enterPentestMgmtPresentationResults,
                        }),
                    ),
                uploadKickoffPresentationV0: (uuid: UUID, file_name: string, body: Blob) =>
                    handleError(
                        pentestTasksApi.uploadPentestKickoffPresentationV0({
                            uuid,
                            file_name,
                            body,
                        }),
                    ),
                uploadDialogPresentationV0: (uuid: UUID, file_name: string, body: Blob) =>
                    handleError(
                        pentestTasksApi.uploadPentestDialogPresentationV0({
                            uuid,
                            file_name,
                            body,
                        }),
                    ),
                uploadManagementPresentationV0: (uuid: UUID, file_name: string, body: Blob) =>
                    handleError(
                        pentestTasksApi.uploadPentestMgmtPresentationV0({
                            uuid,
                            file_name,
                            body,
                        }),
                    ),
                uploadPentestReportV0: (uuid: UUID, file_name: string, body: Blob) =>
                    handleError(
                        pentestTasksApi.uploadPentestReportV0({
                            uuid,
                            file_name,
                            body,
                        }),
                    ),
                getInviteMeeting: (uuid: UUID) => handleError(pentestTasksApi.getPentestInviteMeeting({ uuid })),
                getEnterResults: (uuid: UUID) => handleError(pentestTasksApi.getPentestEnterResults({ uuid })),
                targets: {
                    get: (uuid: UUID) => handleError(pentestTargetsApi.getPentestTargetsInternal({ uuid })),
                    approve: (uuid: UUID) => handleError(pentestTargetsApi.approvePentestTargets({ uuid })),
                    unsubmit: (uuid: UUID) => handleError(pentestTargetsApi.unsubmitPentestTargets({ uuid })),
                },
                tasks: {},
                changeKickoffDate: (uuid: UUID, date: string) =>
                    handleError(
                        pentestProjectsApi.changePentestKickoffDate({
                            uuid,
                            ChangePentestMeetingDateRequest: { date },
                        }),
                    ),
                changeDialogDate: (uuid: UUID, date: string) =>
                    handleError(
                        pentestProjectsApi.changePentestDialogDate({
                            uuid,
                            ChangePentestMeetingDateRequest: { date },
                        }),
                    ),
                changeDebriefDate: (uuid: UUID, date: string) =>
                    handleError(
                        pentestProjectsApi.changePentestDebriefDate({
                            uuid,
                            ChangePentestMeetingDateRequest: { date },
                        }),
                    ),
                changePresentationDate: (uuid: UUID, date: string) =>
                    handleError(
                        pentestProjectsApi.changePentestPresentationDate({
                            uuid,
                            ChangePentestMeetingDateRequest: { date },
                        }),
                    ),
                deleteReport: (uuid: UUID) => handleError(pentestProjectsApi.deletePentestReport({ uuid })),
            },
        },
        questionnaires: {
            questions: {
                all: () => handleError(questionnaireQuestionApi.getAllQuestions()),
                get: (uuid: UUID) => handleError(questionnaireQuestionApi.getQuestion({ uuid })),
                update: (uuid: UUID, updateQuestionRequest: UpdateQuestionRequest) =>
                    handleError(
                        questionnaireQuestionApi.updateQuestion({
                            uuid,
                            UpdateQuestionRequest: updateQuestionRequest,
                        }),
                    ),
                createChoice: (uuid: UUID, createQuestionChoice: CreateQuestionChoice) =>
                    handleError(
                        questionnaireQuestionApi.createQuestionChoice({
                            uuid,
                            CreateQuestionChoice: createQuestionChoice,
                        }),
                    ),
                updateChoices: (uuid: UUID, updateQuestionChoiceRequest: UpdateQuestionChoiceRequest) =>
                    handleError(
                        questionnaireQuestionApi.updateQuestionChoice({
                            uuid,
                            UpdateQuestionChoiceRequest: updateQuestionChoiceRequest,
                        }),
                    ),
                deleteChoice: (uuid: UUID) => handleError(questionnaireQuestionApi.deleteQuestionChoice({ uuid })),
                create: (createQuestionRequest: CreateQuestionRequest) =>
                    handleError(
                        questionnaireQuestionApi.createQuestion({ CreateQuestionRequest: createQuestionRequest }),
                    ),
                delete: (uuid: UUID) => handleError(questionnaireQuestionApi.deleteQuestion({ uuid })),
            },
            groups: {
                all: () => handleError(questionnaireGroupsApi.getAllQuestionGroups()),
                get: (uuid: UUID) => handleError(questionnaireGroupsApi.getQuestionGroup({ uuid })),
                create: (createQuestionGroupRequest: CreateQuestionGroupRequest) =>
                    handleError(
                        questionnaireGroupsApi.createQuestionGroup({
                            CreateQuestionGroupRequest: createQuestionGroupRequest,
                        }),
                    ),
                update: (uuid: UUID, updateQuestionGroupRequest: UpdateQuestionGroupRequest) =>
                    handleError(
                        questionnaireGroupsApi.updateQuestionGroup({
                            uuid,
                            UpdateQuestionGroupRequest: updateQuestionGroupRequest,
                        }),
                    ),
                delete: (uuid: UUID) => handleError(questionnaireGroupsApi.deleteQuestionGroup({ uuid })),
            },
            templates: {
                all: () => handleError(questionnaireTemplatesApi.getAllQuestionnaireTemplates()),
                get: (uuid: UUID) => handleError(questionnaireTemplatesApi.getQuestionnaireTemplate({ uuid })),
                create: (createQuestionnaireTemplateRequest: CreateQuestionnaireTemplateRequest) =>
                    handleError(
                        questionnaireTemplatesApi.createQuestionnaireTemplate({
                            CreateQuestionnaireTemplateRequest: createQuestionnaireTemplateRequest,
                        }),
                    ),
                update: (uuid: UUID, updateQuestionnaireTemplateRequest: UpdateQuestionnaireTemplateRequest) =>
                    handleError(
                        questionnaireTemplatesApi.updateQuestionnaireTemplate({
                            uuid,
                            UpdateQuestionnaireTemplateRequest: updateQuestionnaireTemplateRequest,
                        }),
                    ),
                delete: (uuid: UUID) => handleError(questionnaireTemplatesApi.deleteQuestionnaireTemplate({ uuid })),
            },
            exportTxt: (uuid: UUID) => handleError(questionnairesApi.exportQuestionnaireTxt({ uuid })),
            exportJson: (uuid: UUID) => handleError(questionnairesApi.exportQuestionnaireJson({ uuid })),
        },
        tasks: {
            ready: () => handleError(tasksApi.getReadyTasksInternal()),
            getReady: (uuid: UUID) => handleError(tasksApi.getReadyTaskInternal({ uuid })),
            allFiltered: (filter: GetAllTasksRequest) =>
                handleError(tasksApi.getAllTasks({ GetAllTasksRequest: filter })),
            finishExternalV0: (uuid: UUID) => handleError(tasksApi.finishExternalTaskV0({ uuid })),
        },
        users: {
            me: {
                update: (updateMeRequest: UpdateMeInternalRequest) =>
                    handleError(usersApi.updateMeInternal({ UpdateMeInternalRequest: updateMeRequest })),
                testMatrix: () => handleError(usersApi.testMatrixUserInternal()),
            },
        },
        offerPotentialTopics: {
            all: () => handleError(offerPotentialTopicsApi.getOfferPotentialTopics()),
            create: (createOfferPotentialTopicRequest: CreateOfferPotentialTopicRequest) =>
                handleError(
                    offerPotentialTopicsApi.createOfferPotentialTopic({
                        CreateOfferPotentialTopicRequest: createOfferPotentialTopicRequest,
                    }),
                ),
            update: (uuid: UUID, updateOfferPotentialTopicRequest: UpdateOfferPotentialTopicRequest) =>
                handleError(
                    offerPotentialTopicsApi.updateOfferPotentialTopic({
                        uuid,
                        UpdateOfferPotentialTopicRequest: updateOfferPotentialTopicRequest,
                    }),
                ),
        },
        lucy: {
            getCreateCampaignOptions: () => handleError(lucyAutomationApi.getCreateCampaignOptions()),
            getCampaigns: () => handleError(lucyAutomationApi.getCampaigns()),
            getScenarios: (campaignId: number) => handleError(lucyAutomationApi.getScenarios({ id: campaignId })),
            runCreateCampaign: (request: any) => handleError(lucyAutomationApi.runCreateCampaign({ body: request })),
            runGenerateAttackStatus: (request: any) =>
                handleError(lucyAutomationApi.runGenerateAttackStatus({ body: request })),
            runGenerateTrainingStatus: (request: any) =>
                handleError(lucyAutomationApi.runGenerateTrainingStatus({ body: request })),
            runGeneratePresentation: (request: any) =>
                handleError(lucyAutomationApi.runGeneratePresentation({ body: request })),
            runGenerateIrPresentation: (request: any) =>
                handleError(lucyAutomationApi.runGenerateIrPresentation({ body: request })),
            runGenerateRawCsv: (request: any) => handleError(lucyAutomationApi.runGenerateRawCsv({ body: request })),
            runGenerateReport: (request: any) => handleError(lucyAutomationApi.runGenerateReport({ body: request })),
            runImportEmployee: (request: any) => handleError(lucyAutomationApi.runImportEmployee({ body: request })),
            runImportEmail: (request: any) => handleError(lucyAutomationApi.runImportEmail({ body: request })),
        },
        scripts: {
            runExample: (request: any) => handleError(scriptsApi.runExampleScript({ body: request })),
            kill: (script: UUID) => handleError(scriptsApi.killScript({ uuid: script })),
        },
    },
};

/* eslint-enable */

/**
 * Wraps a promise returned by the generated SDK which handles its errors and returns a {@link Result}
 *
 * @param promise The promise to wrap. This should be a promise defined in the generated part of the API
 *
 * @returns a new promise with a result that wraps errors from the API
 */
export async function handleError<T>(promise: Promise<T>): Promise<Result<T, ApiError>> {
    try {
        return new Ok(await promise);
    } catch (e) {
        if (e instanceof ResponseError) {
            return new Err(await parseError(e.response));
        } else if (e instanceof RequiredError) {
            CONSOLE.error(e);
            return new Err({
                status_code: StatusCode.JsonDecodeError,
                message: "The server's response didn't match the spec",
            });
        } else {
            CONSOLE.error("Unknown error occurred:", e);
            return new Err({
                status_code: StatusCode.ArbitraryJSError,
                message: "Unknown error occurred",
            });
        }
    }
}
