import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect, useState } from "react";
import HeadingLayout from "src/components/base/heading-layout";
import { useTranslation } from "react-i18next";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { FullQuestionGroup, FullQuestionnaireTemplate, SimpleQuestionGroup } from "src/api/generated";
import { useForm, useStore } from "@tanstack/react-form";
import Form from "src/components/base/form";
import { Button } from "src/components/base/button";
import { ArrowUpRightIcon, ChevronLeftIcon, TrashIcon } from "@heroicons/react/20/solid";
import { Text } from "src/components/base/text";
import { Field, FieldGroup, Fieldset, Label } from "src/components/base/fieldset";
import { Description } from "@headlessui/react";
import { Input } from "src/components/base/input";
import { Textarea } from "src/components/base/textarea";
import { Divider } from "src/components/base/divider";
import { Combobox, ComboboxOption } from "src/components/base/combobox";
import { ReorderItem, ReorderList } from "src/components/base/reorder-list";
import { clsx } from "clsx";

const fieldClassName = clsx("grid grid-cols-2");

/**
 * The properties for {@link EditQuestionnaireTemplate}
 */
export type EditQuestionnaireTemplateProps = {};

/**
 * The edit view for questionnaire templates
 */
export default function EditQuestionnaireTemplate(props: EditQuestionnaireTemplateProps) {
    const [t] = useTranslation();
    const { questionnaireId } = Route.useParams();
    const navigate = Route.useNavigate();

    const [groups, setGroups] = useState<Array<SimpleQuestionGroup>>([]);
    const [template, setTemplate] = useState<FullQuestionnaireTemplate>();

    const [currQuestionGroup, setCurrQuestionGroup] = useState<FullQuestionGroup | null>(null);
    const [questionGroupQuery, setQuestionGroupQuery] = React.useState("");

    const editForm = useForm({
        defaultValues: {
            name: template?.name,
            notes: template?.notes,
            title: template?.title,
            description: template?.description,
            groups: template ? template.groups : [],
        },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            const res = await Api.internal.questionnaires.templates.update(questionnaireId, {
                name: value.name,
                notes: value.notes,
                title: value.title,
                description: value.description,
                groups: value.groups.map((g) => g.uuid),
            });

            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        toast.success(t("toast.saved-changes"));
                        retrieveTemplate().then(() => {
                            formApi.reset();
                        });
                    } else {
                        if (res.error.in_use) {
                            toast.error(t("error.in-use"));
                        }
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });
    const isDirty = useStore(editForm.store, (state) => state.isDirty);
    const templateGroups = useStore(editForm.store, (state) => state.values.groups);

    /**
     * Retrieve the current template
     */
    const retrieveTemplate = async () => {
        (await Api.internal.questionnaires.templates.get(questionnaireId)).match(
            (template) => setTemplate(template.optional),
            (err) => toast.error(err.message),
        );
    };

    useEffect(() => {
        Api.internal.questionnaires.groups.all().then((res) =>
            res.match(
                (groups) => setGroups(groups.list),
                (err) => toast.error(err.message),
            ),
        );
        retrieveTemplate().then();
    }, []);

    const filteredGroups =
        questionGroupQuery === ""
            ? groups.filter((q) => !templateGroups.some((gq) => gq.uuid === q.uuid))
            : groups
                  .filter((q) => !templateGroups.some((gq) => gq.uuid === q.uuid))
                  .filter((q) => q.name.toLowerCase().includes(questionGroupQuery.toLowerCase()));

    return (
        <Form onSubmit={editForm.handleSubmit} className={"h-full"}>
            <Button plain={true} onClick={() => navigate({ to: "/i/questionnaires/templates" })}>
                <ChevronLeftIcon />
                <Text>{t("button.back-to-overview")}</Text>
            </Button>

            <HeadingLayout
                className={"mt-6 h-full"}
                heading={t("internal.edit-questionnaire-template.heading.edit-template", { name: template?.name })}
                headingChildren={
                    <>
                        <Button plain={true} type={"submit"} disabled={!isDirty} onClick={() => editForm.reset()}>
                            {t("button.reset")}
                        </Button>
                        <Button color={"blue"} type={"submit"} disabled={!isDirty}>
                            {t("button.save")}
                        </Button>
                    </>
                }
            >
                <Fieldset>
                    <FieldGroup>
                        <editForm.Field name={"name"}>
                            {(fieldApi) => (
                                <Field className={fieldClassName}>
                                    <span>
                                        <Label>{t("label.name")}</Label>
                                        <Description></Description>
                                    </span>
                                    <Input
                                        className={"place-self-start"}
                                        required={true}
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </editForm.Field>
                        <editForm.Field name={"notes"}>
                            {(fieldApi) => (
                                <Field className={fieldClassName}>
                                    <span>
                                        <Label>{t("label.notes")}</Label>
                                        <Description></Description>
                                    </span>
                                    <Textarea
                                        className={"place-self-start"}
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </editForm.Field>

                        <Divider soft={true} />

                        <editForm.Field name={"title"}>
                            {(fieldApi) => (
                                <Field className={fieldClassName}>
                                    <span>
                                        <Label>{t("label.title")}</Label>
                                        <Description></Description>
                                    </span>
                                    <Input
                                        className={"place-self-start"}
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </editForm.Field>
                        <editForm.Field name={"description"}>
                            {(fieldApi) => (
                                <Field className={fieldClassName}>
                                    <span>
                                        <Label>{t("label.description")}</Label>
                                        <Description></Description>
                                    </span>
                                    <Textarea
                                        className={"place-self-start"}
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </editForm.Field>

                        <Divider soft={true} />
                    </FieldGroup>
                </Fieldset>

                <editForm.Field name={"groups"}>
                    {(fieldApi) => (
                        <div className={"grid h-full grid-cols-2 gap-20"}>
                            <Fieldset>
                                <FieldGroup>
                                    <Field>
                                        <Label>{t("label.search")}</Label>
                                        <Combobox
                                            value={currQuestionGroup}
                                            onChange={(q) => setCurrQuestionGroup(q)}
                                            onClose={() => setQuestionGroupQuery("")}
                                            queryDisplay={(q) => (q ? q.name : "")}
                                            onQueryChange={(q) => setQuestionGroupQuery(q)}
                                        >
                                            {filteredGroups.map((c) => (
                                                <ComboboxOption value={c}>
                                                    <Label>{c.name}</Label>
                                                </ComboboxOption>
                                            ))}
                                        </Combobox>
                                    </Field>
                                    <Button
                                        disabled={!currQuestionGroup}
                                        onClick={() => {
                                            const curr = currQuestionGroup;
                                            if (!curr) {
                                                return;
                                            }

                                            setCurrQuestionGroup(null);
                                            fieldApi.handleChange([...fieldApi.state.value, curr]);
                                        }}
                                    >
                                        {t("button.add")}
                                    </Button>
                                </FieldGroup>
                            </Fieldset>

                            <ReorderList axis={"y"} values={fieldApi.state.value} onReorder={fieldApi.handleChange}>
                                {fieldApi.state.value.map((q, idx) => (
                                    <ReorderItem key={q.uuid} value={q} className={"items-center justify-between"}>
                                        <span>{q.name}</span>
                                        <div className={"flex"}>
                                            <Button
                                                plain={true}
                                                href={"/i/questionnaires/groups/$groupId"}
                                                params={{ groupId: q.uuid }}
                                            >
                                                <ArrowUpRightIcon />
                                            </Button>
                                            <Button
                                                plain={true}
                                                onClick={() => {
                                                    const questions = [...fieldApi.state.value];
                                                    questions.splice(idx, 1);
                                                    fieldApi.handleChange(questions);
                                                }}
                                            >
                                                <TrashIcon />
                                            </Button>
                                        </div>
                                    </ReorderItem>
                                ))}
                            </ReorderList>
                        </div>
                    )}
                </editForm.Field>
            </HeadingLayout>
        </Form>
    );
}

export const Route = createFileRoute("/_internal/i/questionnaires/templates/$questionnaireId")({
    component: EditQuestionnaireTemplate,
});
