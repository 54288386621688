import { createFileRoute, useRouter } from "@tanstack/react-router";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useStore } from "@tanstack/react-form";
import { Api, UUID } from "src/api/api";
import { toast } from "react-toastify";
import Form from "src/components/base/form";
import {
    Description,
    ErrorMessage,
    Field,
    FieldGroup,
    Fieldset,
    Label,
    Legend,
    RequiredLabel,
} from "src/components/base/fieldset";
import { Button } from "src/components/base/button";
import { Input } from "src/components/base/input";
import { Textarea } from "src/components/base/textarea";
import BlockUnsavedChanges from "src/components/base/unsaved-changes";
import HeadingLayout from "src/components/base/heading-layout";
import { Divider } from "src/components/base/divider";
import MeetingAttendees from "src/components/internal/meeting-attendees";
import {
    CustomerDauerschutzState,
    OfferPotentialData,
    SimpleCustomerFile,
    SimpleOfferPotentialTopic,
} from "src/api/generated";
import { Checkbox, CheckboxField, CheckboxGroup } from "src/components/base/checkbox";
import { Radio, RadioField, RadioGroup } from "src/components/base/radio";

/**
 * The properties for {@link EnterDialogResultsForm}
 */
export type EnterDialogResultsFormProps = {};

/**
 * The form to enter dialog results
 */
function EnterDialogResultsForm(props: EnterDialogResultsFormProps) {
    const [t] = useTranslation("internal-enter-dialog-results-v1");
    const [tg] = useTranslation();
    const { taskId } = Route.useParams();
    const router = useRouter();

    const [presentation, setPresentation] = React.useState<SimpleCustomerFile | undefined>(undefined);
    const [offerSuggestions, setOfferSuggestions] = React.useState<Array<SimpleOfferPotentialTopic>>([]);

    const form = useForm({
        defaultValues: {
            debriefDate: "",
            debriefAttendees: {
                technical_lead: false,
                technical_assistant: false,
                management_assistant: false,
                project_manager: true,
            },
            skipDebrief: false,
            existingManagedServices: "",
            dauerschutz_state: "None" as CustomerDauerschutzState,
            dauerschutz_notes: "",
            offer_potential_notes: "",
            selectedOfferSuggestions: {} as Record<UUID, OfferPotentialData>,
            notes: "",
        },
        // eslint-disable-next-line
        onSubmit: async ({ formApi, value }) => {
            const res = await Api.internal.projects.pentest.setDialogResultsV1(taskId, {
                debrief_date: value.skipDebrief
                    ? { state: "Canceled" }
                    : value.debriefDate === ""
                      ? undefined
                      : {
                            state: "Scheduled",
                            date: new Date(value.debriefDate).toISOString(),
                        },
                debrief_attendees: value.debriefAttendees,
                existing_managed_services: value.existingManagedServices,
                dauerschutz_state: value.dauerschutz_state,
                dauerschutz_notes: value.dauerschutz_notes,
                offer_potential_notes: value.offer_potential_notes,
                offer_potentials: Object.entries(value.selectedOfferSuggestions).map(([key, value]) => ({
                    data: value,
                    topic: key,
                })),
                notes: value.notes,
            });
            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        router.history.back({ ignoreBlocker: true });
                    } else {
                        if (res.error.debrief_date) {
                            formApi.setFieldMeta("debriefDate", (meta) => {
                                meta.errors.push(tg("error.date-in-past"));
                                return meta;
                            });
                        }
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });
    const isDirty = useStore(form.store, (store) => store.isDirty);
    const skipDebrief = useStore(form.store, (store) => store.values.skipDebrief);
    const [customerName, setCustomerName] = React.useState<string>("");

    useEffect(() => {
        Api.internal.projects.pentest.getEnterResults(taskId).then((res) => {
            res.match(
                (res) => setPresentation(res.dialog_presentation),
                (err) => toast.error(err.message),
            );
        });
        Api.internal.tasks.getReady(taskId).then((res) => {
            res.match(
                (res) => {
                    if (res.project) {
                        setCustomerName(res.project.customer.legal_name);
                    }
                },
                (err) => toast.error(err.message),
            );
        });
        Api.internal.offerPotentialTopics.all().then((res) =>
            res.match(
                (ok) => setOfferSuggestions(ok.list),
                (err) => toast.error(err.message),
            ),
        );
    }, []);

    const upsellingCategories: { [key: string]: Array<SimpleOfferPotentialTopic> } = {};

    for (const offerSuggestion of offerSuggestions) {
        if (offerSuggestion.category in upsellingCategories) {
            upsellingCategories[offerSuggestion.category].push(offerSuggestion);
        } else {
            upsellingCategories[offerSuggestion.category] = [offerSuggestion];
        }
    }

    return (
        <HeadingLayout
            heading={tg("internal.enter-dialog-results.heading")}
            headingDescription={t("label.customer") + ": " + customerName}
            headingChildren={
                presentation && (
                    <Button
                        target={"_blank"}
                        href={`/api/frontend/v1/internal/customers/files/${presentation.uuid}`}
                        download={presentation.name}
                    >
                        {tg("button.download-pres")}
                    </Button>
                )
            }
        >
            <Form onSubmit={form.handleSubmit} className={"max-w-lg"}>
                <Fieldset>
                    <FieldGroup>
                        <form.Field name={"debriefDate"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{tg("label.debrief-date")}</Label>
                                    <Input
                                        disabled={skipDebrief}
                                        autoFocus={true}
                                        type={"datetime-local"}
                                        value={fieldApi.state.value}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                    {fieldApi.state.meta.errors.map((err) => (
                                        <ErrorMessage>{err}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"debriefAttendees"}>
                            {(fieldApi) => (
                                <MeetingAttendees
                                    skip={skipDebrief}
                                    technical_lead={fieldApi.state.value.technical_lead}
                                    technical_assistant={fieldApi.state.value.technical_assistant}
                                    management_assistant={fieldApi.state.value.management_assistant}
                                    project_manager={fieldApi.state.value.project_manager}
                                    onChange={(
                                        technical_lead,
                                        technical_assistant,
                                        management_assistant,
                                        project_manager,
                                    ) => {
                                        fieldApi.handleChange({
                                            ...fieldApi.state.value,
                                            project_manager: project_manager,
                                            technical_lead: technical_lead,
                                            technical_assistant: technical_assistant,
                                            management_assistant: management_assistant,
                                        });
                                    }}
                                />
                            )}
                        </form.Field>

                        <form.Field name={"skipDebrief"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{tg("label.skip-debrief")}</Label>
                                    <CheckboxGroup>
                                        <Checkbox checked={fieldApi.state.value} onChange={fieldApi.handleChange} />
                                    </CheckboxGroup>
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"existingManagedServices"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.managed-services")}</Label>
                                    <Description>{t("description.enter-name")}</Description>
                                    <Input
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"dauerschutz_state"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.dauerschutz-state")}</Label>
                                    <RadioGroup
                                        value={fieldApi.state.value}
                                        onChange={(v: CustomerDauerschutzState) => fieldApi.handleChange(v)}
                                    >
                                        <RadioField>
                                            <Label>{t("label.dauerschutz-none")}</Label>
                                            <Radio value={"None" as CustomerDauerschutzState} />
                                        </RadioField>
                                        <RadioField>
                                            <Label>{t("label.dauerschutz-insufficient")}</Label>
                                            <Radio value={"Insufficient" as CustomerDauerschutzState} />
                                        </RadioField>
                                        <RadioField>
                                            <Label>{t("label.dauerschutz-unmanaged")}</Label>
                                            <Radio value={"Unmanaged" as CustomerDauerschutzState} />
                                        </RadioField>
                                        <RadioField>
                                            <Label>{t("label.dauerschutz-managed")}</Label>
                                            <Radio value={"Managed" as CustomerDauerschutzState} />
                                        </RadioField>
                                    </RadioGroup>
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"dauerschutz_notes"}>
                            {(fieldApi) => (
                                <Field>
                                    <RequiredLabel>{t("label.dauerschutz-notes")}</RequiredLabel>
                                    <Input
                                        required={true}
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </form.Field>

                        <Divider />

                        <form.Field name={"selectedOfferSuggestions"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{tg("label.upsell-options")}</Label>
                                    <CheckboxGroup>
                                        {Object.entries(upsellingCategories)
                                            .sort(([a, _1], [b, _2]) => {
                                                if (a == "") {
                                                    if (b == "") return 0;
                                                    else return 1;
                                                } else {
                                                    if (b == "") return -1;
                                                    else return a.localeCompare(b);
                                                }
                                            })
                                            .map(([category, topics]) => (
                                                <>
                                                    <Legend>{category !== "" ? category : t("label.other")}</Legend>
                                                    {topics.map((offer) => (
                                                        <>
                                                            <CheckboxField key={offer.uuid}>
                                                                <Label>{offer.name}</Label>
                                                                <Checkbox
                                                                    key={offer.uuid}
                                                                    checked={
                                                                        fieldApi.state.value[offer.uuid] !== undefined
                                                                    }
                                                                    onChange={(e) => {
                                                                        const data = fieldApi.state.value;

                                                                        if (e) {
                                                                            data[offer.uuid] =
                                                                                offer.data.type === "None"
                                                                                    ? { type: "None" }
                                                                                    : offer.data.type === "Size"
                                                                                      ? {
                                                                                            type: "Size",
                                                                                            size: 0,
                                                                                        }
                                                                                      : { type: "Custom", text: "" };
                                                                            fieldApi.handleChange(data);
                                                                        } else {
                                                                            delete fieldApi.state.value[offer.uuid];
                                                                            fieldApi.handleChange(data);
                                                                        }
                                                                    }}
                                                                />
                                                            </CheckboxField>

                                                            {offer.data.type === "Size" &&
                                                                fieldApi.state.value[offer.uuid] && (
                                                                    <Field>
                                                                        <RequiredLabel>
                                                                            {offer.data.label}
                                                                        </RequiredLabel>
                                                                        {(() => {
                                                                            const curr =
                                                                                fieldApi.state.value[offer.uuid];
                                                                            if (!curr || curr.type !== "Size") {
                                                                                return undefined;
                                                                            }

                                                                            return (
                                                                                <Input
                                                                                    autoFocus={true}
                                                                                    required={true}
                                                                                    type={"number"}
                                                                                    value={curr.size}
                                                                                    onChange={(e) => {
                                                                                        const data =
                                                                                            fieldApi.state.value;
                                                                                        data[offer.uuid] = {
                                                                                            ...curr,
                                                                                            size: parseInt(
                                                                                                e.target.value,
                                                                                            ),
                                                                                        };

                                                                                        fieldApi.handleChange(data);
                                                                                    }}
                                                                                />
                                                                            );
                                                                        })()}
                                                                    </Field>
                                                                )}

                                                            {offer.data.type === "Custom" &&
                                                                fieldApi.state.value[offer.uuid] && (
                                                                    <Field>
                                                                        <RequiredLabel>
                                                                            {offer.data.label}
                                                                        </RequiredLabel>
                                                                        {(() => {
                                                                            const curr =
                                                                                fieldApi.state.value[offer.uuid];
                                                                            if (!curr || curr.type !== "Custom") {
                                                                                return undefined;
                                                                            }

                                                                            return (
                                                                                <Input
                                                                                    autoFocus={true}
                                                                                    required={true}
                                                                                    value={curr.text}
                                                                                    onChange={(e) => {
                                                                                        const data =
                                                                                            fieldApi.state.value;
                                                                                        data[offer.uuid] = {
                                                                                            ...curr,
                                                                                            text: e.target.value,
                                                                                        };

                                                                                        fieldApi.handleChange(data);
                                                                                    }}
                                                                                />
                                                                            );
                                                                        })()}
                                                                    </Field>
                                                                )}
                                                        </>
                                                    ))}
                                                </>
                                            ))}
                                    </CheckboxGroup>
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"offer_potential_notes"}>
                            {(fieldApi) => (
                                <Field>
                                    <RequiredLabel>{t("label.upsell-notes")}</RequiredLabel>
                                    <Input
                                        required={true}
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </form.Field>

                        <Divider />

                        <form.Field name={"notes"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{tg("label.notes")}</Label>
                                    <Textarea
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </form.Field>

                        <Button type={"submit"} color={"blue"}>
                            {tg("button.submit")}
                        </Button>
                    </FieldGroup>
                </Fieldset>
            </Form>

            <BlockUnsavedChanges condition={isDirty} />
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/forms/tasks/$taskId/enter-dialog-results-v1")({
    component: EnterDialogResultsForm,
});
