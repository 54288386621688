/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as IndexImport } from './routes/index'
import { Route as LinksLinksIImport } from './routes/_links/links/_i'
import { Route as LinksLinksCImport } from './routes/_links/links/_c'
import { Route as InternalIRunExampleScriptImport } from './routes/_internal/i/run-example-script'
import { Route as InternalIDashboardImport } from './routes/_internal/i/dashboard'
import { Route as InternalITasksImport } from './routes/_internal/i/_tasks'
import { Route as InternalIProfileImport } from './routes/_internal/i/_profile'
import { Route as InternalIPmViewImport } from './routes/_internal/i/_pm-view'
import { Route as CustomerCTasksImport } from './routes/_customer/c/tasks'
import { Route as CustomerCDashboardImport } from './routes/_customer/c/dashboard'
import { Route as CustomerCProfileImport } from './routes/_customer/c/_profile'
import { Route as AdminAUserImport } from './routes/_admin/a/user'
import { Route as AdminADashboardImport } from './routes/_admin/a/dashboard'
import { Route as AdminAProfileImport } from './routes/_admin/a/_profile'
import { Route as InternalICustomersIndexImport } from './routes/_internal/i/customers/index'
import { Route as InternalIUpsellingOfferSuggestionsImport } from './routes/_internal/i/upselling/offer-suggestions'
import { Route as InternalIProjectsCreateImport } from './routes/_internal/i/projects/create'
import { Route as AdminASettingsMatrixImport } from './routes/_admin/a/settings/matrix'
import { Route as AdminASettingsMailImport } from './routes/_admin/a/settings/mail'
import { Route as AdminASettingsKarlaKolumnaImport } from './routes/_admin/a/settings/karla-kolumna'
import { Route as InternalIQuestionnairesTemplatesIndexImport } from './routes/_internal/i/questionnaires/templates/index'
import { Route as InternalIQuestionnairesQuestionsIndexImport } from './routes/_internal/i/questionnaires/questions/index'
import { Route as InternalIQuestionnairesGroupsIndexImport } from './routes/_internal/i/questionnaires/groups/index'
import { Route as InternalIPhishingScriptsIndexImport } from './routes/_internal/i/phishing/scripts/index'
import { Route as InternalITasksTasksIndexImport } from './routes/_internal/i/_tasks/tasks/index'
import { Route as InternalIPmViewPmViewIndexImport } from './routes/_internal/i/_pm-view/pm-view/index'
import { Route as InternalIQuestionnairesTemplatesCreateImport } from './routes/_internal/i/questionnaires/templates/create'
import { Route as InternalIQuestionnairesTemplatesQuestionnaireIdImport } from './routes/_internal/i/questionnaires/templates/$questionnaireId'
import { Route as InternalIQuestionnairesQuestionsCreateImport } from './routes/_internal/i/questionnaires/questions/create'
import { Route as InternalIQuestionnairesQuestionsQuestionIdImport } from './routes/_internal/i/questionnaires/questions/$questionId'
import { Route as InternalIQuestionnairesGroupsCreateImport } from './routes/_internal/i/questionnaires/groups/create'
import { Route as InternalIQuestionnairesGroupsGroupIdImport } from './routes/_internal/i/questionnaires/groups/$groupId'
import { Route as InternalIPhishingScriptsGenerateTrainingStatusImport } from './routes/_internal/i/phishing/scripts/generate-training-status'
import { Route as InternalIPhishingScriptsGenerateRawCsvImport } from './routes/_internal/i/phishing/scripts/generate-raw-csv'
import { Route as InternalIPhishingScriptsGeneratePresentationImport } from './routes/_internal/i/phishing/scripts/generate-presentation'
import { Route as InternalIPhishingScriptsGenerateAttackStatusImport } from './routes/_internal/i/phishing/scripts/generate-attack-status'
import { Route as InternalIPhishingScriptsCreateCampaignImport } from './routes/_internal/i/phishing/scripts/create-campaign'
import { Route as InternalIPentestsProjectIdTargetsImport } from './routes/_internal/i/pentests/$projectId/targets'
import { Route as InternalIPentestsProjectIdPentestImport } from './routes/_internal/i/pentests/$projectId/_pentest'
import { Route as InternalICustomersCustomerIdCustomerImport } from './routes/_internal/i/customers/$customerId/_customer'
import { Route as InternalITasksTasksPoolImport } from './routes/_internal/i/_tasks/tasks/pool'
import { Route as InternalITasksTasksMyImport } from './routes/_internal/i/_tasks/tasks/my'
import { Route as InternalIProfileProfileSecurityImport } from './routes/_internal/i/_profile/profile/security'
import { Route as InternalIProfileProfileGeneralImport } from './routes/_internal/i/_profile/profile/general'
import { Route as InternalIPmViewPmViewOverdueTasksImport } from './routes/_internal/i/_pm-view/pm-view/overdue-tasks'
import { Route as CustomerCProfileProfileSecurityImport } from './routes/_customer/c/_profile/profile/security'
import { Route as CustomerCProfileProfileGeneralImport } from './routes/_customer/c/_profile/profile/general'
import { Route as AdminAProfileProfileSecurityImport } from './routes/_admin/a/_profile/profile/security'
import { Route as AdminAProfileProfileGeneralImport } from './routes/_admin/a/_profile/profile/general'
import { Route as LinksLinksIITasksIndexImport } from './routes/_links/links/_i/i/tasks/index'
import { Route as LinksLinksIICustomersCustomerIdImport } from './routes/_links/links/_i/i/customers/$customerId'
import { Route as LinksLinksCCTasksTaskIdImport } from './routes/_links/links/_c/c/tasks/$taskId'
import { Route as LinksLinksCCPentestsProjectIdImport } from './routes/_links/links/_c/c/pentests/$projectId'
import { Route as InternalIPentestsProjectIdPentestNotesImport } from './routes/_internal/i/pentests/$projectId/_pentest/notes'
import { Route as InternalIPentestsProjectIdPentestFilesImport } from './routes/_internal/i/pentests/$projectId/_pentest/files'
import { Route as InternalIPentestsProjectIdPentestContactsImport } from './routes/_internal/i/pentests/$projectId/_pentest/contacts'
import { Route as InternalIFormsTasksTaskIdUploadPentestReportImport } from './routes/_internal/i/forms/tasks/$taskId/upload-pentest-report'
import { Route as InternalIFormsTasksTaskIdUploadMgmtPresentationImport } from './routes/_internal/i/forms/tasks/$taskId/upload-mgmt-presentation'
import { Route as InternalIFormsTasksTaskIdUploadKickoffPresentationImport } from './routes/_internal/i/forms/tasks/$taskId/upload-kickoff-presentation'
import { Route as InternalIFormsTasksTaskIdUploadDialogPresentationImport } from './routes/_internal/i/forms/tasks/$taskId/upload-dialog-presentation'
import { Route as InternalIFormsTasksTaskIdTestSslV0Import } from './routes/_internal/i/forms/tasks/$taskId/test-ssl-v0'
import { Route as InternalIFormsTasksTaskIdTestExoticV0Import } from './routes/_internal/i/forms/tasks/$taskId/test-exotic-v0'
import { Route as InternalIFormsTasksTaskIdTestCommonV0Import } from './routes/_internal/i/forms/tasks/$taskId/test-common-v0'
import { Route as InternalIFormsTasksTaskIdStartInitialScanV0Import } from './routes/_internal/i/forms/tasks/$taskId/start-initial-scan-v0'
import { Route as InternalIFormsTasksTaskIdScheduleMgmtPresentationV0Import } from './routes/_internal/i/forms/tasks/$taskId/schedule-mgmt-presentation-v0'
import { Route as InternalIFormsTasksTaskIdScheduleKickoffV1Import } from './routes/_internal/i/forms/tasks/$taskId/schedule-kickoff-v1'
import { Route as InternalIFormsTasksTaskIdScheduleKickoffV0Import } from './routes/_internal/i/forms/tasks/$taskId/schedule-kickoff-v0'
import { Route as InternalIFormsTasksTaskIdScheduleDialogV0Import } from './routes/_internal/i/forms/tasks/$taskId/schedule-dialog-v0'
import { Route as InternalIFormsTasksTaskIdScheduleDebriefV0Import } from './routes/_internal/i/forms/tasks/$taskId/schedule-debrief-v0'
import { Route as InternalIFormsTasksTaskIdScanShodanV0Import } from './routes/_internal/i/forms/tasks/$taskId/scan-shodan-v0'
import { Route as InternalIFormsTasksTaskIdRunKrakenAssiV0Import } from './routes/_internal/i/forms/tasks/$taskId/run-kraken-assi-v0'
import { Route as InternalIFormsTasksTaskIdInviteMgmtPresentationV0Import } from './routes/_internal/i/forms/tasks/$taskId/invite-mgmt-presentation-v0'
import { Route as InternalIFormsTasksTaskIdInviteDialogV0Import } from './routes/_internal/i/forms/tasks/$taskId/invite-dialog-v0'
import { Route as InternalIFormsTasksTaskIdInviteDebriefV0Import } from './routes/_internal/i/forms/tasks/$taskId/invite-debrief-v0'
import { Route as InternalIFormsTasksTaskIdInviteCustomerUserV0Import } from './routes/_internal/i/forms/tasks/$taskId/invite-customer-user-v0'
import { Route as InternalIFormsTasksTaskIdImportDataV0Import } from './routes/_internal/i/forms/tasks/$taskId/import-data-v0'
import { Route as InternalIFormsTasksTaskIdEnterMgmtResultsV1Import } from './routes/_internal/i/forms/tasks/$taskId/enter-mgmt-results-v1'
import { Route as InternalIFormsTasksTaskIdEnterMgmtResultsV0Import } from './routes/_internal/i/forms/tasks/$taskId/enter-mgmt-results-v0'
import { Route as InternalIFormsTasksTaskIdEnterKickoffResultsV1Import } from './routes/_internal/i/forms/tasks/$taskId/enter-kickoff-results-v1'
import { Route as InternalIFormsTasksTaskIdEnterKickoffResultsV0Import } from './routes/_internal/i/forms/tasks/$taskId/enter-kickoff-results-v0'
import { Route as InternalIFormsTasksTaskIdEnterDialogResultsV1Import } from './routes/_internal/i/forms/tasks/$taskId/enter-dialog-results-v1'
import { Route as InternalIFormsTasksTaskIdEnterDialogResultsV0Import } from './routes/_internal/i/forms/tasks/$taskId/enter-dialog-results-v0'
import { Route as InternalIFormsTasksTaskIdEnterDebriefResultsV0Import } from './routes/_internal/i/forms/tasks/$taskId/enter-debrief-results-v0'
import { Route as InternalIFormsTasksTaskIdCreateServicesV0Import } from './routes/_internal/i/forms/tasks/$taskId/create-services-v0'
import { Route as InternalIFormsTasksTaskIdConfirmFoundV0Import } from './routes/_internal/i/forms/tasks/$taskId/confirm-found-v0'
import { Route as InternalIFormsTasksTaskIdAddHttpFindingsV0Import } from './routes/_internal/i/forms/tasks/$taskId/add-http-findings-v0'
import { Route as InternalICustomersCustomerIdCustomerUpsellingImport } from './routes/_internal/i/customers/$customerId/_customer/upselling'
import { Route as InternalICustomersCustomerIdCustomerGeneralImport } from './routes/_internal/i/customers/$customerId/_customer/general'
import { Route as InternalICustomersCustomerIdCustomerContactsImport } from './routes/_internal/i/customers/$customerId/_customer/contacts'
import { Route as CustomerCProjectsPentestsProjectIdPentestImport } from './routes/_customer/c/projects/pentests/$projectId/_pentest'
import { Route as CustomerCFormsTasksTaskIdTargetImport } from './routes/_customer/c/forms/tasks/$taskId/target'
import { Route as CustomerCFormsTasksTaskIdQuestionnaireImport } from './routes/_customer/c/forms/tasks/$taskId/questionnaire'
import { Route as CustomerCFormsTasksTaskIdAssignProjectContactsImport } from './routes/_customer/c/forms/tasks/$taskId/assign-project-contacts'
import { Route as LinksLinksIIPentestsProjectIdIndexImport } from './routes/_links/links/_i/i/pentests/$projectId/index'
import { Route as InternalIPentestsProjectIdPentestScheduleIndexImport } from './routes/_internal/i/pentests/$projectId/_pentest/schedule/index'
import { Route as InternalIPentestsProjectIdPentestGeneralIndexImport } from './routes/_internal/i/pentests/$projectId/_pentest/general/index'
import { Route as LinksLinksIIPentestsProjectIdTargetsImport } from './routes/_links/links/_i/i/pentests/$projectId/targets'
import { Route as InternalIPentestsProjectIdPentestScheduleChangeMgmtDateImport } from './routes/_internal/i/pentests/$projectId/_pentest/schedule/change-mgmt-date'
import { Route as InternalIPentestsProjectIdPentestScheduleChangeKickoffDateImport } from './routes/_internal/i/pentests/$projectId/_pentest/schedule/change-kickoff-date'
import { Route as InternalIPentestsProjectIdPentestScheduleChangeDialogDateImport } from './routes/_internal/i/pentests/$projectId/_pentest/schedule/change-dialog-date'
import { Route as InternalIPentestsProjectIdPentestScheduleChangeDebriefDateImport } from './routes/_internal/i/pentests/$projectId/_pentest/schedule/change-debrief-date'
import { Route as InternalIPentestsProjectIdPentestGeneralEditTaImport } from './routes/_internal/i/pentests/$projectId/_pentest/general/edit-ta'
import { Route as CustomerCProjectsPentestsProjectIdPentestScheduleImport } from './routes/_customer/c/projects/pentests/$projectId/_pentest/schedule'
import { Route as CustomerCProjectsPentestsProjectIdPentestGeneralImport } from './routes/_customer/c/projects/pentests/$projectId/_pentest/general'
import { Route as CustomerCProjectsPentestsProjectIdPentestFilesImport } from './routes/_customer/c/projects/pentests/$projectId/_pentest/files'
import { Route as CustomerCProjectsPentestsProjectIdPentestContactsImport } from './routes/_customer/c/projects/pentests/$projectId/_pentest/contacts'
import { Route as LinksLinksIIPentestsProjectIdQuestionnairesQuestionnaireIdImport } from './routes/_links/links/_i/i/pentests/$projectId/questionnaires/$questionnaireId'

// Create Virtual Routes

const LinksLazyImport = createFileRoute('/_links')()
const InternalLazyImport = createFileRoute('/_internal')()
const CustomerLazyImport = createFileRoute('/_customer')()
const AdminLazyImport = createFileRoute('/_admin')()
const LinksLinksImport = createFileRoute('/_links/links')()
const InternalIImport = createFileRoute('/_internal/i')()
const CustomerCImport = createFileRoute('/_customer/c')()
const AdminAImport = createFileRoute('/_admin/a')()
const ResetResetIdLazyImport = createFileRoute('/reset/$resetId')()
const InviteInviteIdLazyImport = createFileRoute('/invite/$inviteId')()
const InternalIPentestsProjectIdImport = createFileRoute(
  '/_internal/i/pentests/$projectId',
)()
const InternalICustomersCustomerIdImport = createFileRoute(
  '/_internal/i/customers/$customerId',
)()
const CustomerCProjectsPentestsProjectIdImport = createFileRoute(
  '/_customer/c/projects/pentests/$projectId',
)()
const InternalIPhishingScriptsGenerateIrPresentationLazyImport =
  createFileRoute('/_internal/i/phishing/scripts/generate-ir-presentation')()

// Create/Update Routes

const LinksLazyRoute = LinksLazyImport.update({
  id: '/_links',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/_links.lazy').then((d) => d.Route))

const InternalLazyRoute = InternalLazyImport.update({
  id: '/_internal',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/_internal.lazy').then((d) => d.Route))

const CustomerLazyRoute = CustomerLazyImport.update({
  id: '/_customer',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/_customer.lazy').then((d) => d.Route))

const AdminLazyRoute = AdminLazyImport.update({
  id: '/_admin',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/_admin.lazy').then((d) => d.Route))

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const LinksLinksRoute = LinksLinksImport.update({
  id: '/links',
  path: '/links',
  getParentRoute: () => LinksLazyRoute,
} as any)

const InternalIRoute = InternalIImport.update({
  id: '/i',
  path: '/i',
  getParentRoute: () => InternalLazyRoute,
} as any)

const CustomerCRoute = CustomerCImport.update({
  id: '/c',
  path: '/c',
  getParentRoute: () => CustomerLazyRoute,
} as any)

const AdminARoute = AdminAImport.update({
  id: '/a',
  path: '/a',
  getParentRoute: () => AdminLazyRoute,
} as any)

const ResetResetIdLazyRoute = ResetResetIdLazyImport.update({
  id: '/reset/$resetId',
  path: '/reset/$resetId',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/reset/$resetId.lazy').then((d) => d.Route),
)

const InviteInviteIdLazyRoute = InviteInviteIdLazyImport.update({
  id: '/invite/$inviteId',
  path: '/invite/$inviteId',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/invite/$inviteId.lazy').then((d) => d.Route),
)

const LinksLinksIRoute = LinksLinksIImport.update({
  id: '/_i',
  getParentRoute: () => LinksLinksRoute,
} as any)

const LinksLinksCRoute = LinksLinksCImport.update({
  id: '/_c',
  getParentRoute: () => LinksLinksRoute,
} as any)

const InternalIRunExampleScriptRoute = InternalIRunExampleScriptImport.update({
  id: '/run-example-script',
  path: '/run-example-script',
  getParentRoute: () => InternalIRoute,
} as any)

const InternalIDashboardRoute = InternalIDashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => InternalIRoute,
} as any)

const InternalITasksRoute = InternalITasksImport.update({
  id: '/_tasks',
  getParentRoute: () => InternalIRoute,
} as any)

const InternalIProfileRoute = InternalIProfileImport.update({
  id: '/_profile',
  getParentRoute: () => InternalIRoute,
} as any)

const InternalIPmViewRoute = InternalIPmViewImport.update({
  id: '/_pm-view',
  getParentRoute: () => InternalIRoute,
} as any)

const CustomerCTasksRoute = CustomerCTasksImport.update({
  id: '/tasks',
  path: '/tasks',
  getParentRoute: () => CustomerCRoute,
} as any)

const CustomerCDashboardRoute = CustomerCDashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => CustomerCRoute,
} as any)

const CustomerCProfileRoute = CustomerCProfileImport.update({
  id: '/_profile',
  getParentRoute: () => CustomerCRoute,
} as any)

const AdminAUserRoute = AdminAUserImport.update({
  id: '/user',
  path: '/user',
  getParentRoute: () => AdminARoute,
} as any)

const AdminADashboardRoute = AdminADashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => AdminARoute,
} as any)

const AdminAProfileRoute = AdminAProfileImport.update({
  id: '/_profile',
  getParentRoute: () => AdminARoute,
} as any)

const InternalIPentestsProjectIdRoute = InternalIPentestsProjectIdImport.update(
  {
    id: '/pentests/$projectId',
    path: '/pentests/$projectId',
    getParentRoute: () => InternalIRoute,
  } as any,
)

const InternalICustomersCustomerIdRoute =
  InternalICustomersCustomerIdImport.update({
    id: '/customers/$customerId',
    path: '/customers/$customerId',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalICustomersIndexRoute = InternalICustomersIndexImport.update({
  id: '/customers/',
  path: '/customers/',
  getParentRoute: () => InternalIRoute,
} as any)

const InternalIUpsellingOfferSuggestionsRoute =
  InternalIUpsellingOfferSuggestionsImport.update({
    id: '/upselling/offer-suggestions',
    path: '/upselling/offer-suggestions',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIProjectsCreateRoute = InternalIProjectsCreateImport.update({
  id: '/projects/create',
  path: '/projects/create',
  getParentRoute: () => InternalIRoute,
} as any)

const AdminASettingsMatrixRoute = AdminASettingsMatrixImport.update({
  id: '/settings/matrix',
  path: '/settings/matrix',
  getParentRoute: () => AdminARoute,
} as any)

const AdminASettingsMailRoute = AdminASettingsMailImport.update({
  id: '/settings/mail',
  path: '/settings/mail',
  getParentRoute: () => AdminARoute,
} as any)

const AdminASettingsKarlaKolumnaRoute = AdminASettingsKarlaKolumnaImport.update(
  {
    id: '/settings/karla-kolumna',
    path: '/settings/karla-kolumna',
    getParentRoute: () => AdminARoute,
  } as any,
)

const CustomerCProjectsPentestsProjectIdRoute =
  CustomerCProjectsPentestsProjectIdImport.update({
    id: '/projects/pentests/$projectId',
    path: '/projects/pentests/$projectId',
    getParentRoute: () => CustomerCRoute,
  } as any)

const InternalIQuestionnairesTemplatesIndexRoute =
  InternalIQuestionnairesTemplatesIndexImport.update({
    id: '/questionnaires/templates/',
    path: '/questionnaires/templates/',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIQuestionnairesQuestionsIndexRoute =
  InternalIQuestionnairesQuestionsIndexImport.update({
    id: '/questionnaires/questions/',
    path: '/questionnaires/questions/',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIQuestionnairesGroupsIndexRoute =
  InternalIQuestionnairesGroupsIndexImport.update({
    id: '/questionnaires/groups/',
    path: '/questionnaires/groups/',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIPhishingScriptsIndexRoute =
  InternalIPhishingScriptsIndexImport.update({
    id: '/phishing/scripts/',
    path: '/phishing/scripts/',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalITasksTasksIndexRoute = InternalITasksTasksIndexImport.update({
  id: '/tasks/',
  path: '/tasks/',
  getParentRoute: () => InternalITasksRoute,
} as any)

const InternalIPmViewPmViewIndexRoute = InternalIPmViewPmViewIndexImport.update(
  {
    id: '/pm-view/',
    path: '/pm-view/',
    getParentRoute: () => InternalIPmViewRoute,
  } as any,
)

const InternalIPhishingScriptsGenerateIrPresentationLazyRoute =
  InternalIPhishingScriptsGenerateIrPresentationLazyImport.update({
    id: '/phishing/scripts/generate-ir-presentation',
    path: '/phishing/scripts/generate-ir-presentation',
    getParentRoute: () => InternalIRoute,
  } as any).lazy(() =>
    import(
      './routes/_internal/i/phishing/scripts/generate-ir-presentation.lazy'
    ).then((d) => d.Route),
  )

const InternalIQuestionnairesTemplatesCreateRoute =
  InternalIQuestionnairesTemplatesCreateImport.update({
    id: '/questionnaires/templates/create',
    path: '/questionnaires/templates/create',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIQuestionnairesTemplatesQuestionnaireIdRoute =
  InternalIQuestionnairesTemplatesQuestionnaireIdImport.update({
    id: '/questionnaires/templates/$questionnaireId',
    path: '/questionnaires/templates/$questionnaireId',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIQuestionnairesQuestionsCreateRoute =
  InternalIQuestionnairesQuestionsCreateImport.update({
    id: '/questionnaires/questions/create',
    path: '/questionnaires/questions/create',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIQuestionnairesQuestionsQuestionIdRoute =
  InternalIQuestionnairesQuestionsQuestionIdImport.update({
    id: '/questionnaires/questions/$questionId',
    path: '/questionnaires/questions/$questionId',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIQuestionnairesGroupsCreateRoute =
  InternalIQuestionnairesGroupsCreateImport.update({
    id: '/questionnaires/groups/create',
    path: '/questionnaires/groups/create',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIQuestionnairesGroupsGroupIdRoute =
  InternalIQuestionnairesGroupsGroupIdImport.update({
    id: '/questionnaires/groups/$groupId',
    path: '/questionnaires/groups/$groupId',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIPhishingScriptsGenerateTrainingStatusRoute =
  InternalIPhishingScriptsGenerateTrainingStatusImport.update({
    id: '/phishing/scripts/generate-training-status',
    path: '/phishing/scripts/generate-training-status',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIPhishingScriptsGenerateRawCsvRoute =
  InternalIPhishingScriptsGenerateRawCsvImport.update({
    id: '/phishing/scripts/generate-raw-csv',
    path: '/phishing/scripts/generate-raw-csv',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIPhishingScriptsGeneratePresentationRoute =
  InternalIPhishingScriptsGeneratePresentationImport.update({
    id: '/phishing/scripts/generate-presentation',
    path: '/phishing/scripts/generate-presentation',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIPhishingScriptsGenerateAttackStatusRoute =
  InternalIPhishingScriptsGenerateAttackStatusImport.update({
    id: '/phishing/scripts/generate-attack-status',
    path: '/phishing/scripts/generate-attack-status',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIPhishingScriptsCreateCampaignRoute =
  InternalIPhishingScriptsCreateCampaignImport.update({
    id: '/phishing/scripts/create-campaign',
    path: '/phishing/scripts/create-campaign',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIPentestsProjectIdTargetsRoute =
  InternalIPentestsProjectIdTargetsImport.update({
    id: '/targets',
    path: '/targets',
    getParentRoute: () => InternalIPentestsProjectIdRoute,
  } as any)

const InternalIPentestsProjectIdPentestRoute =
  InternalIPentestsProjectIdPentestImport.update({
    id: '/_pentest',
    getParentRoute: () => InternalIPentestsProjectIdRoute,
  } as any)

const InternalICustomersCustomerIdCustomerRoute =
  InternalICustomersCustomerIdCustomerImport.update({
    id: '/_customer',
    getParentRoute: () => InternalICustomersCustomerIdRoute,
  } as any)

const InternalITasksTasksPoolRoute = InternalITasksTasksPoolImport.update({
  id: '/tasks/pool',
  path: '/tasks/pool',
  getParentRoute: () => InternalITasksRoute,
} as any)

const InternalITasksTasksMyRoute = InternalITasksTasksMyImport.update({
  id: '/tasks/my',
  path: '/tasks/my',
  getParentRoute: () => InternalITasksRoute,
} as any)

const InternalIProfileProfileSecurityRoute =
  InternalIProfileProfileSecurityImport.update({
    id: '/profile/security',
    path: '/profile/security',
    getParentRoute: () => InternalIProfileRoute,
  } as any)

const InternalIProfileProfileGeneralRoute =
  InternalIProfileProfileGeneralImport.update({
    id: '/profile/general',
    path: '/profile/general',
    getParentRoute: () => InternalIProfileRoute,
  } as any)

const InternalIPmViewPmViewOverdueTasksRoute =
  InternalIPmViewPmViewOverdueTasksImport.update({
    id: '/pm-view/overdue-tasks',
    path: '/pm-view/overdue-tasks',
    getParentRoute: () => InternalIPmViewRoute,
  } as any)

const CustomerCProfileProfileSecurityRoute =
  CustomerCProfileProfileSecurityImport.update({
    id: '/profile/security',
    path: '/profile/security',
    getParentRoute: () => CustomerCProfileRoute,
  } as any)

const CustomerCProfileProfileGeneralRoute =
  CustomerCProfileProfileGeneralImport.update({
    id: '/profile/general',
    path: '/profile/general',
    getParentRoute: () => CustomerCProfileRoute,
  } as any)

const AdminAProfileProfileSecurityRoute =
  AdminAProfileProfileSecurityImport.update({
    id: '/profile/security',
    path: '/profile/security',
    getParentRoute: () => AdminAProfileRoute,
  } as any)

const AdminAProfileProfileGeneralRoute =
  AdminAProfileProfileGeneralImport.update({
    id: '/profile/general',
    path: '/profile/general',
    getParentRoute: () => AdminAProfileRoute,
  } as any)

const LinksLinksIITasksIndexRoute = LinksLinksIITasksIndexImport.update({
  id: '/i/tasks/',
  path: '/i/tasks/',
  getParentRoute: () => LinksLinksIRoute,
} as any)

const LinksLinksIICustomersCustomerIdRoute =
  LinksLinksIICustomersCustomerIdImport.update({
    id: '/i/customers/$customerId',
    path: '/i/customers/$customerId',
    getParentRoute: () => LinksLinksIRoute,
  } as any)

const LinksLinksCCTasksTaskIdRoute = LinksLinksCCTasksTaskIdImport.update({
  id: '/c/tasks/$taskId',
  path: '/c/tasks/$taskId',
  getParentRoute: () => LinksLinksCRoute,
} as any)

const LinksLinksCCPentestsProjectIdRoute =
  LinksLinksCCPentestsProjectIdImport.update({
    id: '/c/pentests/$projectId',
    path: '/c/pentests/$projectId',
    getParentRoute: () => LinksLinksCRoute,
  } as any)

const InternalIPentestsProjectIdPentestNotesRoute =
  InternalIPentestsProjectIdPentestNotesImport.update({
    id: '/notes',
    path: '/notes',
    getParentRoute: () => InternalIPentestsProjectIdPentestRoute,
  } as any)

const InternalIPentestsProjectIdPentestFilesRoute =
  InternalIPentestsProjectIdPentestFilesImport.update({
    id: '/files',
    path: '/files',
    getParentRoute: () => InternalIPentestsProjectIdPentestRoute,
  } as any)

const InternalIPentestsProjectIdPentestContactsRoute =
  InternalIPentestsProjectIdPentestContactsImport.update({
    id: '/contacts',
    path: '/contacts',
    getParentRoute: () => InternalIPentestsProjectIdPentestRoute,
  } as any)

const InternalIFormsTasksTaskIdUploadPentestReportRoute =
  InternalIFormsTasksTaskIdUploadPentestReportImport.update({
    id: '/forms/tasks/$taskId/upload-pentest-report',
    path: '/forms/tasks/$taskId/upload-pentest-report',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdUploadMgmtPresentationRoute =
  InternalIFormsTasksTaskIdUploadMgmtPresentationImport.update({
    id: '/forms/tasks/$taskId/upload-mgmt-presentation',
    path: '/forms/tasks/$taskId/upload-mgmt-presentation',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdUploadKickoffPresentationRoute =
  InternalIFormsTasksTaskIdUploadKickoffPresentationImport.update({
    id: '/forms/tasks/$taskId/upload-kickoff-presentation',
    path: '/forms/tasks/$taskId/upload-kickoff-presentation',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdUploadDialogPresentationRoute =
  InternalIFormsTasksTaskIdUploadDialogPresentationImport.update({
    id: '/forms/tasks/$taskId/upload-dialog-presentation',
    path: '/forms/tasks/$taskId/upload-dialog-presentation',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdTestSslV0Route =
  InternalIFormsTasksTaskIdTestSslV0Import.update({
    id: '/forms/tasks/$taskId/test-ssl-v0',
    path: '/forms/tasks/$taskId/test-ssl-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdTestExoticV0Route =
  InternalIFormsTasksTaskIdTestExoticV0Import.update({
    id: '/forms/tasks/$taskId/test-exotic-v0',
    path: '/forms/tasks/$taskId/test-exotic-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdTestCommonV0Route =
  InternalIFormsTasksTaskIdTestCommonV0Import.update({
    id: '/forms/tasks/$taskId/test-common-v0',
    path: '/forms/tasks/$taskId/test-common-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdStartInitialScanV0Route =
  InternalIFormsTasksTaskIdStartInitialScanV0Import.update({
    id: '/forms/tasks/$taskId/start-initial-scan-v0',
    path: '/forms/tasks/$taskId/start-initial-scan-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdScheduleMgmtPresentationV0Route =
  InternalIFormsTasksTaskIdScheduleMgmtPresentationV0Import.update({
    id: '/forms/tasks/$taskId/schedule-mgmt-presentation-v0',
    path: '/forms/tasks/$taskId/schedule-mgmt-presentation-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdScheduleKickoffV1Route =
  InternalIFormsTasksTaskIdScheduleKickoffV1Import.update({
    id: '/forms/tasks/$taskId/schedule-kickoff-v1',
    path: '/forms/tasks/$taskId/schedule-kickoff-v1',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdScheduleKickoffV0Route =
  InternalIFormsTasksTaskIdScheduleKickoffV0Import.update({
    id: '/forms/tasks/$taskId/schedule-kickoff-v0',
    path: '/forms/tasks/$taskId/schedule-kickoff-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdScheduleDialogV0Route =
  InternalIFormsTasksTaskIdScheduleDialogV0Import.update({
    id: '/forms/tasks/$taskId/schedule-dialog-v0',
    path: '/forms/tasks/$taskId/schedule-dialog-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdScheduleDebriefV0Route =
  InternalIFormsTasksTaskIdScheduleDebriefV0Import.update({
    id: '/forms/tasks/$taskId/schedule-debrief-v0',
    path: '/forms/tasks/$taskId/schedule-debrief-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdScanShodanV0Route =
  InternalIFormsTasksTaskIdScanShodanV0Import.update({
    id: '/forms/tasks/$taskId/scan-shodan-v0',
    path: '/forms/tasks/$taskId/scan-shodan-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdRunKrakenAssiV0Route =
  InternalIFormsTasksTaskIdRunKrakenAssiV0Import.update({
    id: '/forms/tasks/$taskId/run-kraken-assi-v0',
    path: '/forms/tasks/$taskId/run-kraken-assi-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdInviteMgmtPresentationV0Route =
  InternalIFormsTasksTaskIdInviteMgmtPresentationV0Import.update({
    id: '/forms/tasks/$taskId/invite-mgmt-presentation-v0',
    path: '/forms/tasks/$taskId/invite-mgmt-presentation-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdInviteDialogV0Route =
  InternalIFormsTasksTaskIdInviteDialogV0Import.update({
    id: '/forms/tasks/$taskId/invite-dialog-v0',
    path: '/forms/tasks/$taskId/invite-dialog-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdInviteDebriefV0Route =
  InternalIFormsTasksTaskIdInviteDebriefV0Import.update({
    id: '/forms/tasks/$taskId/invite-debrief-v0',
    path: '/forms/tasks/$taskId/invite-debrief-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdInviteCustomerUserV0Route =
  InternalIFormsTasksTaskIdInviteCustomerUserV0Import.update({
    id: '/forms/tasks/$taskId/invite-customer-user-v0',
    path: '/forms/tasks/$taskId/invite-customer-user-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdImportDataV0Route =
  InternalIFormsTasksTaskIdImportDataV0Import.update({
    id: '/forms/tasks/$taskId/import-data-v0',
    path: '/forms/tasks/$taskId/import-data-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdEnterMgmtResultsV1Route =
  InternalIFormsTasksTaskIdEnterMgmtResultsV1Import.update({
    id: '/forms/tasks/$taskId/enter-mgmt-results-v1',
    path: '/forms/tasks/$taskId/enter-mgmt-results-v1',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdEnterMgmtResultsV0Route =
  InternalIFormsTasksTaskIdEnterMgmtResultsV0Import.update({
    id: '/forms/tasks/$taskId/enter-mgmt-results-v0',
    path: '/forms/tasks/$taskId/enter-mgmt-results-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdEnterKickoffResultsV1Route =
  InternalIFormsTasksTaskIdEnterKickoffResultsV1Import.update({
    id: '/forms/tasks/$taskId/enter-kickoff-results-v1',
    path: '/forms/tasks/$taskId/enter-kickoff-results-v1',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdEnterKickoffResultsV0Route =
  InternalIFormsTasksTaskIdEnterKickoffResultsV0Import.update({
    id: '/forms/tasks/$taskId/enter-kickoff-results-v0',
    path: '/forms/tasks/$taskId/enter-kickoff-results-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdEnterDialogResultsV1Route =
  InternalIFormsTasksTaskIdEnterDialogResultsV1Import.update({
    id: '/forms/tasks/$taskId/enter-dialog-results-v1',
    path: '/forms/tasks/$taskId/enter-dialog-results-v1',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdEnterDialogResultsV0Route =
  InternalIFormsTasksTaskIdEnterDialogResultsV0Import.update({
    id: '/forms/tasks/$taskId/enter-dialog-results-v0',
    path: '/forms/tasks/$taskId/enter-dialog-results-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdEnterDebriefResultsV0Route =
  InternalIFormsTasksTaskIdEnterDebriefResultsV0Import.update({
    id: '/forms/tasks/$taskId/enter-debrief-results-v0',
    path: '/forms/tasks/$taskId/enter-debrief-results-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdCreateServicesV0Route =
  InternalIFormsTasksTaskIdCreateServicesV0Import.update({
    id: '/forms/tasks/$taskId/create-services-v0',
    path: '/forms/tasks/$taskId/create-services-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdConfirmFoundV0Route =
  InternalIFormsTasksTaskIdConfirmFoundV0Import.update({
    id: '/forms/tasks/$taskId/confirm-found-v0',
    path: '/forms/tasks/$taskId/confirm-found-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalIFormsTasksTaskIdAddHttpFindingsV0Route =
  InternalIFormsTasksTaskIdAddHttpFindingsV0Import.update({
    id: '/forms/tasks/$taskId/add-http-findings-v0',
    path: '/forms/tasks/$taskId/add-http-findings-v0',
    getParentRoute: () => InternalIRoute,
  } as any)

const InternalICustomersCustomerIdCustomerUpsellingRoute =
  InternalICustomersCustomerIdCustomerUpsellingImport.update({
    id: '/upselling',
    path: '/upselling',
    getParentRoute: () => InternalICustomersCustomerIdCustomerRoute,
  } as any)

const InternalICustomersCustomerIdCustomerGeneralRoute =
  InternalICustomersCustomerIdCustomerGeneralImport.update({
    id: '/general',
    path: '/general',
    getParentRoute: () => InternalICustomersCustomerIdCustomerRoute,
  } as any)

const InternalICustomersCustomerIdCustomerContactsRoute =
  InternalICustomersCustomerIdCustomerContactsImport.update({
    id: '/contacts',
    path: '/contacts',
    getParentRoute: () => InternalICustomersCustomerIdCustomerRoute,
  } as any)

const CustomerCProjectsPentestsProjectIdPentestRoute =
  CustomerCProjectsPentestsProjectIdPentestImport.update({
    id: '/_pentest',
    getParentRoute: () => CustomerCProjectsPentestsProjectIdRoute,
  } as any)

const CustomerCFormsTasksTaskIdTargetRoute =
  CustomerCFormsTasksTaskIdTargetImport.update({
    id: '/forms/tasks/$taskId/target',
    path: '/forms/tasks/$taskId/target',
    getParentRoute: () => CustomerCRoute,
  } as any)

const CustomerCFormsTasksTaskIdQuestionnaireRoute =
  CustomerCFormsTasksTaskIdQuestionnaireImport.update({
    id: '/forms/tasks/$taskId/questionnaire',
    path: '/forms/tasks/$taskId/questionnaire',
    getParentRoute: () => CustomerCRoute,
  } as any)

const CustomerCFormsTasksTaskIdAssignProjectContactsRoute =
  CustomerCFormsTasksTaskIdAssignProjectContactsImport.update({
    id: '/forms/tasks/$taskId/assign-project-contacts',
    path: '/forms/tasks/$taskId/assign-project-contacts',
    getParentRoute: () => CustomerCRoute,
  } as any)

const LinksLinksIIPentestsProjectIdIndexRoute =
  LinksLinksIIPentestsProjectIdIndexImport.update({
    id: '/i/pentests/$projectId/',
    path: '/i/pentests/$projectId/',
    getParentRoute: () => LinksLinksIRoute,
  } as any)

const InternalIPentestsProjectIdPentestScheduleIndexRoute =
  InternalIPentestsProjectIdPentestScheduleIndexImport.update({
    id: '/schedule/',
    path: '/schedule/',
    getParentRoute: () => InternalIPentestsProjectIdPentestRoute,
  } as any)

const InternalIPentestsProjectIdPentestGeneralIndexRoute =
  InternalIPentestsProjectIdPentestGeneralIndexImport.update({
    id: '/general/',
    path: '/general/',
    getParentRoute: () => InternalIPentestsProjectIdPentestRoute,
  } as any)

const LinksLinksIIPentestsProjectIdTargetsRoute =
  LinksLinksIIPentestsProjectIdTargetsImport.update({
    id: '/i/pentests/$projectId/targets',
    path: '/i/pentests/$projectId/targets',
    getParentRoute: () => LinksLinksIRoute,
  } as any)

const InternalIPentestsProjectIdPentestScheduleChangeMgmtDateRoute =
  InternalIPentestsProjectIdPentestScheduleChangeMgmtDateImport.update({
    id: '/schedule/change-mgmt-date',
    path: '/schedule/change-mgmt-date',
    getParentRoute: () => InternalIPentestsProjectIdPentestRoute,
  } as any)

const InternalIPentestsProjectIdPentestScheduleChangeKickoffDateRoute =
  InternalIPentestsProjectIdPentestScheduleChangeKickoffDateImport.update({
    id: '/schedule/change-kickoff-date',
    path: '/schedule/change-kickoff-date',
    getParentRoute: () => InternalIPentestsProjectIdPentestRoute,
  } as any)

const InternalIPentestsProjectIdPentestScheduleChangeDialogDateRoute =
  InternalIPentestsProjectIdPentestScheduleChangeDialogDateImport.update({
    id: '/schedule/change-dialog-date',
    path: '/schedule/change-dialog-date',
    getParentRoute: () => InternalIPentestsProjectIdPentestRoute,
  } as any)

const InternalIPentestsProjectIdPentestScheduleChangeDebriefDateRoute =
  InternalIPentestsProjectIdPentestScheduleChangeDebriefDateImport.update({
    id: '/schedule/change-debrief-date',
    path: '/schedule/change-debrief-date',
    getParentRoute: () => InternalIPentestsProjectIdPentestRoute,
  } as any)

const InternalIPentestsProjectIdPentestGeneralEditTaRoute =
  InternalIPentestsProjectIdPentestGeneralEditTaImport.update({
    id: '/general/edit-ta',
    path: '/general/edit-ta',
    getParentRoute: () => InternalIPentestsProjectIdPentestRoute,
  } as any)

const CustomerCProjectsPentestsProjectIdPentestScheduleRoute =
  CustomerCProjectsPentestsProjectIdPentestScheduleImport.update({
    id: '/schedule',
    path: '/schedule',
    getParentRoute: () => CustomerCProjectsPentestsProjectIdPentestRoute,
  } as any)

const CustomerCProjectsPentestsProjectIdPentestGeneralRoute =
  CustomerCProjectsPentestsProjectIdPentestGeneralImport.update({
    id: '/general',
    path: '/general',
    getParentRoute: () => CustomerCProjectsPentestsProjectIdPentestRoute,
  } as any)

const CustomerCProjectsPentestsProjectIdPentestFilesRoute =
  CustomerCProjectsPentestsProjectIdPentestFilesImport.update({
    id: '/files',
    path: '/files',
    getParentRoute: () => CustomerCProjectsPentestsProjectIdPentestRoute,
  } as any)

const CustomerCProjectsPentestsProjectIdPentestContactsRoute =
  CustomerCProjectsPentestsProjectIdPentestContactsImport.update({
    id: '/contacts',
    path: '/contacts',
    getParentRoute: () => CustomerCProjectsPentestsProjectIdPentestRoute,
  } as any)

const LinksLinksIIPentestsProjectIdQuestionnairesQuestionnaireIdRoute =
  LinksLinksIIPentestsProjectIdQuestionnairesQuestionnaireIdImport.update({
    id: '/i/pentests/$projectId/questionnaires/$questionnaireId',
    path: '/i/pentests/$projectId/questionnaires/$questionnaireId',
    getParentRoute: () => LinksLinksIRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_admin': {
      id: '/_admin'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AdminLazyImport
      parentRoute: typeof rootRoute
    }
    '/_customer': {
      id: '/_customer'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof CustomerLazyImport
      parentRoute: typeof rootRoute
    }
    '/_internal': {
      id: '/_internal'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof InternalLazyImport
      parentRoute: typeof rootRoute
    }
    '/_links': {
      id: '/_links'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LinksLazyImport
      parentRoute: typeof rootRoute
    }
    '/invite/$inviteId': {
      id: '/invite/$inviteId'
      path: '/invite/$inviteId'
      fullPath: '/invite/$inviteId'
      preLoaderRoute: typeof InviteInviteIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/reset/$resetId': {
      id: '/reset/$resetId'
      path: '/reset/$resetId'
      fullPath: '/reset/$resetId'
      preLoaderRoute: typeof ResetResetIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/_admin/a': {
      id: '/_admin/a'
      path: '/a'
      fullPath: '/a'
      preLoaderRoute: typeof AdminAImport
      parentRoute: typeof AdminLazyImport
    }
    '/_admin/a/_profile': {
      id: '/_admin/a/_profile'
      path: '/a'
      fullPath: '/a'
      preLoaderRoute: typeof AdminAProfileImport
      parentRoute: typeof AdminARoute
    }
    '/_admin/a/dashboard': {
      id: '/_admin/a/dashboard'
      path: '/dashboard'
      fullPath: '/a/dashboard'
      preLoaderRoute: typeof AdminADashboardImport
      parentRoute: typeof AdminAImport
    }
    '/_admin/a/user': {
      id: '/_admin/a/user'
      path: '/user'
      fullPath: '/a/user'
      preLoaderRoute: typeof AdminAUserImport
      parentRoute: typeof AdminAImport
    }
    '/_customer/c': {
      id: '/_customer/c'
      path: '/c'
      fullPath: '/c'
      preLoaderRoute: typeof CustomerCImport
      parentRoute: typeof CustomerLazyImport
    }
    '/_customer/c/_profile': {
      id: '/_customer/c/_profile'
      path: '/c'
      fullPath: '/c'
      preLoaderRoute: typeof CustomerCProfileImport
      parentRoute: typeof CustomerCRoute
    }
    '/_customer/c/dashboard': {
      id: '/_customer/c/dashboard'
      path: '/dashboard'
      fullPath: '/c/dashboard'
      preLoaderRoute: typeof CustomerCDashboardImport
      parentRoute: typeof CustomerCImport
    }
    '/_customer/c/tasks': {
      id: '/_customer/c/tasks'
      path: '/tasks'
      fullPath: '/c/tasks'
      preLoaderRoute: typeof CustomerCTasksImport
      parentRoute: typeof CustomerCImport
    }
    '/_internal/i': {
      id: '/_internal/i'
      path: '/i'
      fullPath: '/i'
      preLoaderRoute: typeof InternalIImport
      parentRoute: typeof InternalLazyImport
    }
    '/_internal/i/_pm-view': {
      id: '/_internal/i/_pm-view'
      path: '/i'
      fullPath: '/i'
      preLoaderRoute: typeof InternalIPmViewImport
      parentRoute: typeof InternalIRoute
    }
    '/_internal/i/_profile': {
      id: '/_internal/i/_profile'
      path: ''
      fullPath: '/i'
      preLoaderRoute: typeof InternalIProfileImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/_tasks': {
      id: '/_internal/i/_tasks'
      path: ''
      fullPath: '/i'
      preLoaderRoute: typeof InternalITasksImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/dashboard': {
      id: '/_internal/i/dashboard'
      path: '/dashboard'
      fullPath: '/i/dashboard'
      preLoaderRoute: typeof InternalIDashboardImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/run-example-script': {
      id: '/_internal/i/run-example-script'
      path: '/run-example-script'
      fullPath: '/i/run-example-script'
      preLoaderRoute: typeof InternalIRunExampleScriptImport
      parentRoute: typeof InternalIImport
    }
    '/_links/links': {
      id: '/_links/links'
      path: '/links'
      fullPath: '/links'
      preLoaderRoute: typeof LinksLinksImport
      parentRoute: typeof LinksLazyImport
    }
    '/_links/links/_c': {
      id: '/_links/links/_c'
      path: '/links'
      fullPath: '/links'
      preLoaderRoute: typeof LinksLinksCImport
      parentRoute: typeof LinksLinksRoute
    }
    '/_links/links/_i': {
      id: '/_links/links/_i'
      path: ''
      fullPath: '/links'
      preLoaderRoute: typeof LinksLinksIImport
      parentRoute: typeof LinksLinksImport
    }
    '/_admin/a/settings/karla-kolumna': {
      id: '/_admin/a/settings/karla-kolumna'
      path: '/settings/karla-kolumna'
      fullPath: '/a/settings/karla-kolumna'
      preLoaderRoute: typeof AdminASettingsKarlaKolumnaImport
      parentRoute: typeof AdminAImport
    }
    '/_admin/a/settings/mail': {
      id: '/_admin/a/settings/mail'
      path: '/settings/mail'
      fullPath: '/a/settings/mail'
      preLoaderRoute: typeof AdminASettingsMailImport
      parentRoute: typeof AdminAImport
    }
    '/_admin/a/settings/matrix': {
      id: '/_admin/a/settings/matrix'
      path: '/settings/matrix'
      fullPath: '/a/settings/matrix'
      preLoaderRoute: typeof AdminASettingsMatrixImport
      parentRoute: typeof AdminAImport
    }
    '/_internal/i/projects/create': {
      id: '/_internal/i/projects/create'
      path: '/projects/create'
      fullPath: '/i/projects/create'
      preLoaderRoute: typeof InternalIProjectsCreateImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/upselling/offer-suggestions': {
      id: '/_internal/i/upselling/offer-suggestions'
      path: '/upselling/offer-suggestions'
      fullPath: '/i/upselling/offer-suggestions'
      preLoaderRoute: typeof InternalIUpsellingOfferSuggestionsImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/customers/': {
      id: '/_internal/i/customers/'
      path: '/customers'
      fullPath: '/i/customers'
      preLoaderRoute: typeof InternalICustomersIndexImport
      parentRoute: typeof InternalIImport
    }
    '/_admin/a/_profile/profile/general': {
      id: '/_admin/a/_profile/profile/general'
      path: '/profile/general'
      fullPath: '/a/profile/general'
      preLoaderRoute: typeof AdminAProfileProfileGeneralImport
      parentRoute: typeof AdminAProfileImport
    }
    '/_admin/a/_profile/profile/security': {
      id: '/_admin/a/_profile/profile/security'
      path: '/profile/security'
      fullPath: '/a/profile/security'
      preLoaderRoute: typeof AdminAProfileProfileSecurityImport
      parentRoute: typeof AdminAProfileImport
    }
    '/_customer/c/_profile/profile/general': {
      id: '/_customer/c/_profile/profile/general'
      path: '/profile/general'
      fullPath: '/c/profile/general'
      preLoaderRoute: typeof CustomerCProfileProfileGeneralImport
      parentRoute: typeof CustomerCProfileImport
    }
    '/_customer/c/_profile/profile/security': {
      id: '/_customer/c/_profile/profile/security'
      path: '/profile/security'
      fullPath: '/c/profile/security'
      preLoaderRoute: typeof CustomerCProfileProfileSecurityImport
      parentRoute: typeof CustomerCProfileImport
    }
    '/_internal/i/_pm-view/pm-view/overdue-tasks': {
      id: '/_internal/i/_pm-view/pm-view/overdue-tasks'
      path: '/pm-view/overdue-tasks'
      fullPath: '/i/pm-view/overdue-tasks'
      preLoaderRoute: typeof InternalIPmViewPmViewOverdueTasksImport
      parentRoute: typeof InternalIPmViewImport
    }
    '/_internal/i/_profile/profile/general': {
      id: '/_internal/i/_profile/profile/general'
      path: '/profile/general'
      fullPath: '/i/profile/general'
      preLoaderRoute: typeof InternalIProfileProfileGeneralImport
      parentRoute: typeof InternalIProfileImport
    }
    '/_internal/i/_profile/profile/security': {
      id: '/_internal/i/_profile/profile/security'
      path: '/profile/security'
      fullPath: '/i/profile/security'
      preLoaderRoute: typeof InternalIProfileProfileSecurityImport
      parentRoute: typeof InternalIProfileImport
    }
    '/_internal/i/_tasks/tasks/my': {
      id: '/_internal/i/_tasks/tasks/my'
      path: '/tasks/my'
      fullPath: '/i/tasks/my'
      preLoaderRoute: typeof InternalITasksTasksMyImport
      parentRoute: typeof InternalITasksImport
    }
    '/_internal/i/_tasks/tasks/pool': {
      id: '/_internal/i/_tasks/tasks/pool'
      path: '/tasks/pool'
      fullPath: '/i/tasks/pool'
      preLoaderRoute: typeof InternalITasksTasksPoolImport
      parentRoute: typeof InternalITasksImport
    }
    '/_internal/i/customers/$customerId': {
      id: '/_internal/i/customers/$customerId'
      path: '/customers/$customerId'
      fullPath: '/i/customers/$customerId'
      preLoaderRoute: typeof InternalICustomersCustomerIdImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/customers/$customerId/_customer': {
      id: '/_internal/i/customers/$customerId/_customer'
      path: '/customers/$customerId'
      fullPath: '/i/customers/$customerId'
      preLoaderRoute: typeof InternalICustomersCustomerIdCustomerImport
      parentRoute: typeof InternalICustomersCustomerIdRoute
    }
    '/_internal/i/pentests/$projectId': {
      id: '/_internal/i/pentests/$projectId'
      path: '/pentests/$projectId'
      fullPath: '/i/pentests/$projectId'
      preLoaderRoute: typeof InternalIPentestsProjectIdImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/pentests/$projectId/_pentest': {
      id: '/_internal/i/pentests/$projectId/_pentest'
      path: '/pentests/$projectId'
      fullPath: '/i/pentests/$projectId'
      preLoaderRoute: typeof InternalIPentestsProjectIdPentestImport
      parentRoute: typeof InternalIPentestsProjectIdRoute
    }
    '/_internal/i/pentests/$projectId/targets': {
      id: '/_internal/i/pentests/$projectId/targets'
      path: '/targets'
      fullPath: '/i/pentests/$projectId/targets'
      preLoaderRoute: typeof InternalIPentestsProjectIdTargetsImport
      parentRoute: typeof InternalIPentestsProjectIdImport
    }
    '/_internal/i/phishing/scripts/create-campaign': {
      id: '/_internal/i/phishing/scripts/create-campaign'
      path: '/phishing/scripts/create-campaign'
      fullPath: '/i/phishing/scripts/create-campaign'
      preLoaderRoute: typeof InternalIPhishingScriptsCreateCampaignImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/phishing/scripts/generate-attack-status': {
      id: '/_internal/i/phishing/scripts/generate-attack-status'
      path: '/phishing/scripts/generate-attack-status'
      fullPath: '/i/phishing/scripts/generate-attack-status'
      preLoaderRoute: typeof InternalIPhishingScriptsGenerateAttackStatusImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/phishing/scripts/generate-presentation': {
      id: '/_internal/i/phishing/scripts/generate-presentation'
      path: '/phishing/scripts/generate-presentation'
      fullPath: '/i/phishing/scripts/generate-presentation'
      preLoaderRoute: typeof InternalIPhishingScriptsGeneratePresentationImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/phishing/scripts/generate-raw-csv': {
      id: '/_internal/i/phishing/scripts/generate-raw-csv'
      path: '/phishing/scripts/generate-raw-csv'
      fullPath: '/i/phishing/scripts/generate-raw-csv'
      preLoaderRoute: typeof InternalIPhishingScriptsGenerateRawCsvImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/phishing/scripts/generate-training-status': {
      id: '/_internal/i/phishing/scripts/generate-training-status'
      path: '/phishing/scripts/generate-training-status'
      fullPath: '/i/phishing/scripts/generate-training-status'
      preLoaderRoute: typeof InternalIPhishingScriptsGenerateTrainingStatusImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/questionnaires/groups/$groupId': {
      id: '/_internal/i/questionnaires/groups/$groupId'
      path: '/questionnaires/groups/$groupId'
      fullPath: '/i/questionnaires/groups/$groupId'
      preLoaderRoute: typeof InternalIQuestionnairesGroupsGroupIdImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/questionnaires/groups/create': {
      id: '/_internal/i/questionnaires/groups/create'
      path: '/questionnaires/groups/create'
      fullPath: '/i/questionnaires/groups/create'
      preLoaderRoute: typeof InternalIQuestionnairesGroupsCreateImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/questionnaires/questions/$questionId': {
      id: '/_internal/i/questionnaires/questions/$questionId'
      path: '/questionnaires/questions/$questionId'
      fullPath: '/i/questionnaires/questions/$questionId'
      preLoaderRoute: typeof InternalIQuestionnairesQuestionsQuestionIdImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/questionnaires/questions/create': {
      id: '/_internal/i/questionnaires/questions/create'
      path: '/questionnaires/questions/create'
      fullPath: '/i/questionnaires/questions/create'
      preLoaderRoute: typeof InternalIQuestionnairesQuestionsCreateImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/questionnaires/templates/$questionnaireId': {
      id: '/_internal/i/questionnaires/templates/$questionnaireId'
      path: '/questionnaires/templates/$questionnaireId'
      fullPath: '/i/questionnaires/templates/$questionnaireId'
      preLoaderRoute: typeof InternalIQuestionnairesTemplatesQuestionnaireIdImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/questionnaires/templates/create': {
      id: '/_internal/i/questionnaires/templates/create'
      path: '/questionnaires/templates/create'
      fullPath: '/i/questionnaires/templates/create'
      preLoaderRoute: typeof InternalIQuestionnairesTemplatesCreateImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/phishing/scripts/generate-ir-presentation': {
      id: '/_internal/i/phishing/scripts/generate-ir-presentation'
      path: '/phishing/scripts/generate-ir-presentation'
      fullPath: '/i/phishing/scripts/generate-ir-presentation'
      preLoaderRoute: typeof InternalIPhishingScriptsGenerateIrPresentationLazyImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/_pm-view/pm-view/': {
      id: '/_internal/i/_pm-view/pm-view/'
      path: '/pm-view'
      fullPath: '/i/pm-view'
      preLoaderRoute: typeof InternalIPmViewPmViewIndexImport
      parentRoute: typeof InternalIPmViewImport
    }
    '/_internal/i/_tasks/tasks/': {
      id: '/_internal/i/_tasks/tasks/'
      path: '/tasks'
      fullPath: '/i/tasks'
      preLoaderRoute: typeof InternalITasksTasksIndexImport
      parentRoute: typeof InternalITasksImport
    }
    '/_internal/i/phishing/scripts/': {
      id: '/_internal/i/phishing/scripts/'
      path: '/phishing/scripts'
      fullPath: '/i/phishing/scripts'
      preLoaderRoute: typeof InternalIPhishingScriptsIndexImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/questionnaires/groups/': {
      id: '/_internal/i/questionnaires/groups/'
      path: '/questionnaires/groups'
      fullPath: '/i/questionnaires/groups'
      preLoaderRoute: typeof InternalIQuestionnairesGroupsIndexImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/questionnaires/questions/': {
      id: '/_internal/i/questionnaires/questions/'
      path: '/questionnaires/questions'
      fullPath: '/i/questionnaires/questions'
      preLoaderRoute: typeof InternalIQuestionnairesQuestionsIndexImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/questionnaires/templates/': {
      id: '/_internal/i/questionnaires/templates/'
      path: '/questionnaires/templates'
      fullPath: '/i/questionnaires/templates'
      preLoaderRoute: typeof InternalIQuestionnairesTemplatesIndexImport
      parentRoute: typeof InternalIImport
    }
    '/_customer/c/forms/tasks/$taskId/assign-project-contacts': {
      id: '/_customer/c/forms/tasks/$taskId/assign-project-contacts'
      path: '/forms/tasks/$taskId/assign-project-contacts'
      fullPath: '/c/forms/tasks/$taskId/assign-project-contacts'
      preLoaderRoute: typeof CustomerCFormsTasksTaskIdAssignProjectContactsImport
      parentRoute: typeof CustomerCImport
    }
    '/_customer/c/forms/tasks/$taskId/questionnaire': {
      id: '/_customer/c/forms/tasks/$taskId/questionnaire'
      path: '/forms/tasks/$taskId/questionnaire'
      fullPath: '/c/forms/tasks/$taskId/questionnaire'
      preLoaderRoute: typeof CustomerCFormsTasksTaskIdQuestionnaireImport
      parentRoute: typeof CustomerCImport
    }
    '/_customer/c/forms/tasks/$taskId/target': {
      id: '/_customer/c/forms/tasks/$taskId/target'
      path: '/forms/tasks/$taskId/target'
      fullPath: '/c/forms/tasks/$taskId/target'
      preLoaderRoute: typeof CustomerCFormsTasksTaskIdTargetImport
      parentRoute: typeof CustomerCImport
    }
    '/_customer/c/projects/pentests/$projectId': {
      id: '/_customer/c/projects/pentests/$projectId'
      path: '/projects/pentests/$projectId'
      fullPath: '/c/projects/pentests/$projectId'
      preLoaderRoute: typeof CustomerCProjectsPentestsProjectIdImport
      parentRoute: typeof CustomerCImport
    }
    '/_customer/c/projects/pentests/$projectId/_pentest': {
      id: '/_customer/c/projects/pentests/$projectId/_pentest'
      path: '/projects/pentests/$projectId'
      fullPath: '/c/projects/pentests/$projectId'
      preLoaderRoute: typeof CustomerCProjectsPentestsProjectIdPentestImport
      parentRoute: typeof CustomerCProjectsPentestsProjectIdRoute
    }
    '/_internal/i/customers/$customerId/_customer/contacts': {
      id: '/_internal/i/customers/$customerId/_customer/contacts'
      path: '/contacts'
      fullPath: '/i/customers/$customerId/contacts'
      preLoaderRoute: typeof InternalICustomersCustomerIdCustomerContactsImport
      parentRoute: typeof InternalICustomersCustomerIdCustomerImport
    }
    '/_internal/i/customers/$customerId/_customer/general': {
      id: '/_internal/i/customers/$customerId/_customer/general'
      path: '/general'
      fullPath: '/i/customers/$customerId/general'
      preLoaderRoute: typeof InternalICustomersCustomerIdCustomerGeneralImport
      parentRoute: typeof InternalICustomersCustomerIdCustomerImport
    }
    '/_internal/i/customers/$customerId/_customer/upselling': {
      id: '/_internal/i/customers/$customerId/_customer/upselling'
      path: '/upselling'
      fullPath: '/i/customers/$customerId/upselling'
      preLoaderRoute: typeof InternalICustomersCustomerIdCustomerUpsellingImport
      parentRoute: typeof InternalICustomersCustomerIdCustomerImport
    }
    '/_internal/i/forms/tasks/$taskId/add-http-findings-v0': {
      id: '/_internal/i/forms/tasks/$taskId/add-http-findings-v0'
      path: '/forms/tasks/$taskId/add-http-findings-v0'
      fullPath: '/i/forms/tasks/$taskId/add-http-findings-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdAddHttpFindingsV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/confirm-found-v0': {
      id: '/_internal/i/forms/tasks/$taskId/confirm-found-v0'
      path: '/forms/tasks/$taskId/confirm-found-v0'
      fullPath: '/i/forms/tasks/$taskId/confirm-found-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdConfirmFoundV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/create-services-v0': {
      id: '/_internal/i/forms/tasks/$taskId/create-services-v0'
      path: '/forms/tasks/$taskId/create-services-v0'
      fullPath: '/i/forms/tasks/$taskId/create-services-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdCreateServicesV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/enter-debrief-results-v0': {
      id: '/_internal/i/forms/tasks/$taskId/enter-debrief-results-v0'
      path: '/forms/tasks/$taskId/enter-debrief-results-v0'
      fullPath: '/i/forms/tasks/$taskId/enter-debrief-results-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdEnterDebriefResultsV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/enter-dialog-results-v0': {
      id: '/_internal/i/forms/tasks/$taskId/enter-dialog-results-v0'
      path: '/forms/tasks/$taskId/enter-dialog-results-v0'
      fullPath: '/i/forms/tasks/$taskId/enter-dialog-results-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdEnterDialogResultsV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/enter-dialog-results-v1': {
      id: '/_internal/i/forms/tasks/$taskId/enter-dialog-results-v1'
      path: '/forms/tasks/$taskId/enter-dialog-results-v1'
      fullPath: '/i/forms/tasks/$taskId/enter-dialog-results-v1'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdEnterDialogResultsV1Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/enter-kickoff-results-v0': {
      id: '/_internal/i/forms/tasks/$taskId/enter-kickoff-results-v0'
      path: '/forms/tasks/$taskId/enter-kickoff-results-v0'
      fullPath: '/i/forms/tasks/$taskId/enter-kickoff-results-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdEnterKickoffResultsV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/enter-kickoff-results-v1': {
      id: '/_internal/i/forms/tasks/$taskId/enter-kickoff-results-v1'
      path: '/forms/tasks/$taskId/enter-kickoff-results-v1'
      fullPath: '/i/forms/tasks/$taskId/enter-kickoff-results-v1'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdEnterKickoffResultsV1Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/enter-mgmt-results-v0': {
      id: '/_internal/i/forms/tasks/$taskId/enter-mgmt-results-v0'
      path: '/forms/tasks/$taskId/enter-mgmt-results-v0'
      fullPath: '/i/forms/tasks/$taskId/enter-mgmt-results-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdEnterMgmtResultsV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/enter-mgmt-results-v1': {
      id: '/_internal/i/forms/tasks/$taskId/enter-mgmt-results-v1'
      path: '/forms/tasks/$taskId/enter-mgmt-results-v1'
      fullPath: '/i/forms/tasks/$taskId/enter-mgmt-results-v1'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdEnterMgmtResultsV1Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/import-data-v0': {
      id: '/_internal/i/forms/tasks/$taskId/import-data-v0'
      path: '/forms/tasks/$taskId/import-data-v0'
      fullPath: '/i/forms/tasks/$taskId/import-data-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdImportDataV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/invite-customer-user-v0': {
      id: '/_internal/i/forms/tasks/$taskId/invite-customer-user-v0'
      path: '/forms/tasks/$taskId/invite-customer-user-v0'
      fullPath: '/i/forms/tasks/$taskId/invite-customer-user-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdInviteCustomerUserV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/invite-debrief-v0': {
      id: '/_internal/i/forms/tasks/$taskId/invite-debrief-v0'
      path: '/forms/tasks/$taskId/invite-debrief-v0'
      fullPath: '/i/forms/tasks/$taskId/invite-debrief-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdInviteDebriefV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/invite-dialog-v0': {
      id: '/_internal/i/forms/tasks/$taskId/invite-dialog-v0'
      path: '/forms/tasks/$taskId/invite-dialog-v0'
      fullPath: '/i/forms/tasks/$taskId/invite-dialog-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdInviteDialogV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/invite-mgmt-presentation-v0': {
      id: '/_internal/i/forms/tasks/$taskId/invite-mgmt-presentation-v0'
      path: '/forms/tasks/$taskId/invite-mgmt-presentation-v0'
      fullPath: '/i/forms/tasks/$taskId/invite-mgmt-presentation-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdInviteMgmtPresentationV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/run-kraken-assi-v0': {
      id: '/_internal/i/forms/tasks/$taskId/run-kraken-assi-v0'
      path: '/forms/tasks/$taskId/run-kraken-assi-v0'
      fullPath: '/i/forms/tasks/$taskId/run-kraken-assi-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdRunKrakenAssiV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/scan-shodan-v0': {
      id: '/_internal/i/forms/tasks/$taskId/scan-shodan-v0'
      path: '/forms/tasks/$taskId/scan-shodan-v0'
      fullPath: '/i/forms/tasks/$taskId/scan-shodan-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdScanShodanV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/schedule-debrief-v0': {
      id: '/_internal/i/forms/tasks/$taskId/schedule-debrief-v0'
      path: '/forms/tasks/$taskId/schedule-debrief-v0'
      fullPath: '/i/forms/tasks/$taskId/schedule-debrief-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdScheduleDebriefV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/schedule-dialog-v0': {
      id: '/_internal/i/forms/tasks/$taskId/schedule-dialog-v0'
      path: '/forms/tasks/$taskId/schedule-dialog-v0'
      fullPath: '/i/forms/tasks/$taskId/schedule-dialog-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdScheduleDialogV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/schedule-kickoff-v0': {
      id: '/_internal/i/forms/tasks/$taskId/schedule-kickoff-v0'
      path: '/forms/tasks/$taskId/schedule-kickoff-v0'
      fullPath: '/i/forms/tasks/$taskId/schedule-kickoff-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdScheduleKickoffV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/schedule-kickoff-v1': {
      id: '/_internal/i/forms/tasks/$taskId/schedule-kickoff-v1'
      path: '/forms/tasks/$taskId/schedule-kickoff-v1'
      fullPath: '/i/forms/tasks/$taskId/schedule-kickoff-v1'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdScheduleKickoffV1Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/schedule-mgmt-presentation-v0': {
      id: '/_internal/i/forms/tasks/$taskId/schedule-mgmt-presentation-v0'
      path: '/forms/tasks/$taskId/schedule-mgmt-presentation-v0'
      fullPath: '/i/forms/tasks/$taskId/schedule-mgmt-presentation-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdScheduleMgmtPresentationV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/start-initial-scan-v0': {
      id: '/_internal/i/forms/tasks/$taskId/start-initial-scan-v0'
      path: '/forms/tasks/$taskId/start-initial-scan-v0'
      fullPath: '/i/forms/tasks/$taskId/start-initial-scan-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdStartInitialScanV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/test-common-v0': {
      id: '/_internal/i/forms/tasks/$taskId/test-common-v0'
      path: '/forms/tasks/$taskId/test-common-v0'
      fullPath: '/i/forms/tasks/$taskId/test-common-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdTestCommonV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/test-exotic-v0': {
      id: '/_internal/i/forms/tasks/$taskId/test-exotic-v0'
      path: '/forms/tasks/$taskId/test-exotic-v0'
      fullPath: '/i/forms/tasks/$taskId/test-exotic-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdTestExoticV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/test-ssl-v0': {
      id: '/_internal/i/forms/tasks/$taskId/test-ssl-v0'
      path: '/forms/tasks/$taskId/test-ssl-v0'
      fullPath: '/i/forms/tasks/$taskId/test-ssl-v0'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdTestSslV0Import
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/upload-dialog-presentation': {
      id: '/_internal/i/forms/tasks/$taskId/upload-dialog-presentation'
      path: '/forms/tasks/$taskId/upload-dialog-presentation'
      fullPath: '/i/forms/tasks/$taskId/upload-dialog-presentation'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdUploadDialogPresentationImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/upload-kickoff-presentation': {
      id: '/_internal/i/forms/tasks/$taskId/upload-kickoff-presentation'
      path: '/forms/tasks/$taskId/upload-kickoff-presentation'
      fullPath: '/i/forms/tasks/$taskId/upload-kickoff-presentation'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdUploadKickoffPresentationImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/upload-mgmt-presentation': {
      id: '/_internal/i/forms/tasks/$taskId/upload-mgmt-presentation'
      path: '/forms/tasks/$taskId/upload-mgmt-presentation'
      fullPath: '/i/forms/tasks/$taskId/upload-mgmt-presentation'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdUploadMgmtPresentationImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/forms/tasks/$taskId/upload-pentest-report': {
      id: '/_internal/i/forms/tasks/$taskId/upload-pentest-report'
      path: '/forms/tasks/$taskId/upload-pentest-report'
      fullPath: '/i/forms/tasks/$taskId/upload-pentest-report'
      preLoaderRoute: typeof InternalIFormsTasksTaskIdUploadPentestReportImport
      parentRoute: typeof InternalIImport
    }
    '/_internal/i/pentests/$projectId/_pentest/contacts': {
      id: '/_internal/i/pentests/$projectId/_pentest/contacts'
      path: '/contacts'
      fullPath: '/i/pentests/$projectId/contacts'
      preLoaderRoute: typeof InternalIPentestsProjectIdPentestContactsImport
      parentRoute: typeof InternalIPentestsProjectIdPentestImport
    }
    '/_internal/i/pentests/$projectId/_pentest/files': {
      id: '/_internal/i/pentests/$projectId/_pentest/files'
      path: '/files'
      fullPath: '/i/pentests/$projectId/files'
      preLoaderRoute: typeof InternalIPentestsProjectIdPentestFilesImport
      parentRoute: typeof InternalIPentestsProjectIdPentestImport
    }
    '/_internal/i/pentests/$projectId/_pentest/notes': {
      id: '/_internal/i/pentests/$projectId/_pentest/notes'
      path: '/notes'
      fullPath: '/i/pentests/$projectId/notes'
      preLoaderRoute: typeof InternalIPentestsProjectIdPentestNotesImport
      parentRoute: typeof InternalIPentestsProjectIdPentestImport
    }
    '/_links/links/_c/c/pentests/$projectId': {
      id: '/_links/links/_c/c/pentests/$projectId'
      path: '/c/pentests/$projectId'
      fullPath: '/links/c/pentests/$projectId'
      preLoaderRoute: typeof LinksLinksCCPentestsProjectIdImport
      parentRoute: typeof LinksLinksCImport
    }
    '/_links/links/_c/c/tasks/$taskId': {
      id: '/_links/links/_c/c/tasks/$taskId'
      path: '/c/tasks/$taskId'
      fullPath: '/links/c/tasks/$taskId'
      preLoaderRoute: typeof LinksLinksCCTasksTaskIdImport
      parentRoute: typeof LinksLinksCImport
    }
    '/_links/links/_i/i/customers/$customerId': {
      id: '/_links/links/_i/i/customers/$customerId'
      path: '/i/customers/$customerId'
      fullPath: '/links/i/customers/$customerId'
      preLoaderRoute: typeof LinksLinksIICustomersCustomerIdImport
      parentRoute: typeof LinksLinksIImport
    }
    '/_links/links/_i/i/tasks/': {
      id: '/_links/links/_i/i/tasks/'
      path: '/i/tasks'
      fullPath: '/links/i/tasks'
      preLoaderRoute: typeof LinksLinksIITasksIndexImport
      parentRoute: typeof LinksLinksIImport
    }
    '/_customer/c/projects/pentests/$projectId/_pentest/contacts': {
      id: '/_customer/c/projects/pentests/$projectId/_pentest/contacts'
      path: '/contacts'
      fullPath: '/c/projects/pentests/$projectId/contacts'
      preLoaderRoute: typeof CustomerCProjectsPentestsProjectIdPentestContactsImport
      parentRoute: typeof CustomerCProjectsPentestsProjectIdPentestImport
    }
    '/_customer/c/projects/pentests/$projectId/_pentest/files': {
      id: '/_customer/c/projects/pentests/$projectId/_pentest/files'
      path: '/files'
      fullPath: '/c/projects/pentests/$projectId/files'
      preLoaderRoute: typeof CustomerCProjectsPentestsProjectIdPentestFilesImport
      parentRoute: typeof CustomerCProjectsPentestsProjectIdPentestImport
    }
    '/_customer/c/projects/pentests/$projectId/_pentest/general': {
      id: '/_customer/c/projects/pentests/$projectId/_pentest/general'
      path: '/general'
      fullPath: '/c/projects/pentests/$projectId/general'
      preLoaderRoute: typeof CustomerCProjectsPentestsProjectIdPentestGeneralImport
      parentRoute: typeof CustomerCProjectsPentestsProjectIdPentestImport
    }
    '/_customer/c/projects/pentests/$projectId/_pentest/schedule': {
      id: '/_customer/c/projects/pentests/$projectId/_pentest/schedule'
      path: '/schedule'
      fullPath: '/c/projects/pentests/$projectId/schedule'
      preLoaderRoute: typeof CustomerCProjectsPentestsProjectIdPentestScheduleImport
      parentRoute: typeof CustomerCProjectsPentestsProjectIdPentestImport
    }
    '/_internal/i/pentests/$projectId/_pentest/general/edit-ta': {
      id: '/_internal/i/pentests/$projectId/_pentest/general/edit-ta'
      path: '/general/edit-ta'
      fullPath: '/i/pentests/$projectId/general/edit-ta'
      preLoaderRoute: typeof InternalIPentestsProjectIdPentestGeneralEditTaImport
      parentRoute: typeof InternalIPentestsProjectIdPentestImport
    }
    '/_internal/i/pentests/$projectId/_pentest/schedule/change-debrief-date': {
      id: '/_internal/i/pentests/$projectId/_pentest/schedule/change-debrief-date'
      path: '/schedule/change-debrief-date'
      fullPath: '/i/pentests/$projectId/schedule/change-debrief-date'
      preLoaderRoute: typeof InternalIPentestsProjectIdPentestScheduleChangeDebriefDateImport
      parentRoute: typeof InternalIPentestsProjectIdPentestImport
    }
    '/_internal/i/pentests/$projectId/_pentest/schedule/change-dialog-date': {
      id: '/_internal/i/pentests/$projectId/_pentest/schedule/change-dialog-date'
      path: '/schedule/change-dialog-date'
      fullPath: '/i/pentests/$projectId/schedule/change-dialog-date'
      preLoaderRoute: typeof InternalIPentestsProjectIdPentestScheduleChangeDialogDateImport
      parentRoute: typeof InternalIPentestsProjectIdPentestImport
    }
    '/_internal/i/pentests/$projectId/_pentest/schedule/change-kickoff-date': {
      id: '/_internal/i/pentests/$projectId/_pentest/schedule/change-kickoff-date'
      path: '/schedule/change-kickoff-date'
      fullPath: '/i/pentests/$projectId/schedule/change-kickoff-date'
      preLoaderRoute: typeof InternalIPentestsProjectIdPentestScheduleChangeKickoffDateImport
      parentRoute: typeof InternalIPentestsProjectIdPentestImport
    }
    '/_internal/i/pentests/$projectId/_pentest/schedule/change-mgmt-date': {
      id: '/_internal/i/pentests/$projectId/_pentest/schedule/change-mgmt-date'
      path: '/schedule/change-mgmt-date'
      fullPath: '/i/pentests/$projectId/schedule/change-mgmt-date'
      preLoaderRoute: typeof InternalIPentestsProjectIdPentestScheduleChangeMgmtDateImport
      parentRoute: typeof InternalIPentestsProjectIdPentestImport
    }
    '/_links/links/_i/i/pentests/$projectId/targets': {
      id: '/_links/links/_i/i/pentests/$projectId/targets'
      path: '/i/pentests/$projectId/targets'
      fullPath: '/links/i/pentests/$projectId/targets'
      preLoaderRoute: typeof LinksLinksIIPentestsProjectIdTargetsImport
      parentRoute: typeof LinksLinksIImport
    }
    '/_internal/i/pentests/$projectId/_pentest/general/': {
      id: '/_internal/i/pentests/$projectId/_pentest/general/'
      path: '/general'
      fullPath: '/i/pentests/$projectId/general'
      preLoaderRoute: typeof InternalIPentestsProjectIdPentestGeneralIndexImport
      parentRoute: typeof InternalIPentestsProjectIdPentestImport
    }
    '/_internal/i/pentests/$projectId/_pentest/schedule/': {
      id: '/_internal/i/pentests/$projectId/_pentest/schedule/'
      path: '/schedule'
      fullPath: '/i/pentests/$projectId/schedule'
      preLoaderRoute: typeof InternalIPentestsProjectIdPentestScheduleIndexImport
      parentRoute: typeof InternalIPentestsProjectIdPentestImport
    }
    '/_links/links/_i/i/pentests/$projectId/': {
      id: '/_links/links/_i/i/pentests/$projectId/'
      path: '/i/pentests/$projectId'
      fullPath: '/links/i/pentests/$projectId'
      preLoaderRoute: typeof LinksLinksIIPentestsProjectIdIndexImport
      parentRoute: typeof LinksLinksIImport
    }
    '/_links/links/_i/i/pentests/$projectId/questionnaires/$questionnaireId': {
      id: '/_links/links/_i/i/pentests/$projectId/questionnaires/$questionnaireId'
      path: '/i/pentests/$projectId/questionnaires/$questionnaireId'
      fullPath: '/links/i/pentests/$projectId/questionnaires/$questionnaireId'
      preLoaderRoute: typeof LinksLinksIIPentestsProjectIdQuestionnairesQuestionnaireIdImport
      parentRoute: typeof LinksLinksIImport
    }
  }
}

// Create and export the route tree

interface AdminAProfileRouteChildren {
  AdminAProfileProfileGeneralRoute: typeof AdminAProfileProfileGeneralRoute
  AdminAProfileProfileSecurityRoute: typeof AdminAProfileProfileSecurityRoute
}

const AdminAProfileRouteChildren: AdminAProfileRouteChildren = {
  AdminAProfileProfileGeneralRoute: AdminAProfileProfileGeneralRoute,
  AdminAProfileProfileSecurityRoute: AdminAProfileProfileSecurityRoute,
}

const AdminAProfileRouteWithChildren = AdminAProfileRoute._addFileChildren(
  AdminAProfileRouteChildren,
)

interface AdminARouteChildren {
  AdminAProfileRoute: typeof AdminAProfileRouteWithChildren
  AdminADashboardRoute: typeof AdminADashboardRoute
  AdminAUserRoute: typeof AdminAUserRoute
  AdminASettingsKarlaKolumnaRoute: typeof AdminASettingsKarlaKolumnaRoute
  AdminASettingsMailRoute: typeof AdminASettingsMailRoute
  AdminASettingsMatrixRoute: typeof AdminASettingsMatrixRoute
}

const AdminARouteChildren: AdminARouteChildren = {
  AdminAProfileRoute: AdminAProfileRouteWithChildren,
  AdminADashboardRoute: AdminADashboardRoute,
  AdminAUserRoute: AdminAUserRoute,
  AdminASettingsKarlaKolumnaRoute: AdminASettingsKarlaKolumnaRoute,
  AdminASettingsMailRoute: AdminASettingsMailRoute,
  AdminASettingsMatrixRoute: AdminASettingsMatrixRoute,
}

const AdminARouteWithChildren =
  AdminARoute._addFileChildren(AdminARouteChildren)

interface AdminLazyRouteChildren {
  AdminARoute: typeof AdminARouteWithChildren
}

const AdminLazyRouteChildren: AdminLazyRouteChildren = {
  AdminARoute: AdminARouteWithChildren,
}

const AdminLazyRouteWithChildren = AdminLazyRoute._addFileChildren(
  AdminLazyRouteChildren,
)

interface CustomerCProfileRouteChildren {
  CustomerCProfileProfileGeneralRoute: typeof CustomerCProfileProfileGeneralRoute
  CustomerCProfileProfileSecurityRoute: typeof CustomerCProfileProfileSecurityRoute
}

const CustomerCProfileRouteChildren: CustomerCProfileRouteChildren = {
  CustomerCProfileProfileGeneralRoute: CustomerCProfileProfileGeneralRoute,
  CustomerCProfileProfileSecurityRoute: CustomerCProfileProfileSecurityRoute,
}

const CustomerCProfileRouteWithChildren =
  CustomerCProfileRoute._addFileChildren(CustomerCProfileRouteChildren)

interface CustomerCProjectsPentestsProjectIdPentestRouteChildren {
  CustomerCProjectsPentestsProjectIdPentestContactsRoute: typeof CustomerCProjectsPentestsProjectIdPentestContactsRoute
  CustomerCProjectsPentestsProjectIdPentestFilesRoute: typeof CustomerCProjectsPentestsProjectIdPentestFilesRoute
  CustomerCProjectsPentestsProjectIdPentestGeneralRoute: typeof CustomerCProjectsPentestsProjectIdPentestGeneralRoute
  CustomerCProjectsPentestsProjectIdPentestScheduleRoute: typeof CustomerCProjectsPentestsProjectIdPentestScheduleRoute
}

const CustomerCProjectsPentestsProjectIdPentestRouteChildren: CustomerCProjectsPentestsProjectIdPentestRouteChildren =
  {
    CustomerCProjectsPentestsProjectIdPentestContactsRoute:
      CustomerCProjectsPentestsProjectIdPentestContactsRoute,
    CustomerCProjectsPentestsProjectIdPentestFilesRoute:
      CustomerCProjectsPentestsProjectIdPentestFilesRoute,
    CustomerCProjectsPentestsProjectIdPentestGeneralRoute:
      CustomerCProjectsPentestsProjectIdPentestGeneralRoute,
    CustomerCProjectsPentestsProjectIdPentestScheduleRoute:
      CustomerCProjectsPentestsProjectIdPentestScheduleRoute,
  }

const CustomerCProjectsPentestsProjectIdPentestRouteWithChildren =
  CustomerCProjectsPentestsProjectIdPentestRoute._addFileChildren(
    CustomerCProjectsPentestsProjectIdPentestRouteChildren,
  )

interface CustomerCProjectsPentestsProjectIdRouteChildren {
  CustomerCProjectsPentestsProjectIdPentestRoute: typeof CustomerCProjectsPentestsProjectIdPentestRouteWithChildren
}

const CustomerCProjectsPentestsProjectIdRouteChildren: CustomerCProjectsPentestsProjectIdRouteChildren =
  {
    CustomerCProjectsPentestsProjectIdPentestRoute:
      CustomerCProjectsPentestsProjectIdPentestRouteWithChildren,
  }

const CustomerCProjectsPentestsProjectIdRouteWithChildren =
  CustomerCProjectsPentestsProjectIdRoute._addFileChildren(
    CustomerCProjectsPentestsProjectIdRouteChildren,
  )

interface CustomerCRouteChildren {
  CustomerCProfileRoute: typeof CustomerCProfileRouteWithChildren
  CustomerCDashboardRoute: typeof CustomerCDashboardRoute
  CustomerCTasksRoute: typeof CustomerCTasksRoute
  CustomerCFormsTasksTaskIdAssignProjectContactsRoute: typeof CustomerCFormsTasksTaskIdAssignProjectContactsRoute
  CustomerCFormsTasksTaskIdQuestionnaireRoute: typeof CustomerCFormsTasksTaskIdQuestionnaireRoute
  CustomerCFormsTasksTaskIdTargetRoute: typeof CustomerCFormsTasksTaskIdTargetRoute
  CustomerCProjectsPentestsProjectIdRoute: typeof CustomerCProjectsPentestsProjectIdRouteWithChildren
}

const CustomerCRouteChildren: CustomerCRouteChildren = {
  CustomerCProfileRoute: CustomerCProfileRouteWithChildren,
  CustomerCDashboardRoute: CustomerCDashboardRoute,
  CustomerCTasksRoute: CustomerCTasksRoute,
  CustomerCFormsTasksTaskIdAssignProjectContactsRoute:
    CustomerCFormsTasksTaskIdAssignProjectContactsRoute,
  CustomerCFormsTasksTaskIdQuestionnaireRoute:
    CustomerCFormsTasksTaskIdQuestionnaireRoute,
  CustomerCFormsTasksTaskIdTargetRoute: CustomerCFormsTasksTaskIdTargetRoute,
  CustomerCProjectsPentestsProjectIdRoute:
    CustomerCProjectsPentestsProjectIdRouteWithChildren,
}

const CustomerCRouteWithChildren = CustomerCRoute._addFileChildren(
  CustomerCRouteChildren,
)

interface CustomerLazyRouteChildren {
  CustomerCRoute: typeof CustomerCRouteWithChildren
}

const CustomerLazyRouteChildren: CustomerLazyRouteChildren = {
  CustomerCRoute: CustomerCRouteWithChildren,
}

const CustomerLazyRouteWithChildren = CustomerLazyRoute._addFileChildren(
  CustomerLazyRouteChildren,
)

interface InternalIPmViewRouteChildren {
  InternalIPmViewPmViewOverdueTasksRoute: typeof InternalIPmViewPmViewOverdueTasksRoute
  InternalIPmViewPmViewIndexRoute: typeof InternalIPmViewPmViewIndexRoute
}

const InternalIPmViewRouteChildren: InternalIPmViewRouteChildren = {
  InternalIPmViewPmViewOverdueTasksRoute:
    InternalIPmViewPmViewOverdueTasksRoute,
  InternalIPmViewPmViewIndexRoute: InternalIPmViewPmViewIndexRoute,
}

const InternalIPmViewRouteWithChildren = InternalIPmViewRoute._addFileChildren(
  InternalIPmViewRouteChildren,
)

interface InternalIProfileRouteChildren {
  InternalIProfileProfileGeneralRoute: typeof InternalIProfileProfileGeneralRoute
  InternalIProfileProfileSecurityRoute: typeof InternalIProfileProfileSecurityRoute
}

const InternalIProfileRouteChildren: InternalIProfileRouteChildren = {
  InternalIProfileProfileGeneralRoute: InternalIProfileProfileGeneralRoute,
  InternalIProfileProfileSecurityRoute: InternalIProfileProfileSecurityRoute,
}

const InternalIProfileRouteWithChildren =
  InternalIProfileRoute._addFileChildren(InternalIProfileRouteChildren)

interface InternalITasksRouteChildren {
  InternalITasksTasksMyRoute: typeof InternalITasksTasksMyRoute
  InternalITasksTasksPoolRoute: typeof InternalITasksTasksPoolRoute
  InternalITasksTasksIndexRoute: typeof InternalITasksTasksIndexRoute
}

const InternalITasksRouteChildren: InternalITasksRouteChildren = {
  InternalITasksTasksMyRoute: InternalITasksTasksMyRoute,
  InternalITasksTasksPoolRoute: InternalITasksTasksPoolRoute,
  InternalITasksTasksIndexRoute: InternalITasksTasksIndexRoute,
}

const InternalITasksRouteWithChildren = InternalITasksRoute._addFileChildren(
  InternalITasksRouteChildren,
)

interface InternalICustomersCustomerIdCustomerRouteChildren {
  InternalICustomersCustomerIdCustomerContactsRoute: typeof InternalICustomersCustomerIdCustomerContactsRoute
  InternalICustomersCustomerIdCustomerGeneralRoute: typeof InternalICustomersCustomerIdCustomerGeneralRoute
  InternalICustomersCustomerIdCustomerUpsellingRoute: typeof InternalICustomersCustomerIdCustomerUpsellingRoute
}

const InternalICustomersCustomerIdCustomerRouteChildren: InternalICustomersCustomerIdCustomerRouteChildren =
  {
    InternalICustomersCustomerIdCustomerContactsRoute:
      InternalICustomersCustomerIdCustomerContactsRoute,
    InternalICustomersCustomerIdCustomerGeneralRoute:
      InternalICustomersCustomerIdCustomerGeneralRoute,
    InternalICustomersCustomerIdCustomerUpsellingRoute:
      InternalICustomersCustomerIdCustomerUpsellingRoute,
  }

const InternalICustomersCustomerIdCustomerRouteWithChildren =
  InternalICustomersCustomerIdCustomerRoute._addFileChildren(
    InternalICustomersCustomerIdCustomerRouteChildren,
  )

interface InternalICustomersCustomerIdRouteChildren {
  InternalICustomersCustomerIdCustomerRoute: typeof InternalICustomersCustomerIdCustomerRouteWithChildren
}

const InternalICustomersCustomerIdRouteChildren: InternalICustomersCustomerIdRouteChildren =
  {
    InternalICustomersCustomerIdCustomerRoute:
      InternalICustomersCustomerIdCustomerRouteWithChildren,
  }

const InternalICustomersCustomerIdRouteWithChildren =
  InternalICustomersCustomerIdRoute._addFileChildren(
    InternalICustomersCustomerIdRouteChildren,
  )

interface InternalIPentestsProjectIdPentestRouteChildren {
  InternalIPentestsProjectIdPentestContactsRoute: typeof InternalIPentestsProjectIdPentestContactsRoute
  InternalIPentestsProjectIdPentestFilesRoute: typeof InternalIPentestsProjectIdPentestFilesRoute
  InternalIPentestsProjectIdPentestNotesRoute: typeof InternalIPentestsProjectIdPentestNotesRoute
  InternalIPentestsProjectIdPentestGeneralEditTaRoute: typeof InternalIPentestsProjectIdPentestGeneralEditTaRoute
  InternalIPentestsProjectIdPentestScheduleChangeDebriefDateRoute: typeof InternalIPentestsProjectIdPentestScheduleChangeDebriefDateRoute
  InternalIPentestsProjectIdPentestScheduleChangeDialogDateRoute: typeof InternalIPentestsProjectIdPentestScheduleChangeDialogDateRoute
  InternalIPentestsProjectIdPentestScheduleChangeKickoffDateRoute: typeof InternalIPentestsProjectIdPentestScheduleChangeKickoffDateRoute
  InternalIPentestsProjectIdPentestScheduleChangeMgmtDateRoute: typeof InternalIPentestsProjectIdPentestScheduleChangeMgmtDateRoute
  InternalIPentestsProjectIdPentestGeneralIndexRoute: typeof InternalIPentestsProjectIdPentestGeneralIndexRoute
  InternalIPentestsProjectIdPentestScheduleIndexRoute: typeof InternalIPentestsProjectIdPentestScheduleIndexRoute
}

const InternalIPentestsProjectIdPentestRouteChildren: InternalIPentestsProjectIdPentestRouteChildren =
  {
    InternalIPentestsProjectIdPentestContactsRoute:
      InternalIPentestsProjectIdPentestContactsRoute,
    InternalIPentestsProjectIdPentestFilesRoute:
      InternalIPentestsProjectIdPentestFilesRoute,
    InternalIPentestsProjectIdPentestNotesRoute:
      InternalIPentestsProjectIdPentestNotesRoute,
    InternalIPentestsProjectIdPentestGeneralEditTaRoute:
      InternalIPentestsProjectIdPentestGeneralEditTaRoute,
    InternalIPentestsProjectIdPentestScheduleChangeDebriefDateRoute:
      InternalIPentestsProjectIdPentestScheduleChangeDebriefDateRoute,
    InternalIPentestsProjectIdPentestScheduleChangeDialogDateRoute:
      InternalIPentestsProjectIdPentestScheduleChangeDialogDateRoute,
    InternalIPentestsProjectIdPentestScheduleChangeKickoffDateRoute:
      InternalIPentestsProjectIdPentestScheduleChangeKickoffDateRoute,
    InternalIPentestsProjectIdPentestScheduleChangeMgmtDateRoute:
      InternalIPentestsProjectIdPentestScheduleChangeMgmtDateRoute,
    InternalIPentestsProjectIdPentestGeneralIndexRoute:
      InternalIPentestsProjectIdPentestGeneralIndexRoute,
    InternalIPentestsProjectIdPentestScheduleIndexRoute:
      InternalIPentestsProjectIdPentestScheduleIndexRoute,
  }

const InternalIPentestsProjectIdPentestRouteWithChildren =
  InternalIPentestsProjectIdPentestRoute._addFileChildren(
    InternalIPentestsProjectIdPentestRouteChildren,
  )

interface InternalIPentestsProjectIdRouteChildren {
  InternalIPentestsProjectIdPentestRoute: typeof InternalIPentestsProjectIdPentestRouteWithChildren
  InternalIPentestsProjectIdTargetsRoute: typeof InternalIPentestsProjectIdTargetsRoute
}

const InternalIPentestsProjectIdRouteChildren: InternalIPentestsProjectIdRouteChildren =
  {
    InternalIPentestsProjectIdPentestRoute:
      InternalIPentestsProjectIdPentestRouteWithChildren,
    InternalIPentestsProjectIdTargetsRoute:
      InternalIPentestsProjectIdTargetsRoute,
  }

const InternalIPentestsProjectIdRouteWithChildren =
  InternalIPentestsProjectIdRoute._addFileChildren(
    InternalIPentestsProjectIdRouteChildren,
  )

interface InternalIRouteChildren {
  InternalIPmViewRoute: typeof InternalIPmViewRouteWithChildren
  InternalIProfileRoute: typeof InternalIProfileRouteWithChildren
  InternalITasksRoute: typeof InternalITasksRouteWithChildren
  InternalIDashboardRoute: typeof InternalIDashboardRoute
  InternalIRunExampleScriptRoute: typeof InternalIRunExampleScriptRoute
  InternalIProjectsCreateRoute: typeof InternalIProjectsCreateRoute
  InternalIUpsellingOfferSuggestionsRoute: typeof InternalIUpsellingOfferSuggestionsRoute
  InternalICustomersIndexRoute: typeof InternalICustomersIndexRoute
  InternalICustomersCustomerIdRoute: typeof InternalICustomersCustomerIdRouteWithChildren
  InternalIPentestsProjectIdRoute: typeof InternalIPentestsProjectIdRouteWithChildren
  InternalIPhishingScriptsCreateCampaignRoute: typeof InternalIPhishingScriptsCreateCampaignRoute
  InternalIPhishingScriptsGenerateAttackStatusRoute: typeof InternalIPhishingScriptsGenerateAttackStatusRoute
  InternalIPhishingScriptsGeneratePresentationRoute: typeof InternalIPhishingScriptsGeneratePresentationRoute
  InternalIPhishingScriptsGenerateRawCsvRoute: typeof InternalIPhishingScriptsGenerateRawCsvRoute
  InternalIPhishingScriptsGenerateTrainingStatusRoute: typeof InternalIPhishingScriptsGenerateTrainingStatusRoute
  InternalIQuestionnairesGroupsGroupIdRoute: typeof InternalIQuestionnairesGroupsGroupIdRoute
  InternalIQuestionnairesGroupsCreateRoute: typeof InternalIQuestionnairesGroupsCreateRoute
  InternalIQuestionnairesQuestionsQuestionIdRoute: typeof InternalIQuestionnairesQuestionsQuestionIdRoute
  InternalIQuestionnairesQuestionsCreateRoute: typeof InternalIQuestionnairesQuestionsCreateRoute
  InternalIQuestionnairesTemplatesQuestionnaireIdRoute: typeof InternalIQuestionnairesTemplatesQuestionnaireIdRoute
  InternalIQuestionnairesTemplatesCreateRoute: typeof InternalIQuestionnairesTemplatesCreateRoute
  InternalIPhishingScriptsGenerateIrPresentationLazyRoute: typeof InternalIPhishingScriptsGenerateIrPresentationLazyRoute
  InternalIPhishingScriptsIndexRoute: typeof InternalIPhishingScriptsIndexRoute
  InternalIQuestionnairesGroupsIndexRoute: typeof InternalIQuestionnairesGroupsIndexRoute
  InternalIQuestionnairesQuestionsIndexRoute: typeof InternalIQuestionnairesQuestionsIndexRoute
  InternalIQuestionnairesTemplatesIndexRoute: typeof InternalIQuestionnairesTemplatesIndexRoute
  InternalIFormsTasksTaskIdAddHttpFindingsV0Route: typeof InternalIFormsTasksTaskIdAddHttpFindingsV0Route
  InternalIFormsTasksTaskIdConfirmFoundV0Route: typeof InternalIFormsTasksTaskIdConfirmFoundV0Route
  InternalIFormsTasksTaskIdCreateServicesV0Route: typeof InternalIFormsTasksTaskIdCreateServicesV0Route
  InternalIFormsTasksTaskIdEnterDebriefResultsV0Route: typeof InternalIFormsTasksTaskIdEnterDebriefResultsV0Route
  InternalIFormsTasksTaskIdEnterDialogResultsV0Route: typeof InternalIFormsTasksTaskIdEnterDialogResultsV0Route
  InternalIFormsTasksTaskIdEnterDialogResultsV1Route: typeof InternalIFormsTasksTaskIdEnterDialogResultsV1Route
  InternalIFormsTasksTaskIdEnterKickoffResultsV0Route: typeof InternalIFormsTasksTaskIdEnterKickoffResultsV0Route
  InternalIFormsTasksTaskIdEnterKickoffResultsV1Route: typeof InternalIFormsTasksTaskIdEnterKickoffResultsV1Route
  InternalIFormsTasksTaskIdEnterMgmtResultsV0Route: typeof InternalIFormsTasksTaskIdEnterMgmtResultsV0Route
  InternalIFormsTasksTaskIdEnterMgmtResultsV1Route: typeof InternalIFormsTasksTaskIdEnterMgmtResultsV1Route
  InternalIFormsTasksTaskIdImportDataV0Route: typeof InternalIFormsTasksTaskIdImportDataV0Route
  InternalIFormsTasksTaskIdInviteCustomerUserV0Route: typeof InternalIFormsTasksTaskIdInviteCustomerUserV0Route
  InternalIFormsTasksTaskIdInviteDebriefV0Route: typeof InternalIFormsTasksTaskIdInviteDebriefV0Route
  InternalIFormsTasksTaskIdInviteDialogV0Route: typeof InternalIFormsTasksTaskIdInviteDialogV0Route
  InternalIFormsTasksTaskIdInviteMgmtPresentationV0Route: typeof InternalIFormsTasksTaskIdInviteMgmtPresentationV0Route
  InternalIFormsTasksTaskIdRunKrakenAssiV0Route: typeof InternalIFormsTasksTaskIdRunKrakenAssiV0Route
  InternalIFormsTasksTaskIdScanShodanV0Route: typeof InternalIFormsTasksTaskIdScanShodanV0Route
  InternalIFormsTasksTaskIdScheduleDebriefV0Route: typeof InternalIFormsTasksTaskIdScheduleDebriefV0Route
  InternalIFormsTasksTaskIdScheduleDialogV0Route: typeof InternalIFormsTasksTaskIdScheduleDialogV0Route
  InternalIFormsTasksTaskIdScheduleKickoffV0Route: typeof InternalIFormsTasksTaskIdScheduleKickoffV0Route
  InternalIFormsTasksTaskIdScheduleKickoffV1Route: typeof InternalIFormsTasksTaskIdScheduleKickoffV1Route
  InternalIFormsTasksTaskIdScheduleMgmtPresentationV0Route: typeof InternalIFormsTasksTaskIdScheduleMgmtPresentationV0Route
  InternalIFormsTasksTaskIdStartInitialScanV0Route: typeof InternalIFormsTasksTaskIdStartInitialScanV0Route
  InternalIFormsTasksTaskIdTestCommonV0Route: typeof InternalIFormsTasksTaskIdTestCommonV0Route
  InternalIFormsTasksTaskIdTestExoticV0Route: typeof InternalIFormsTasksTaskIdTestExoticV0Route
  InternalIFormsTasksTaskIdTestSslV0Route: typeof InternalIFormsTasksTaskIdTestSslV0Route
  InternalIFormsTasksTaskIdUploadDialogPresentationRoute: typeof InternalIFormsTasksTaskIdUploadDialogPresentationRoute
  InternalIFormsTasksTaskIdUploadKickoffPresentationRoute: typeof InternalIFormsTasksTaskIdUploadKickoffPresentationRoute
  InternalIFormsTasksTaskIdUploadMgmtPresentationRoute: typeof InternalIFormsTasksTaskIdUploadMgmtPresentationRoute
  InternalIFormsTasksTaskIdUploadPentestReportRoute: typeof InternalIFormsTasksTaskIdUploadPentestReportRoute
}

const InternalIRouteChildren: InternalIRouteChildren = {
  InternalIPmViewRoute: InternalIPmViewRouteWithChildren,
  InternalIProfileRoute: InternalIProfileRouteWithChildren,
  InternalITasksRoute: InternalITasksRouteWithChildren,
  InternalIDashboardRoute: InternalIDashboardRoute,
  InternalIRunExampleScriptRoute: InternalIRunExampleScriptRoute,
  InternalIProjectsCreateRoute: InternalIProjectsCreateRoute,
  InternalIUpsellingOfferSuggestionsRoute:
    InternalIUpsellingOfferSuggestionsRoute,
  InternalICustomersIndexRoute: InternalICustomersIndexRoute,
  InternalICustomersCustomerIdRoute:
    InternalICustomersCustomerIdRouteWithChildren,
  InternalIPentestsProjectIdRoute: InternalIPentestsProjectIdRouteWithChildren,
  InternalIPhishingScriptsCreateCampaignRoute:
    InternalIPhishingScriptsCreateCampaignRoute,
  InternalIPhishingScriptsGenerateAttackStatusRoute:
    InternalIPhishingScriptsGenerateAttackStatusRoute,
  InternalIPhishingScriptsGeneratePresentationRoute:
    InternalIPhishingScriptsGeneratePresentationRoute,
  InternalIPhishingScriptsGenerateRawCsvRoute:
    InternalIPhishingScriptsGenerateRawCsvRoute,
  InternalIPhishingScriptsGenerateTrainingStatusRoute:
    InternalIPhishingScriptsGenerateTrainingStatusRoute,
  InternalIQuestionnairesGroupsGroupIdRoute:
    InternalIQuestionnairesGroupsGroupIdRoute,
  InternalIQuestionnairesGroupsCreateRoute:
    InternalIQuestionnairesGroupsCreateRoute,
  InternalIQuestionnairesQuestionsQuestionIdRoute:
    InternalIQuestionnairesQuestionsQuestionIdRoute,
  InternalIQuestionnairesQuestionsCreateRoute:
    InternalIQuestionnairesQuestionsCreateRoute,
  InternalIQuestionnairesTemplatesQuestionnaireIdRoute:
    InternalIQuestionnairesTemplatesQuestionnaireIdRoute,
  InternalIQuestionnairesTemplatesCreateRoute:
    InternalIQuestionnairesTemplatesCreateRoute,
  InternalIPhishingScriptsGenerateIrPresentationLazyRoute:
    InternalIPhishingScriptsGenerateIrPresentationLazyRoute,
  InternalIPhishingScriptsIndexRoute: InternalIPhishingScriptsIndexRoute,
  InternalIQuestionnairesGroupsIndexRoute:
    InternalIQuestionnairesGroupsIndexRoute,
  InternalIQuestionnairesQuestionsIndexRoute:
    InternalIQuestionnairesQuestionsIndexRoute,
  InternalIQuestionnairesTemplatesIndexRoute:
    InternalIQuestionnairesTemplatesIndexRoute,
  InternalIFormsTasksTaskIdAddHttpFindingsV0Route:
    InternalIFormsTasksTaskIdAddHttpFindingsV0Route,
  InternalIFormsTasksTaskIdConfirmFoundV0Route:
    InternalIFormsTasksTaskIdConfirmFoundV0Route,
  InternalIFormsTasksTaskIdCreateServicesV0Route:
    InternalIFormsTasksTaskIdCreateServicesV0Route,
  InternalIFormsTasksTaskIdEnterDebriefResultsV0Route:
    InternalIFormsTasksTaskIdEnterDebriefResultsV0Route,
  InternalIFormsTasksTaskIdEnterDialogResultsV0Route:
    InternalIFormsTasksTaskIdEnterDialogResultsV0Route,
  InternalIFormsTasksTaskIdEnterDialogResultsV1Route:
    InternalIFormsTasksTaskIdEnterDialogResultsV1Route,
  InternalIFormsTasksTaskIdEnterKickoffResultsV0Route:
    InternalIFormsTasksTaskIdEnterKickoffResultsV0Route,
  InternalIFormsTasksTaskIdEnterKickoffResultsV1Route:
    InternalIFormsTasksTaskIdEnterKickoffResultsV1Route,
  InternalIFormsTasksTaskIdEnterMgmtResultsV0Route:
    InternalIFormsTasksTaskIdEnterMgmtResultsV0Route,
  InternalIFormsTasksTaskIdEnterMgmtResultsV1Route:
    InternalIFormsTasksTaskIdEnterMgmtResultsV1Route,
  InternalIFormsTasksTaskIdImportDataV0Route:
    InternalIFormsTasksTaskIdImportDataV0Route,
  InternalIFormsTasksTaskIdInviteCustomerUserV0Route:
    InternalIFormsTasksTaskIdInviteCustomerUserV0Route,
  InternalIFormsTasksTaskIdInviteDebriefV0Route:
    InternalIFormsTasksTaskIdInviteDebriefV0Route,
  InternalIFormsTasksTaskIdInviteDialogV0Route:
    InternalIFormsTasksTaskIdInviteDialogV0Route,
  InternalIFormsTasksTaskIdInviteMgmtPresentationV0Route:
    InternalIFormsTasksTaskIdInviteMgmtPresentationV0Route,
  InternalIFormsTasksTaskIdRunKrakenAssiV0Route:
    InternalIFormsTasksTaskIdRunKrakenAssiV0Route,
  InternalIFormsTasksTaskIdScanShodanV0Route:
    InternalIFormsTasksTaskIdScanShodanV0Route,
  InternalIFormsTasksTaskIdScheduleDebriefV0Route:
    InternalIFormsTasksTaskIdScheduleDebriefV0Route,
  InternalIFormsTasksTaskIdScheduleDialogV0Route:
    InternalIFormsTasksTaskIdScheduleDialogV0Route,
  InternalIFormsTasksTaskIdScheduleKickoffV0Route:
    InternalIFormsTasksTaskIdScheduleKickoffV0Route,
  InternalIFormsTasksTaskIdScheduleKickoffV1Route:
    InternalIFormsTasksTaskIdScheduleKickoffV1Route,
  InternalIFormsTasksTaskIdScheduleMgmtPresentationV0Route:
    InternalIFormsTasksTaskIdScheduleMgmtPresentationV0Route,
  InternalIFormsTasksTaskIdStartInitialScanV0Route:
    InternalIFormsTasksTaskIdStartInitialScanV0Route,
  InternalIFormsTasksTaskIdTestCommonV0Route:
    InternalIFormsTasksTaskIdTestCommonV0Route,
  InternalIFormsTasksTaskIdTestExoticV0Route:
    InternalIFormsTasksTaskIdTestExoticV0Route,
  InternalIFormsTasksTaskIdTestSslV0Route:
    InternalIFormsTasksTaskIdTestSslV0Route,
  InternalIFormsTasksTaskIdUploadDialogPresentationRoute:
    InternalIFormsTasksTaskIdUploadDialogPresentationRoute,
  InternalIFormsTasksTaskIdUploadKickoffPresentationRoute:
    InternalIFormsTasksTaskIdUploadKickoffPresentationRoute,
  InternalIFormsTasksTaskIdUploadMgmtPresentationRoute:
    InternalIFormsTasksTaskIdUploadMgmtPresentationRoute,
  InternalIFormsTasksTaskIdUploadPentestReportRoute:
    InternalIFormsTasksTaskIdUploadPentestReportRoute,
}

const InternalIRouteWithChildren = InternalIRoute._addFileChildren(
  InternalIRouteChildren,
)

interface InternalLazyRouteChildren {
  InternalIRoute: typeof InternalIRouteWithChildren
}

const InternalLazyRouteChildren: InternalLazyRouteChildren = {
  InternalIRoute: InternalIRouteWithChildren,
}

const InternalLazyRouteWithChildren = InternalLazyRoute._addFileChildren(
  InternalLazyRouteChildren,
)

interface LinksLinksCRouteChildren {
  LinksLinksCCPentestsProjectIdRoute: typeof LinksLinksCCPentestsProjectIdRoute
  LinksLinksCCTasksTaskIdRoute: typeof LinksLinksCCTasksTaskIdRoute
}

const LinksLinksCRouteChildren: LinksLinksCRouteChildren = {
  LinksLinksCCPentestsProjectIdRoute: LinksLinksCCPentestsProjectIdRoute,
  LinksLinksCCTasksTaskIdRoute: LinksLinksCCTasksTaskIdRoute,
}

const LinksLinksCRouteWithChildren = LinksLinksCRoute._addFileChildren(
  LinksLinksCRouteChildren,
)

interface LinksLinksIRouteChildren {
  LinksLinksIICustomersCustomerIdRoute: typeof LinksLinksIICustomersCustomerIdRoute
  LinksLinksIITasksIndexRoute: typeof LinksLinksIITasksIndexRoute
  LinksLinksIIPentestsProjectIdTargetsRoute: typeof LinksLinksIIPentestsProjectIdTargetsRoute
  LinksLinksIIPentestsProjectIdIndexRoute: typeof LinksLinksIIPentestsProjectIdIndexRoute
  LinksLinksIIPentestsProjectIdQuestionnairesQuestionnaireIdRoute: typeof LinksLinksIIPentestsProjectIdQuestionnairesQuestionnaireIdRoute
}

const LinksLinksIRouteChildren: LinksLinksIRouteChildren = {
  LinksLinksIICustomersCustomerIdRoute: LinksLinksIICustomersCustomerIdRoute,
  LinksLinksIITasksIndexRoute: LinksLinksIITasksIndexRoute,
  LinksLinksIIPentestsProjectIdTargetsRoute:
    LinksLinksIIPentestsProjectIdTargetsRoute,
  LinksLinksIIPentestsProjectIdIndexRoute:
    LinksLinksIIPentestsProjectIdIndexRoute,
  LinksLinksIIPentestsProjectIdQuestionnairesQuestionnaireIdRoute:
    LinksLinksIIPentestsProjectIdQuestionnairesQuestionnaireIdRoute,
}

const LinksLinksIRouteWithChildren = LinksLinksIRoute._addFileChildren(
  LinksLinksIRouteChildren,
)

interface LinksLinksRouteChildren {
  LinksLinksCRoute: typeof LinksLinksCRouteWithChildren
  LinksLinksIRoute: typeof LinksLinksIRouteWithChildren
}

const LinksLinksRouteChildren: LinksLinksRouteChildren = {
  LinksLinksCRoute: LinksLinksCRouteWithChildren,
  LinksLinksIRoute: LinksLinksIRouteWithChildren,
}

const LinksLinksRouteWithChildren = LinksLinksRoute._addFileChildren(
  LinksLinksRouteChildren,
)

interface LinksLazyRouteChildren {
  LinksLinksRoute: typeof LinksLinksRouteWithChildren
}

const LinksLazyRouteChildren: LinksLazyRouteChildren = {
  LinksLinksRoute: LinksLinksRouteWithChildren,
}

const LinksLazyRouteWithChildren = LinksLazyRoute._addFileChildren(
  LinksLazyRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof LinksLazyRouteWithChildren
  '/invite/$inviteId': typeof InviteInviteIdLazyRoute
  '/reset/$resetId': typeof ResetResetIdLazyRoute
  '/a': typeof AdminAProfileRouteWithChildren
  '/a/dashboard': typeof AdminADashboardRoute
  '/a/user': typeof AdminAUserRoute
  '/c': typeof CustomerCProfileRouteWithChildren
  '/c/dashboard': typeof CustomerCDashboardRoute
  '/c/tasks': typeof CustomerCTasksRoute
  '/i': typeof InternalITasksRouteWithChildren
  '/i/dashboard': typeof InternalIDashboardRoute
  '/i/run-example-script': typeof InternalIRunExampleScriptRoute
  '/links': typeof LinksLinksIRouteWithChildren
  '/a/settings/karla-kolumna': typeof AdminASettingsKarlaKolumnaRoute
  '/a/settings/mail': typeof AdminASettingsMailRoute
  '/a/settings/matrix': typeof AdminASettingsMatrixRoute
  '/i/projects/create': typeof InternalIProjectsCreateRoute
  '/i/upselling/offer-suggestions': typeof InternalIUpsellingOfferSuggestionsRoute
  '/i/customers': typeof InternalICustomersIndexRoute
  '/a/profile/general': typeof AdminAProfileProfileGeneralRoute
  '/a/profile/security': typeof AdminAProfileProfileSecurityRoute
  '/c/profile/general': typeof CustomerCProfileProfileGeneralRoute
  '/c/profile/security': typeof CustomerCProfileProfileSecurityRoute
  '/i/pm-view/overdue-tasks': typeof InternalIPmViewPmViewOverdueTasksRoute
  '/i/profile/general': typeof InternalIProfileProfileGeneralRoute
  '/i/profile/security': typeof InternalIProfileProfileSecurityRoute
  '/i/tasks/my': typeof InternalITasksTasksMyRoute
  '/i/tasks/pool': typeof InternalITasksTasksPoolRoute
  '/i/customers/$customerId': typeof InternalICustomersCustomerIdCustomerRouteWithChildren
  '/i/pentests/$projectId': typeof InternalIPentestsProjectIdPentestRouteWithChildren
  '/i/pentests/$projectId/targets': typeof InternalIPentestsProjectIdTargetsRoute
  '/i/phishing/scripts/create-campaign': typeof InternalIPhishingScriptsCreateCampaignRoute
  '/i/phishing/scripts/generate-attack-status': typeof InternalIPhishingScriptsGenerateAttackStatusRoute
  '/i/phishing/scripts/generate-presentation': typeof InternalIPhishingScriptsGeneratePresentationRoute
  '/i/phishing/scripts/generate-raw-csv': typeof InternalIPhishingScriptsGenerateRawCsvRoute
  '/i/phishing/scripts/generate-training-status': typeof InternalIPhishingScriptsGenerateTrainingStatusRoute
  '/i/questionnaires/groups/$groupId': typeof InternalIQuestionnairesGroupsGroupIdRoute
  '/i/questionnaires/groups/create': typeof InternalIQuestionnairesGroupsCreateRoute
  '/i/questionnaires/questions/$questionId': typeof InternalIQuestionnairesQuestionsQuestionIdRoute
  '/i/questionnaires/questions/create': typeof InternalIQuestionnairesQuestionsCreateRoute
  '/i/questionnaires/templates/$questionnaireId': typeof InternalIQuestionnairesTemplatesQuestionnaireIdRoute
  '/i/questionnaires/templates/create': typeof InternalIQuestionnairesTemplatesCreateRoute
  '/i/phishing/scripts/generate-ir-presentation': typeof InternalIPhishingScriptsGenerateIrPresentationLazyRoute
  '/i/pm-view': typeof InternalIPmViewPmViewIndexRoute
  '/i/tasks': typeof InternalITasksTasksIndexRoute
  '/i/phishing/scripts': typeof InternalIPhishingScriptsIndexRoute
  '/i/questionnaires/groups': typeof InternalIQuestionnairesGroupsIndexRoute
  '/i/questionnaires/questions': typeof InternalIQuestionnairesQuestionsIndexRoute
  '/i/questionnaires/templates': typeof InternalIQuestionnairesTemplatesIndexRoute
  '/c/forms/tasks/$taskId/assign-project-contacts': typeof CustomerCFormsTasksTaskIdAssignProjectContactsRoute
  '/c/forms/tasks/$taskId/questionnaire': typeof CustomerCFormsTasksTaskIdQuestionnaireRoute
  '/c/forms/tasks/$taskId/target': typeof CustomerCFormsTasksTaskIdTargetRoute
  '/c/projects/pentests/$projectId': typeof CustomerCProjectsPentestsProjectIdPentestRouteWithChildren
  '/i/customers/$customerId/contacts': typeof InternalICustomersCustomerIdCustomerContactsRoute
  '/i/customers/$customerId/general': typeof InternalICustomersCustomerIdCustomerGeneralRoute
  '/i/customers/$customerId/upselling': typeof InternalICustomersCustomerIdCustomerUpsellingRoute
  '/i/forms/tasks/$taskId/add-http-findings-v0': typeof InternalIFormsTasksTaskIdAddHttpFindingsV0Route
  '/i/forms/tasks/$taskId/confirm-found-v0': typeof InternalIFormsTasksTaskIdConfirmFoundV0Route
  '/i/forms/tasks/$taskId/create-services-v0': typeof InternalIFormsTasksTaskIdCreateServicesV0Route
  '/i/forms/tasks/$taskId/enter-debrief-results-v0': typeof InternalIFormsTasksTaskIdEnterDebriefResultsV0Route
  '/i/forms/tasks/$taskId/enter-dialog-results-v0': typeof InternalIFormsTasksTaskIdEnterDialogResultsV0Route
  '/i/forms/tasks/$taskId/enter-dialog-results-v1': typeof InternalIFormsTasksTaskIdEnterDialogResultsV1Route
  '/i/forms/tasks/$taskId/enter-kickoff-results-v0': typeof InternalIFormsTasksTaskIdEnterKickoffResultsV0Route
  '/i/forms/tasks/$taskId/enter-kickoff-results-v1': typeof InternalIFormsTasksTaskIdEnterKickoffResultsV1Route
  '/i/forms/tasks/$taskId/enter-mgmt-results-v0': typeof InternalIFormsTasksTaskIdEnterMgmtResultsV0Route
  '/i/forms/tasks/$taskId/enter-mgmt-results-v1': typeof InternalIFormsTasksTaskIdEnterMgmtResultsV1Route
  '/i/forms/tasks/$taskId/import-data-v0': typeof InternalIFormsTasksTaskIdImportDataV0Route
  '/i/forms/tasks/$taskId/invite-customer-user-v0': typeof InternalIFormsTasksTaskIdInviteCustomerUserV0Route
  '/i/forms/tasks/$taskId/invite-debrief-v0': typeof InternalIFormsTasksTaskIdInviteDebriefV0Route
  '/i/forms/tasks/$taskId/invite-dialog-v0': typeof InternalIFormsTasksTaskIdInviteDialogV0Route
  '/i/forms/tasks/$taskId/invite-mgmt-presentation-v0': typeof InternalIFormsTasksTaskIdInviteMgmtPresentationV0Route
  '/i/forms/tasks/$taskId/run-kraken-assi-v0': typeof InternalIFormsTasksTaskIdRunKrakenAssiV0Route
  '/i/forms/tasks/$taskId/scan-shodan-v0': typeof InternalIFormsTasksTaskIdScanShodanV0Route
  '/i/forms/tasks/$taskId/schedule-debrief-v0': typeof InternalIFormsTasksTaskIdScheduleDebriefV0Route
  '/i/forms/tasks/$taskId/schedule-dialog-v0': typeof InternalIFormsTasksTaskIdScheduleDialogV0Route
  '/i/forms/tasks/$taskId/schedule-kickoff-v0': typeof InternalIFormsTasksTaskIdScheduleKickoffV0Route
  '/i/forms/tasks/$taskId/schedule-kickoff-v1': typeof InternalIFormsTasksTaskIdScheduleKickoffV1Route
  '/i/forms/tasks/$taskId/schedule-mgmt-presentation-v0': typeof InternalIFormsTasksTaskIdScheduleMgmtPresentationV0Route
  '/i/forms/tasks/$taskId/start-initial-scan-v0': typeof InternalIFormsTasksTaskIdStartInitialScanV0Route
  '/i/forms/tasks/$taskId/test-common-v0': typeof InternalIFormsTasksTaskIdTestCommonV0Route
  '/i/forms/tasks/$taskId/test-exotic-v0': typeof InternalIFormsTasksTaskIdTestExoticV0Route
  '/i/forms/tasks/$taskId/test-ssl-v0': typeof InternalIFormsTasksTaskIdTestSslV0Route
  '/i/forms/tasks/$taskId/upload-dialog-presentation': typeof InternalIFormsTasksTaskIdUploadDialogPresentationRoute
  '/i/forms/tasks/$taskId/upload-kickoff-presentation': typeof InternalIFormsTasksTaskIdUploadKickoffPresentationRoute
  '/i/forms/tasks/$taskId/upload-mgmt-presentation': typeof InternalIFormsTasksTaskIdUploadMgmtPresentationRoute
  '/i/forms/tasks/$taskId/upload-pentest-report': typeof InternalIFormsTasksTaskIdUploadPentestReportRoute
  '/i/pentests/$projectId/contacts': typeof InternalIPentestsProjectIdPentestContactsRoute
  '/i/pentests/$projectId/files': typeof InternalIPentestsProjectIdPentestFilesRoute
  '/i/pentests/$projectId/notes': typeof InternalIPentestsProjectIdPentestNotesRoute
  '/links/c/pentests/$projectId': typeof LinksLinksCCPentestsProjectIdRoute
  '/links/c/tasks/$taskId': typeof LinksLinksCCTasksTaskIdRoute
  '/links/i/customers/$customerId': typeof LinksLinksIICustomersCustomerIdRoute
  '/links/i/tasks': typeof LinksLinksIITasksIndexRoute
  '/c/projects/pentests/$projectId/contacts': typeof CustomerCProjectsPentestsProjectIdPentestContactsRoute
  '/c/projects/pentests/$projectId/files': typeof CustomerCProjectsPentestsProjectIdPentestFilesRoute
  '/c/projects/pentests/$projectId/general': typeof CustomerCProjectsPentestsProjectIdPentestGeneralRoute
  '/c/projects/pentests/$projectId/schedule': typeof CustomerCProjectsPentestsProjectIdPentestScheduleRoute
  '/i/pentests/$projectId/general/edit-ta': typeof InternalIPentestsProjectIdPentestGeneralEditTaRoute
  '/i/pentests/$projectId/schedule/change-debrief-date': typeof InternalIPentestsProjectIdPentestScheduleChangeDebriefDateRoute
  '/i/pentests/$projectId/schedule/change-dialog-date': typeof InternalIPentestsProjectIdPentestScheduleChangeDialogDateRoute
  '/i/pentests/$projectId/schedule/change-kickoff-date': typeof InternalIPentestsProjectIdPentestScheduleChangeKickoffDateRoute
  '/i/pentests/$projectId/schedule/change-mgmt-date': typeof InternalIPentestsProjectIdPentestScheduleChangeMgmtDateRoute
  '/links/i/pentests/$projectId/targets': typeof LinksLinksIIPentestsProjectIdTargetsRoute
  '/i/pentests/$projectId/general': typeof InternalIPentestsProjectIdPentestGeneralIndexRoute
  '/i/pentests/$projectId/schedule': typeof InternalIPentestsProjectIdPentestScheduleIndexRoute
  '/links/i/pentests/$projectId': typeof LinksLinksIIPentestsProjectIdIndexRoute
  '/links/i/pentests/$projectId/questionnaires/$questionnaireId': typeof LinksLinksIIPentestsProjectIdQuestionnairesQuestionnaireIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof LinksLazyRouteWithChildren
  '/invite/$inviteId': typeof InviteInviteIdLazyRoute
  '/reset/$resetId': typeof ResetResetIdLazyRoute
  '/a': typeof AdminAProfileRouteWithChildren
  '/a/dashboard': typeof AdminADashboardRoute
  '/a/user': typeof AdminAUserRoute
  '/c': typeof CustomerCProfileRouteWithChildren
  '/c/dashboard': typeof CustomerCDashboardRoute
  '/c/tasks': typeof CustomerCTasksRoute
  '/i': typeof InternalITasksRouteWithChildren
  '/i/dashboard': typeof InternalIDashboardRoute
  '/i/run-example-script': typeof InternalIRunExampleScriptRoute
  '/links': typeof LinksLinksIRouteWithChildren
  '/a/settings/karla-kolumna': typeof AdminASettingsKarlaKolumnaRoute
  '/a/settings/mail': typeof AdminASettingsMailRoute
  '/a/settings/matrix': typeof AdminASettingsMatrixRoute
  '/i/projects/create': typeof InternalIProjectsCreateRoute
  '/i/upselling/offer-suggestions': typeof InternalIUpsellingOfferSuggestionsRoute
  '/i/customers': typeof InternalICustomersIndexRoute
  '/a/profile/general': typeof AdminAProfileProfileGeneralRoute
  '/a/profile/security': typeof AdminAProfileProfileSecurityRoute
  '/c/profile/general': typeof CustomerCProfileProfileGeneralRoute
  '/c/profile/security': typeof CustomerCProfileProfileSecurityRoute
  '/i/pm-view/overdue-tasks': typeof InternalIPmViewPmViewOverdueTasksRoute
  '/i/profile/general': typeof InternalIProfileProfileGeneralRoute
  '/i/profile/security': typeof InternalIProfileProfileSecurityRoute
  '/i/tasks/my': typeof InternalITasksTasksMyRoute
  '/i/tasks/pool': typeof InternalITasksTasksPoolRoute
  '/i/customers/$customerId': typeof InternalICustomersCustomerIdCustomerRouteWithChildren
  '/i/pentests/$projectId': typeof InternalIPentestsProjectIdPentestRouteWithChildren
  '/i/pentests/$projectId/targets': typeof InternalIPentestsProjectIdTargetsRoute
  '/i/phishing/scripts/create-campaign': typeof InternalIPhishingScriptsCreateCampaignRoute
  '/i/phishing/scripts/generate-attack-status': typeof InternalIPhishingScriptsGenerateAttackStatusRoute
  '/i/phishing/scripts/generate-presentation': typeof InternalIPhishingScriptsGeneratePresentationRoute
  '/i/phishing/scripts/generate-raw-csv': typeof InternalIPhishingScriptsGenerateRawCsvRoute
  '/i/phishing/scripts/generate-training-status': typeof InternalIPhishingScriptsGenerateTrainingStatusRoute
  '/i/questionnaires/groups/$groupId': typeof InternalIQuestionnairesGroupsGroupIdRoute
  '/i/questionnaires/groups/create': typeof InternalIQuestionnairesGroupsCreateRoute
  '/i/questionnaires/questions/$questionId': typeof InternalIQuestionnairesQuestionsQuestionIdRoute
  '/i/questionnaires/questions/create': typeof InternalIQuestionnairesQuestionsCreateRoute
  '/i/questionnaires/templates/$questionnaireId': typeof InternalIQuestionnairesTemplatesQuestionnaireIdRoute
  '/i/questionnaires/templates/create': typeof InternalIQuestionnairesTemplatesCreateRoute
  '/i/phishing/scripts/generate-ir-presentation': typeof InternalIPhishingScriptsGenerateIrPresentationLazyRoute
  '/i/pm-view': typeof InternalIPmViewPmViewIndexRoute
  '/i/tasks': typeof InternalITasksTasksIndexRoute
  '/i/phishing/scripts': typeof InternalIPhishingScriptsIndexRoute
  '/i/questionnaires/groups': typeof InternalIQuestionnairesGroupsIndexRoute
  '/i/questionnaires/questions': typeof InternalIQuestionnairesQuestionsIndexRoute
  '/i/questionnaires/templates': typeof InternalIQuestionnairesTemplatesIndexRoute
  '/c/forms/tasks/$taskId/assign-project-contacts': typeof CustomerCFormsTasksTaskIdAssignProjectContactsRoute
  '/c/forms/tasks/$taskId/questionnaire': typeof CustomerCFormsTasksTaskIdQuestionnaireRoute
  '/c/forms/tasks/$taskId/target': typeof CustomerCFormsTasksTaskIdTargetRoute
  '/c/projects/pentests/$projectId': typeof CustomerCProjectsPentestsProjectIdPentestRouteWithChildren
  '/i/customers/$customerId/contacts': typeof InternalICustomersCustomerIdCustomerContactsRoute
  '/i/customers/$customerId/general': typeof InternalICustomersCustomerIdCustomerGeneralRoute
  '/i/customers/$customerId/upselling': typeof InternalICustomersCustomerIdCustomerUpsellingRoute
  '/i/forms/tasks/$taskId/add-http-findings-v0': typeof InternalIFormsTasksTaskIdAddHttpFindingsV0Route
  '/i/forms/tasks/$taskId/confirm-found-v0': typeof InternalIFormsTasksTaskIdConfirmFoundV0Route
  '/i/forms/tasks/$taskId/create-services-v0': typeof InternalIFormsTasksTaskIdCreateServicesV0Route
  '/i/forms/tasks/$taskId/enter-debrief-results-v0': typeof InternalIFormsTasksTaskIdEnterDebriefResultsV0Route
  '/i/forms/tasks/$taskId/enter-dialog-results-v0': typeof InternalIFormsTasksTaskIdEnterDialogResultsV0Route
  '/i/forms/tasks/$taskId/enter-dialog-results-v1': typeof InternalIFormsTasksTaskIdEnterDialogResultsV1Route
  '/i/forms/tasks/$taskId/enter-kickoff-results-v0': typeof InternalIFormsTasksTaskIdEnterKickoffResultsV0Route
  '/i/forms/tasks/$taskId/enter-kickoff-results-v1': typeof InternalIFormsTasksTaskIdEnterKickoffResultsV1Route
  '/i/forms/tasks/$taskId/enter-mgmt-results-v0': typeof InternalIFormsTasksTaskIdEnterMgmtResultsV0Route
  '/i/forms/tasks/$taskId/enter-mgmt-results-v1': typeof InternalIFormsTasksTaskIdEnterMgmtResultsV1Route
  '/i/forms/tasks/$taskId/import-data-v0': typeof InternalIFormsTasksTaskIdImportDataV0Route
  '/i/forms/tasks/$taskId/invite-customer-user-v0': typeof InternalIFormsTasksTaskIdInviteCustomerUserV0Route
  '/i/forms/tasks/$taskId/invite-debrief-v0': typeof InternalIFormsTasksTaskIdInviteDebriefV0Route
  '/i/forms/tasks/$taskId/invite-dialog-v0': typeof InternalIFormsTasksTaskIdInviteDialogV0Route
  '/i/forms/tasks/$taskId/invite-mgmt-presentation-v0': typeof InternalIFormsTasksTaskIdInviteMgmtPresentationV0Route
  '/i/forms/tasks/$taskId/run-kraken-assi-v0': typeof InternalIFormsTasksTaskIdRunKrakenAssiV0Route
  '/i/forms/tasks/$taskId/scan-shodan-v0': typeof InternalIFormsTasksTaskIdScanShodanV0Route
  '/i/forms/tasks/$taskId/schedule-debrief-v0': typeof InternalIFormsTasksTaskIdScheduleDebriefV0Route
  '/i/forms/tasks/$taskId/schedule-dialog-v0': typeof InternalIFormsTasksTaskIdScheduleDialogV0Route
  '/i/forms/tasks/$taskId/schedule-kickoff-v0': typeof InternalIFormsTasksTaskIdScheduleKickoffV0Route
  '/i/forms/tasks/$taskId/schedule-kickoff-v1': typeof InternalIFormsTasksTaskIdScheduleKickoffV1Route
  '/i/forms/tasks/$taskId/schedule-mgmt-presentation-v0': typeof InternalIFormsTasksTaskIdScheduleMgmtPresentationV0Route
  '/i/forms/tasks/$taskId/start-initial-scan-v0': typeof InternalIFormsTasksTaskIdStartInitialScanV0Route
  '/i/forms/tasks/$taskId/test-common-v0': typeof InternalIFormsTasksTaskIdTestCommonV0Route
  '/i/forms/tasks/$taskId/test-exotic-v0': typeof InternalIFormsTasksTaskIdTestExoticV0Route
  '/i/forms/tasks/$taskId/test-ssl-v0': typeof InternalIFormsTasksTaskIdTestSslV0Route
  '/i/forms/tasks/$taskId/upload-dialog-presentation': typeof InternalIFormsTasksTaskIdUploadDialogPresentationRoute
  '/i/forms/tasks/$taskId/upload-kickoff-presentation': typeof InternalIFormsTasksTaskIdUploadKickoffPresentationRoute
  '/i/forms/tasks/$taskId/upload-mgmt-presentation': typeof InternalIFormsTasksTaskIdUploadMgmtPresentationRoute
  '/i/forms/tasks/$taskId/upload-pentest-report': typeof InternalIFormsTasksTaskIdUploadPentestReportRoute
  '/i/pentests/$projectId/contacts': typeof InternalIPentestsProjectIdPentestContactsRoute
  '/i/pentests/$projectId/files': typeof InternalIPentestsProjectIdPentestFilesRoute
  '/i/pentests/$projectId/notes': typeof InternalIPentestsProjectIdPentestNotesRoute
  '/links/c/pentests/$projectId': typeof LinksLinksCCPentestsProjectIdRoute
  '/links/c/tasks/$taskId': typeof LinksLinksCCTasksTaskIdRoute
  '/links/i/customers/$customerId': typeof LinksLinksIICustomersCustomerIdRoute
  '/links/i/tasks': typeof LinksLinksIITasksIndexRoute
  '/c/projects/pentests/$projectId/contacts': typeof CustomerCProjectsPentestsProjectIdPentestContactsRoute
  '/c/projects/pentests/$projectId/files': typeof CustomerCProjectsPentestsProjectIdPentestFilesRoute
  '/c/projects/pentests/$projectId/general': typeof CustomerCProjectsPentestsProjectIdPentestGeneralRoute
  '/c/projects/pentests/$projectId/schedule': typeof CustomerCProjectsPentestsProjectIdPentestScheduleRoute
  '/i/pentests/$projectId/general/edit-ta': typeof InternalIPentestsProjectIdPentestGeneralEditTaRoute
  '/i/pentests/$projectId/schedule/change-debrief-date': typeof InternalIPentestsProjectIdPentestScheduleChangeDebriefDateRoute
  '/i/pentests/$projectId/schedule/change-dialog-date': typeof InternalIPentestsProjectIdPentestScheduleChangeDialogDateRoute
  '/i/pentests/$projectId/schedule/change-kickoff-date': typeof InternalIPentestsProjectIdPentestScheduleChangeKickoffDateRoute
  '/i/pentests/$projectId/schedule/change-mgmt-date': typeof InternalIPentestsProjectIdPentestScheduleChangeMgmtDateRoute
  '/links/i/pentests/$projectId/targets': typeof LinksLinksIIPentestsProjectIdTargetsRoute
  '/i/pentests/$projectId/general': typeof InternalIPentestsProjectIdPentestGeneralIndexRoute
  '/i/pentests/$projectId/schedule': typeof InternalIPentestsProjectIdPentestScheduleIndexRoute
  '/links/i/pentests/$projectId': typeof LinksLinksIIPentestsProjectIdIndexRoute
  '/links/i/pentests/$projectId/questionnaires/$questionnaireId': typeof LinksLinksIIPentestsProjectIdQuestionnairesQuestionnaireIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_admin': typeof AdminLazyRouteWithChildren
  '/_customer': typeof CustomerLazyRouteWithChildren
  '/_internal': typeof InternalLazyRouteWithChildren
  '/_links': typeof LinksLazyRouteWithChildren
  '/invite/$inviteId': typeof InviteInviteIdLazyRoute
  '/reset/$resetId': typeof ResetResetIdLazyRoute
  '/_admin/a': typeof AdminARouteWithChildren
  '/_admin/a/_profile': typeof AdminAProfileRouteWithChildren
  '/_admin/a/dashboard': typeof AdminADashboardRoute
  '/_admin/a/user': typeof AdminAUserRoute
  '/_customer/c': typeof CustomerCRouteWithChildren
  '/_customer/c/_profile': typeof CustomerCProfileRouteWithChildren
  '/_customer/c/dashboard': typeof CustomerCDashboardRoute
  '/_customer/c/tasks': typeof CustomerCTasksRoute
  '/_internal/i': typeof InternalIRouteWithChildren
  '/_internal/i/_pm-view': typeof InternalIPmViewRouteWithChildren
  '/_internal/i/_profile': typeof InternalIProfileRouteWithChildren
  '/_internal/i/_tasks': typeof InternalITasksRouteWithChildren
  '/_internal/i/dashboard': typeof InternalIDashboardRoute
  '/_internal/i/run-example-script': typeof InternalIRunExampleScriptRoute
  '/_links/links': typeof LinksLinksRouteWithChildren
  '/_links/links/_c': typeof LinksLinksCRouteWithChildren
  '/_links/links/_i': typeof LinksLinksIRouteWithChildren
  '/_admin/a/settings/karla-kolumna': typeof AdminASettingsKarlaKolumnaRoute
  '/_admin/a/settings/mail': typeof AdminASettingsMailRoute
  '/_admin/a/settings/matrix': typeof AdminASettingsMatrixRoute
  '/_internal/i/projects/create': typeof InternalIProjectsCreateRoute
  '/_internal/i/upselling/offer-suggestions': typeof InternalIUpsellingOfferSuggestionsRoute
  '/_internal/i/customers/': typeof InternalICustomersIndexRoute
  '/_admin/a/_profile/profile/general': typeof AdminAProfileProfileGeneralRoute
  '/_admin/a/_profile/profile/security': typeof AdminAProfileProfileSecurityRoute
  '/_customer/c/_profile/profile/general': typeof CustomerCProfileProfileGeneralRoute
  '/_customer/c/_profile/profile/security': typeof CustomerCProfileProfileSecurityRoute
  '/_internal/i/_pm-view/pm-view/overdue-tasks': typeof InternalIPmViewPmViewOverdueTasksRoute
  '/_internal/i/_profile/profile/general': typeof InternalIProfileProfileGeneralRoute
  '/_internal/i/_profile/profile/security': typeof InternalIProfileProfileSecurityRoute
  '/_internal/i/_tasks/tasks/my': typeof InternalITasksTasksMyRoute
  '/_internal/i/_tasks/tasks/pool': typeof InternalITasksTasksPoolRoute
  '/_internal/i/customers/$customerId': typeof InternalICustomersCustomerIdRouteWithChildren
  '/_internal/i/customers/$customerId/_customer': typeof InternalICustomersCustomerIdCustomerRouteWithChildren
  '/_internal/i/pentests/$projectId': typeof InternalIPentestsProjectIdRouteWithChildren
  '/_internal/i/pentests/$projectId/_pentest': typeof InternalIPentestsProjectIdPentestRouteWithChildren
  '/_internal/i/pentests/$projectId/targets': typeof InternalIPentestsProjectIdTargetsRoute
  '/_internal/i/phishing/scripts/create-campaign': typeof InternalIPhishingScriptsCreateCampaignRoute
  '/_internal/i/phishing/scripts/generate-attack-status': typeof InternalIPhishingScriptsGenerateAttackStatusRoute
  '/_internal/i/phishing/scripts/generate-presentation': typeof InternalIPhishingScriptsGeneratePresentationRoute
  '/_internal/i/phishing/scripts/generate-raw-csv': typeof InternalIPhishingScriptsGenerateRawCsvRoute
  '/_internal/i/phishing/scripts/generate-training-status': typeof InternalIPhishingScriptsGenerateTrainingStatusRoute
  '/_internal/i/questionnaires/groups/$groupId': typeof InternalIQuestionnairesGroupsGroupIdRoute
  '/_internal/i/questionnaires/groups/create': typeof InternalIQuestionnairesGroupsCreateRoute
  '/_internal/i/questionnaires/questions/$questionId': typeof InternalIQuestionnairesQuestionsQuestionIdRoute
  '/_internal/i/questionnaires/questions/create': typeof InternalIQuestionnairesQuestionsCreateRoute
  '/_internal/i/questionnaires/templates/$questionnaireId': typeof InternalIQuestionnairesTemplatesQuestionnaireIdRoute
  '/_internal/i/questionnaires/templates/create': typeof InternalIQuestionnairesTemplatesCreateRoute
  '/_internal/i/phishing/scripts/generate-ir-presentation': typeof InternalIPhishingScriptsGenerateIrPresentationLazyRoute
  '/_internal/i/_pm-view/pm-view/': typeof InternalIPmViewPmViewIndexRoute
  '/_internal/i/_tasks/tasks/': typeof InternalITasksTasksIndexRoute
  '/_internal/i/phishing/scripts/': typeof InternalIPhishingScriptsIndexRoute
  '/_internal/i/questionnaires/groups/': typeof InternalIQuestionnairesGroupsIndexRoute
  '/_internal/i/questionnaires/questions/': typeof InternalIQuestionnairesQuestionsIndexRoute
  '/_internal/i/questionnaires/templates/': typeof InternalIQuestionnairesTemplatesIndexRoute
  '/_customer/c/forms/tasks/$taskId/assign-project-contacts': typeof CustomerCFormsTasksTaskIdAssignProjectContactsRoute
  '/_customer/c/forms/tasks/$taskId/questionnaire': typeof CustomerCFormsTasksTaskIdQuestionnaireRoute
  '/_customer/c/forms/tasks/$taskId/target': typeof CustomerCFormsTasksTaskIdTargetRoute
  '/_customer/c/projects/pentests/$projectId': typeof CustomerCProjectsPentestsProjectIdRouteWithChildren
  '/_customer/c/projects/pentests/$projectId/_pentest': typeof CustomerCProjectsPentestsProjectIdPentestRouteWithChildren
  '/_internal/i/customers/$customerId/_customer/contacts': typeof InternalICustomersCustomerIdCustomerContactsRoute
  '/_internal/i/customers/$customerId/_customer/general': typeof InternalICustomersCustomerIdCustomerGeneralRoute
  '/_internal/i/customers/$customerId/_customer/upselling': typeof InternalICustomersCustomerIdCustomerUpsellingRoute
  '/_internal/i/forms/tasks/$taskId/add-http-findings-v0': typeof InternalIFormsTasksTaskIdAddHttpFindingsV0Route
  '/_internal/i/forms/tasks/$taskId/confirm-found-v0': typeof InternalIFormsTasksTaskIdConfirmFoundV0Route
  '/_internal/i/forms/tasks/$taskId/create-services-v0': typeof InternalIFormsTasksTaskIdCreateServicesV0Route
  '/_internal/i/forms/tasks/$taskId/enter-debrief-results-v0': typeof InternalIFormsTasksTaskIdEnterDebriefResultsV0Route
  '/_internal/i/forms/tasks/$taskId/enter-dialog-results-v0': typeof InternalIFormsTasksTaskIdEnterDialogResultsV0Route
  '/_internal/i/forms/tasks/$taskId/enter-dialog-results-v1': typeof InternalIFormsTasksTaskIdEnterDialogResultsV1Route
  '/_internal/i/forms/tasks/$taskId/enter-kickoff-results-v0': typeof InternalIFormsTasksTaskIdEnterKickoffResultsV0Route
  '/_internal/i/forms/tasks/$taskId/enter-kickoff-results-v1': typeof InternalIFormsTasksTaskIdEnterKickoffResultsV1Route
  '/_internal/i/forms/tasks/$taskId/enter-mgmt-results-v0': typeof InternalIFormsTasksTaskIdEnterMgmtResultsV0Route
  '/_internal/i/forms/tasks/$taskId/enter-mgmt-results-v1': typeof InternalIFormsTasksTaskIdEnterMgmtResultsV1Route
  '/_internal/i/forms/tasks/$taskId/import-data-v0': typeof InternalIFormsTasksTaskIdImportDataV0Route
  '/_internal/i/forms/tasks/$taskId/invite-customer-user-v0': typeof InternalIFormsTasksTaskIdInviteCustomerUserV0Route
  '/_internal/i/forms/tasks/$taskId/invite-debrief-v0': typeof InternalIFormsTasksTaskIdInviteDebriefV0Route
  '/_internal/i/forms/tasks/$taskId/invite-dialog-v0': typeof InternalIFormsTasksTaskIdInviteDialogV0Route
  '/_internal/i/forms/tasks/$taskId/invite-mgmt-presentation-v0': typeof InternalIFormsTasksTaskIdInviteMgmtPresentationV0Route
  '/_internal/i/forms/tasks/$taskId/run-kraken-assi-v0': typeof InternalIFormsTasksTaskIdRunKrakenAssiV0Route
  '/_internal/i/forms/tasks/$taskId/scan-shodan-v0': typeof InternalIFormsTasksTaskIdScanShodanV0Route
  '/_internal/i/forms/tasks/$taskId/schedule-debrief-v0': typeof InternalIFormsTasksTaskIdScheduleDebriefV0Route
  '/_internal/i/forms/tasks/$taskId/schedule-dialog-v0': typeof InternalIFormsTasksTaskIdScheduleDialogV0Route
  '/_internal/i/forms/tasks/$taskId/schedule-kickoff-v0': typeof InternalIFormsTasksTaskIdScheduleKickoffV0Route
  '/_internal/i/forms/tasks/$taskId/schedule-kickoff-v1': typeof InternalIFormsTasksTaskIdScheduleKickoffV1Route
  '/_internal/i/forms/tasks/$taskId/schedule-mgmt-presentation-v0': typeof InternalIFormsTasksTaskIdScheduleMgmtPresentationV0Route
  '/_internal/i/forms/tasks/$taskId/start-initial-scan-v0': typeof InternalIFormsTasksTaskIdStartInitialScanV0Route
  '/_internal/i/forms/tasks/$taskId/test-common-v0': typeof InternalIFormsTasksTaskIdTestCommonV0Route
  '/_internal/i/forms/tasks/$taskId/test-exotic-v0': typeof InternalIFormsTasksTaskIdTestExoticV0Route
  '/_internal/i/forms/tasks/$taskId/test-ssl-v0': typeof InternalIFormsTasksTaskIdTestSslV0Route
  '/_internal/i/forms/tasks/$taskId/upload-dialog-presentation': typeof InternalIFormsTasksTaskIdUploadDialogPresentationRoute
  '/_internal/i/forms/tasks/$taskId/upload-kickoff-presentation': typeof InternalIFormsTasksTaskIdUploadKickoffPresentationRoute
  '/_internal/i/forms/tasks/$taskId/upload-mgmt-presentation': typeof InternalIFormsTasksTaskIdUploadMgmtPresentationRoute
  '/_internal/i/forms/tasks/$taskId/upload-pentest-report': typeof InternalIFormsTasksTaskIdUploadPentestReportRoute
  '/_internal/i/pentests/$projectId/_pentest/contacts': typeof InternalIPentestsProjectIdPentestContactsRoute
  '/_internal/i/pentests/$projectId/_pentest/files': typeof InternalIPentestsProjectIdPentestFilesRoute
  '/_internal/i/pentests/$projectId/_pentest/notes': typeof InternalIPentestsProjectIdPentestNotesRoute
  '/_links/links/_c/c/pentests/$projectId': typeof LinksLinksCCPentestsProjectIdRoute
  '/_links/links/_c/c/tasks/$taskId': typeof LinksLinksCCTasksTaskIdRoute
  '/_links/links/_i/i/customers/$customerId': typeof LinksLinksIICustomersCustomerIdRoute
  '/_links/links/_i/i/tasks/': typeof LinksLinksIITasksIndexRoute
  '/_customer/c/projects/pentests/$projectId/_pentest/contacts': typeof CustomerCProjectsPentestsProjectIdPentestContactsRoute
  '/_customer/c/projects/pentests/$projectId/_pentest/files': typeof CustomerCProjectsPentestsProjectIdPentestFilesRoute
  '/_customer/c/projects/pentests/$projectId/_pentest/general': typeof CustomerCProjectsPentestsProjectIdPentestGeneralRoute
  '/_customer/c/projects/pentests/$projectId/_pentest/schedule': typeof CustomerCProjectsPentestsProjectIdPentestScheduleRoute
  '/_internal/i/pentests/$projectId/_pentest/general/edit-ta': typeof InternalIPentestsProjectIdPentestGeneralEditTaRoute
  '/_internal/i/pentests/$projectId/_pentest/schedule/change-debrief-date': typeof InternalIPentestsProjectIdPentestScheduleChangeDebriefDateRoute
  '/_internal/i/pentests/$projectId/_pentest/schedule/change-dialog-date': typeof InternalIPentestsProjectIdPentestScheduleChangeDialogDateRoute
  '/_internal/i/pentests/$projectId/_pentest/schedule/change-kickoff-date': typeof InternalIPentestsProjectIdPentestScheduleChangeKickoffDateRoute
  '/_internal/i/pentests/$projectId/_pentest/schedule/change-mgmt-date': typeof InternalIPentestsProjectIdPentestScheduleChangeMgmtDateRoute
  '/_links/links/_i/i/pentests/$projectId/targets': typeof LinksLinksIIPentestsProjectIdTargetsRoute
  '/_internal/i/pentests/$projectId/_pentest/general/': typeof InternalIPentestsProjectIdPentestGeneralIndexRoute
  '/_internal/i/pentests/$projectId/_pentest/schedule/': typeof InternalIPentestsProjectIdPentestScheduleIndexRoute
  '/_links/links/_i/i/pentests/$projectId/': typeof LinksLinksIIPentestsProjectIdIndexRoute
  '/_links/links/_i/i/pentests/$projectId/questionnaires/$questionnaireId': typeof LinksLinksIIPentestsProjectIdQuestionnairesQuestionnaireIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/invite/$inviteId'
    | '/reset/$resetId'
    | '/a'
    | '/a/dashboard'
    | '/a/user'
    | '/c'
    | '/c/dashboard'
    | '/c/tasks'
    | '/i'
    | '/i/dashboard'
    | '/i/run-example-script'
    | '/links'
    | '/a/settings/karla-kolumna'
    | '/a/settings/mail'
    | '/a/settings/matrix'
    | '/i/projects/create'
    | '/i/upselling/offer-suggestions'
    | '/i/customers'
    | '/a/profile/general'
    | '/a/profile/security'
    | '/c/profile/general'
    | '/c/profile/security'
    | '/i/pm-view/overdue-tasks'
    | '/i/profile/general'
    | '/i/profile/security'
    | '/i/tasks/my'
    | '/i/tasks/pool'
    | '/i/customers/$customerId'
    | '/i/pentests/$projectId'
    | '/i/pentests/$projectId/targets'
    | '/i/phishing/scripts/create-campaign'
    | '/i/phishing/scripts/generate-attack-status'
    | '/i/phishing/scripts/generate-presentation'
    | '/i/phishing/scripts/generate-raw-csv'
    | '/i/phishing/scripts/generate-training-status'
    | '/i/questionnaires/groups/$groupId'
    | '/i/questionnaires/groups/create'
    | '/i/questionnaires/questions/$questionId'
    | '/i/questionnaires/questions/create'
    | '/i/questionnaires/templates/$questionnaireId'
    | '/i/questionnaires/templates/create'
    | '/i/phishing/scripts/generate-ir-presentation'
    | '/i/pm-view'
    | '/i/tasks'
    | '/i/phishing/scripts'
    | '/i/questionnaires/groups'
    | '/i/questionnaires/questions'
    | '/i/questionnaires/templates'
    | '/c/forms/tasks/$taskId/assign-project-contacts'
    | '/c/forms/tasks/$taskId/questionnaire'
    | '/c/forms/tasks/$taskId/target'
    | '/c/projects/pentests/$projectId'
    | '/i/customers/$customerId/contacts'
    | '/i/customers/$customerId/general'
    | '/i/customers/$customerId/upselling'
    | '/i/forms/tasks/$taskId/add-http-findings-v0'
    | '/i/forms/tasks/$taskId/confirm-found-v0'
    | '/i/forms/tasks/$taskId/create-services-v0'
    | '/i/forms/tasks/$taskId/enter-debrief-results-v0'
    | '/i/forms/tasks/$taskId/enter-dialog-results-v0'
    | '/i/forms/tasks/$taskId/enter-dialog-results-v1'
    | '/i/forms/tasks/$taskId/enter-kickoff-results-v0'
    | '/i/forms/tasks/$taskId/enter-kickoff-results-v1'
    | '/i/forms/tasks/$taskId/enter-mgmt-results-v0'
    | '/i/forms/tasks/$taskId/enter-mgmt-results-v1'
    | '/i/forms/tasks/$taskId/import-data-v0'
    | '/i/forms/tasks/$taskId/invite-customer-user-v0'
    | '/i/forms/tasks/$taskId/invite-debrief-v0'
    | '/i/forms/tasks/$taskId/invite-dialog-v0'
    | '/i/forms/tasks/$taskId/invite-mgmt-presentation-v0'
    | '/i/forms/tasks/$taskId/run-kraken-assi-v0'
    | '/i/forms/tasks/$taskId/scan-shodan-v0'
    | '/i/forms/tasks/$taskId/schedule-debrief-v0'
    | '/i/forms/tasks/$taskId/schedule-dialog-v0'
    | '/i/forms/tasks/$taskId/schedule-kickoff-v0'
    | '/i/forms/tasks/$taskId/schedule-kickoff-v1'
    | '/i/forms/tasks/$taskId/schedule-mgmt-presentation-v0'
    | '/i/forms/tasks/$taskId/start-initial-scan-v0'
    | '/i/forms/tasks/$taskId/test-common-v0'
    | '/i/forms/tasks/$taskId/test-exotic-v0'
    | '/i/forms/tasks/$taskId/test-ssl-v0'
    | '/i/forms/tasks/$taskId/upload-dialog-presentation'
    | '/i/forms/tasks/$taskId/upload-kickoff-presentation'
    | '/i/forms/tasks/$taskId/upload-mgmt-presentation'
    | '/i/forms/tasks/$taskId/upload-pentest-report'
    | '/i/pentests/$projectId/contacts'
    | '/i/pentests/$projectId/files'
    | '/i/pentests/$projectId/notes'
    | '/links/c/pentests/$projectId'
    | '/links/c/tasks/$taskId'
    | '/links/i/customers/$customerId'
    | '/links/i/tasks'
    | '/c/projects/pentests/$projectId/contacts'
    | '/c/projects/pentests/$projectId/files'
    | '/c/projects/pentests/$projectId/general'
    | '/c/projects/pentests/$projectId/schedule'
    | '/i/pentests/$projectId/general/edit-ta'
    | '/i/pentests/$projectId/schedule/change-debrief-date'
    | '/i/pentests/$projectId/schedule/change-dialog-date'
    | '/i/pentests/$projectId/schedule/change-kickoff-date'
    | '/i/pentests/$projectId/schedule/change-mgmt-date'
    | '/links/i/pentests/$projectId/targets'
    | '/i/pentests/$projectId/general'
    | '/i/pentests/$projectId/schedule'
    | '/links/i/pentests/$projectId'
    | '/links/i/pentests/$projectId/questionnaires/$questionnaireId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/invite/$inviteId'
    | '/reset/$resetId'
    | '/a'
    | '/a/dashboard'
    | '/a/user'
    | '/c'
    | '/c/dashboard'
    | '/c/tasks'
    | '/i'
    | '/i/dashboard'
    | '/i/run-example-script'
    | '/links'
    | '/a/settings/karla-kolumna'
    | '/a/settings/mail'
    | '/a/settings/matrix'
    | '/i/projects/create'
    | '/i/upselling/offer-suggestions'
    | '/i/customers'
    | '/a/profile/general'
    | '/a/profile/security'
    | '/c/profile/general'
    | '/c/profile/security'
    | '/i/pm-view/overdue-tasks'
    | '/i/profile/general'
    | '/i/profile/security'
    | '/i/tasks/my'
    | '/i/tasks/pool'
    | '/i/customers/$customerId'
    | '/i/pentests/$projectId'
    | '/i/pentests/$projectId/targets'
    | '/i/phishing/scripts/create-campaign'
    | '/i/phishing/scripts/generate-attack-status'
    | '/i/phishing/scripts/generate-presentation'
    | '/i/phishing/scripts/generate-raw-csv'
    | '/i/phishing/scripts/generate-training-status'
    | '/i/questionnaires/groups/$groupId'
    | '/i/questionnaires/groups/create'
    | '/i/questionnaires/questions/$questionId'
    | '/i/questionnaires/questions/create'
    | '/i/questionnaires/templates/$questionnaireId'
    | '/i/questionnaires/templates/create'
    | '/i/phishing/scripts/generate-ir-presentation'
    | '/i/pm-view'
    | '/i/tasks'
    | '/i/phishing/scripts'
    | '/i/questionnaires/groups'
    | '/i/questionnaires/questions'
    | '/i/questionnaires/templates'
    | '/c/forms/tasks/$taskId/assign-project-contacts'
    | '/c/forms/tasks/$taskId/questionnaire'
    | '/c/forms/tasks/$taskId/target'
    | '/c/projects/pentests/$projectId'
    | '/i/customers/$customerId/contacts'
    | '/i/customers/$customerId/general'
    | '/i/customers/$customerId/upselling'
    | '/i/forms/tasks/$taskId/add-http-findings-v0'
    | '/i/forms/tasks/$taskId/confirm-found-v0'
    | '/i/forms/tasks/$taskId/create-services-v0'
    | '/i/forms/tasks/$taskId/enter-debrief-results-v0'
    | '/i/forms/tasks/$taskId/enter-dialog-results-v0'
    | '/i/forms/tasks/$taskId/enter-dialog-results-v1'
    | '/i/forms/tasks/$taskId/enter-kickoff-results-v0'
    | '/i/forms/tasks/$taskId/enter-kickoff-results-v1'
    | '/i/forms/tasks/$taskId/enter-mgmt-results-v0'
    | '/i/forms/tasks/$taskId/enter-mgmt-results-v1'
    | '/i/forms/tasks/$taskId/import-data-v0'
    | '/i/forms/tasks/$taskId/invite-customer-user-v0'
    | '/i/forms/tasks/$taskId/invite-debrief-v0'
    | '/i/forms/tasks/$taskId/invite-dialog-v0'
    | '/i/forms/tasks/$taskId/invite-mgmt-presentation-v0'
    | '/i/forms/tasks/$taskId/run-kraken-assi-v0'
    | '/i/forms/tasks/$taskId/scan-shodan-v0'
    | '/i/forms/tasks/$taskId/schedule-debrief-v0'
    | '/i/forms/tasks/$taskId/schedule-dialog-v0'
    | '/i/forms/tasks/$taskId/schedule-kickoff-v0'
    | '/i/forms/tasks/$taskId/schedule-kickoff-v1'
    | '/i/forms/tasks/$taskId/schedule-mgmt-presentation-v0'
    | '/i/forms/tasks/$taskId/start-initial-scan-v0'
    | '/i/forms/tasks/$taskId/test-common-v0'
    | '/i/forms/tasks/$taskId/test-exotic-v0'
    | '/i/forms/tasks/$taskId/test-ssl-v0'
    | '/i/forms/tasks/$taskId/upload-dialog-presentation'
    | '/i/forms/tasks/$taskId/upload-kickoff-presentation'
    | '/i/forms/tasks/$taskId/upload-mgmt-presentation'
    | '/i/forms/tasks/$taskId/upload-pentest-report'
    | '/i/pentests/$projectId/contacts'
    | '/i/pentests/$projectId/files'
    | '/i/pentests/$projectId/notes'
    | '/links/c/pentests/$projectId'
    | '/links/c/tasks/$taskId'
    | '/links/i/customers/$customerId'
    | '/links/i/tasks'
    | '/c/projects/pentests/$projectId/contacts'
    | '/c/projects/pentests/$projectId/files'
    | '/c/projects/pentests/$projectId/general'
    | '/c/projects/pentests/$projectId/schedule'
    | '/i/pentests/$projectId/general/edit-ta'
    | '/i/pentests/$projectId/schedule/change-debrief-date'
    | '/i/pentests/$projectId/schedule/change-dialog-date'
    | '/i/pentests/$projectId/schedule/change-kickoff-date'
    | '/i/pentests/$projectId/schedule/change-mgmt-date'
    | '/links/i/pentests/$projectId/targets'
    | '/i/pentests/$projectId/general'
    | '/i/pentests/$projectId/schedule'
    | '/links/i/pentests/$projectId'
    | '/links/i/pentests/$projectId/questionnaires/$questionnaireId'
  id:
    | '__root__'
    | '/'
    | '/_admin'
    | '/_customer'
    | '/_internal'
    | '/_links'
    | '/invite/$inviteId'
    | '/reset/$resetId'
    | '/_admin/a'
    | '/_admin/a/_profile'
    | '/_admin/a/dashboard'
    | '/_admin/a/user'
    | '/_customer/c'
    | '/_customer/c/_profile'
    | '/_customer/c/dashboard'
    | '/_customer/c/tasks'
    | '/_internal/i'
    | '/_internal/i/_pm-view'
    | '/_internal/i/_profile'
    | '/_internal/i/_tasks'
    | '/_internal/i/dashboard'
    | '/_internal/i/run-example-script'
    | '/_links/links'
    | '/_links/links/_c'
    | '/_links/links/_i'
    | '/_admin/a/settings/karla-kolumna'
    | '/_admin/a/settings/mail'
    | '/_admin/a/settings/matrix'
    | '/_internal/i/projects/create'
    | '/_internal/i/upselling/offer-suggestions'
    | '/_internal/i/customers/'
    | '/_admin/a/_profile/profile/general'
    | '/_admin/a/_profile/profile/security'
    | '/_customer/c/_profile/profile/general'
    | '/_customer/c/_profile/profile/security'
    | '/_internal/i/_pm-view/pm-view/overdue-tasks'
    | '/_internal/i/_profile/profile/general'
    | '/_internal/i/_profile/profile/security'
    | '/_internal/i/_tasks/tasks/my'
    | '/_internal/i/_tasks/tasks/pool'
    | '/_internal/i/customers/$customerId'
    | '/_internal/i/customers/$customerId/_customer'
    | '/_internal/i/pentests/$projectId'
    | '/_internal/i/pentests/$projectId/_pentest'
    | '/_internal/i/pentests/$projectId/targets'
    | '/_internal/i/phishing/scripts/create-campaign'
    | '/_internal/i/phishing/scripts/generate-attack-status'
    | '/_internal/i/phishing/scripts/generate-presentation'
    | '/_internal/i/phishing/scripts/generate-raw-csv'
    | '/_internal/i/phishing/scripts/generate-training-status'
    | '/_internal/i/questionnaires/groups/$groupId'
    | '/_internal/i/questionnaires/groups/create'
    | '/_internal/i/questionnaires/questions/$questionId'
    | '/_internal/i/questionnaires/questions/create'
    | '/_internal/i/questionnaires/templates/$questionnaireId'
    | '/_internal/i/questionnaires/templates/create'
    | '/_internal/i/phishing/scripts/generate-ir-presentation'
    | '/_internal/i/_pm-view/pm-view/'
    | '/_internal/i/_tasks/tasks/'
    | '/_internal/i/phishing/scripts/'
    | '/_internal/i/questionnaires/groups/'
    | '/_internal/i/questionnaires/questions/'
    | '/_internal/i/questionnaires/templates/'
    | '/_customer/c/forms/tasks/$taskId/assign-project-contacts'
    | '/_customer/c/forms/tasks/$taskId/questionnaire'
    | '/_customer/c/forms/tasks/$taskId/target'
    | '/_customer/c/projects/pentests/$projectId'
    | '/_customer/c/projects/pentests/$projectId/_pentest'
    | '/_internal/i/customers/$customerId/_customer/contacts'
    | '/_internal/i/customers/$customerId/_customer/general'
    | '/_internal/i/customers/$customerId/_customer/upselling'
    | '/_internal/i/forms/tasks/$taskId/add-http-findings-v0'
    | '/_internal/i/forms/tasks/$taskId/confirm-found-v0'
    | '/_internal/i/forms/tasks/$taskId/create-services-v0'
    | '/_internal/i/forms/tasks/$taskId/enter-debrief-results-v0'
    | '/_internal/i/forms/tasks/$taskId/enter-dialog-results-v0'
    | '/_internal/i/forms/tasks/$taskId/enter-dialog-results-v1'
    | '/_internal/i/forms/tasks/$taskId/enter-kickoff-results-v0'
    | '/_internal/i/forms/tasks/$taskId/enter-kickoff-results-v1'
    | '/_internal/i/forms/tasks/$taskId/enter-mgmt-results-v0'
    | '/_internal/i/forms/tasks/$taskId/enter-mgmt-results-v1'
    | '/_internal/i/forms/tasks/$taskId/import-data-v0'
    | '/_internal/i/forms/tasks/$taskId/invite-customer-user-v0'
    | '/_internal/i/forms/tasks/$taskId/invite-debrief-v0'
    | '/_internal/i/forms/tasks/$taskId/invite-dialog-v0'
    | '/_internal/i/forms/tasks/$taskId/invite-mgmt-presentation-v0'
    | '/_internal/i/forms/tasks/$taskId/run-kraken-assi-v0'
    | '/_internal/i/forms/tasks/$taskId/scan-shodan-v0'
    | '/_internal/i/forms/tasks/$taskId/schedule-debrief-v0'
    | '/_internal/i/forms/tasks/$taskId/schedule-dialog-v0'
    | '/_internal/i/forms/tasks/$taskId/schedule-kickoff-v0'
    | '/_internal/i/forms/tasks/$taskId/schedule-kickoff-v1'
    | '/_internal/i/forms/tasks/$taskId/schedule-mgmt-presentation-v0'
    | '/_internal/i/forms/tasks/$taskId/start-initial-scan-v0'
    | '/_internal/i/forms/tasks/$taskId/test-common-v0'
    | '/_internal/i/forms/tasks/$taskId/test-exotic-v0'
    | '/_internal/i/forms/tasks/$taskId/test-ssl-v0'
    | '/_internal/i/forms/tasks/$taskId/upload-dialog-presentation'
    | '/_internal/i/forms/tasks/$taskId/upload-kickoff-presentation'
    | '/_internal/i/forms/tasks/$taskId/upload-mgmt-presentation'
    | '/_internal/i/forms/tasks/$taskId/upload-pentest-report'
    | '/_internal/i/pentests/$projectId/_pentest/contacts'
    | '/_internal/i/pentests/$projectId/_pentest/files'
    | '/_internal/i/pentests/$projectId/_pentest/notes'
    | '/_links/links/_c/c/pentests/$projectId'
    | '/_links/links/_c/c/tasks/$taskId'
    | '/_links/links/_i/i/customers/$customerId'
    | '/_links/links/_i/i/tasks/'
    | '/_customer/c/projects/pentests/$projectId/_pentest/contacts'
    | '/_customer/c/projects/pentests/$projectId/_pentest/files'
    | '/_customer/c/projects/pentests/$projectId/_pentest/general'
    | '/_customer/c/projects/pentests/$projectId/_pentest/schedule'
    | '/_internal/i/pentests/$projectId/_pentest/general/edit-ta'
    | '/_internal/i/pentests/$projectId/_pentest/schedule/change-debrief-date'
    | '/_internal/i/pentests/$projectId/_pentest/schedule/change-dialog-date'
    | '/_internal/i/pentests/$projectId/_pentest/schedule/change-kickoff-date'
    | '/_internal/i/pentests/$projectId/_pentest/schedule/change-mgmt-date'
    | '/_links/links/_i/i/pentests/$projectId/targets'
    | '/_internal/i/pentests/$projectId/_pentest/general/'
    | '/_internal/i/pentests/$projectId/_pentest/schedule/'
    | '/_links/links/_i/i/pentests/$projectId/'
    | '/_links/links/_i/i/pentests/$projectId/questionnaires/$questionnaireId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AdminLazyRoute: typeof AdminLazyRouteWithChildren
  CustomerLazyRoute: typeof CustomerLazyRouteWithChildren
  InternalLazyRoute: typeof InternalLazyRouteWithChildren
  LinksLazyRoute: typeof LinksLazyRouteWithChildren
  InviteInviteIdLazyRoute: typeof InviteInviteIdLazyRoute
  ResetResetIdLazyRoute: typeof ResetResetIdLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AdminLazyRoute: AdminLazyRouteWithChildren,
  CustomerLazyRoute: CustomerLazyRouteWithChildren,
  InternalLazyRoute: InternalLazyRouteWithChildren,
  LinksLazyRoute: LinksLazyRouteWithChildren,
  InviteInviteIdLazyRoute: InviteInviteIdLazyRoute,
  ResetResetIdLazyRoute: ResetResetIdLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_admin",
        "/_customer",
        "/_internal",
        "/_links",
        "/invite/$inviteId",
        "/reset/$resetId"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_admin": {
      "filePath": "_admin.lazy.tsx",
      "children": [
        "/_admin/a"
      ]
    },
    "/_customer": {
      "filePath": "_customer.lazy.tsx",
      "children": [
        "/_customer/c"
      ]
    },
    "/_internal": {
      "filePath": "_internal.lazy.tsx",
      "children": [
        "/_internal/i"
      ]
    },
    "/_links": {
      "filePath": "_links.lazy.tsx",
      "children": [
        "/_links/links"
      ]
    },
    "/invite/$inviteId": {
      "filePath": "invite/$inviteId.lazy.tsx"
    },
    "/reset/$resetId": {
      "filePath": "reset/$resetId.lazy.tsx"
    },
    "/_admin/a": {
      "filePath": "_admin/a",
      "parent": "/_admin",
      "children": [
        "/_admin/a/_profile",
        "/_admin/a/dashboard",
        "/_admin/a/user",
        "/_admin/a/settings/karla-kolumna",
        "/_admin/a/settings/mail",
        "/_admin/a/settings/matrix"
      ]
    },
    "/_admin/a/_profile": {
      "filePath": "_admin/a/_profile.tsx",
      "parent": "/_admin/a",
      "children": [
        "/_admin/a/_profile/profile/general",
        "/_admin/a/_profile/profile/security"
      ]
    },
    "/_admin/a/dashboard": {
      "filePath": "_admin/a/dashboard.tsx",
      "parent": "/_admin/a"
    },
    "/_admin/a/user": {
      "filePath": "_admin/a/user.tsx",
      "parent": "/_admin/a"
    },
    "/_customer/c": {
      "filePath": "_customer/c",
      "parent": "/_customer",
      "children": [
        "/_customer/c/_profile",
        "/_customer/c/dashboard",
        "/_customer/c/tasks",
        "/_customer/c/forms/tasks/$taskId/assign-project-contacts",
        "/_customer/c/forms/tasks/$taskId/questionnaire",
        "/_customer/c/forms/tasks/$taskId/target",
        "/_customer/c/projects/pentests/$projectId"
      ]
    },
    "/_customer/c/_profile": {
      "filePath": "_customer/c/_profile.tsx",
      "parent": "/_customer/c",
      "children": [
        "/_customer/c/_profile/profile/general",
        "/_customer/c/_profile/profile/security"
      ]
    },
    "/_customer/c/dashboard": {
      "filePath": "_customer/c/dashboard.tsx",
      "parent": "/_customer/c"
    },
    "/_customer/c/tasks": {
      "filePath": "_customer/c/tasks.tsx",
      "parent": "/_customer/c"
    },
    "/_internal/i": {
      "filePath": "_internal/i",
      "parent": "/_internal",
      "children": [
        "/_internal/i/_pm-view",
        "/_internal/i/_profile",
        "/_internal/i/_tasks",
        "/_internal/i/dashboard",
        "/_internal/i/run-example-script",
        "/_internal/i/projects/create",
        "/_internal/i/upselling/offer-suggestions",
        "/_internal/i/customers/",
        "/_internal/i/customers/$customerId",
        "/_internal/i/pentests/$projectId",
        "/_internal/i/phishing/scripts/create-campaign",
        "/_internal/i/phishing/scripts/generate-attack-status",
        "/_internal/i/phishing/scripts/generate-presentation",
        "/_internal/i/phishing/scripts/generate-raw-csv",
        "/_internal/i/phishing/scripts/generate-training-status",
        "/_internal/i/questionnaires/groups/$groupId",
        "/_internal/i/questionnaires/groups/create",
        "/_internal/i/questionnaires/questions/$questionId",
        "/_internal/i/questionnaires/questions/create",
        "/_internal/i/questionnaires/templates/$questionnaireId",
        "/_internal/i/questionnaires/templates/create",
        "/_internal/i/phishing/scripts/generate-ir-presentation",
        "/_internal/i/phishing/scripts/",
        "/_internal/i/questionnaires/groups/",
        "/_internal/i/questionnaires/questions/",
        "/_internal/i/questionnaires/templates/",
        "/_internal/i/forms/tasks/$taskId/add-http-findings-v0",
        "/_internal/i/forms/tasks/$taskId/confirm-found-v0",
        "/_internal/i/forms/tasks/$taskId/create-services-v0",
        "/_internal/i/forms/tasks/$taskId/enter-debrief-results-v0",
        "/_internal/i/forms/tasks/$taskId/enter-dialog-results-v0",
        "/_internal/i/forms/tasks/$taskId/enter-dialog-results-v1",
        "/_internal/i/forms/tasks/$taskId/enter-kickoff-results-v0",
        "/_internal/i/forms/tasks/$taskId/enter-kickoff-results-v1",
        "/_internal/i/forms/tasks/$taskId/enter-mgmt-results-v0",
        "/_internal/i/forms/tasks/$taskId/enter-mgmt-results-v1",
        "/_internal/i/forms/tasks/$taskId/import-data-v0",
        "/_internal/i/forms/tasks/$taskId/invite-customer-user-v0",
        "/_internal/i/forms/tasks/$taskId/invite-debrief-v0",
        "/_internal/i/forms/tasks/$taskId/invite-dialog-v0",
        "/_internal/i/forms/tasks/$taskId/invite-mgmt-presentation-v0",
        "/_internal/i/forms/tasks/$taskId/run-kraken-assi-v0",
        "/_internal/i/forms/tasks/$taskId/scan-shodan-v0",
        "/_internal/i/forms/tasks/$taskId/schedule-debrief-v0",
        "/_internal/i/forms/tasks/$taskId/schedule-dialog-v0",
        "/_internal/i/forms/tasks/$taskId/schedule-kickoff-v0",
        "/_internal/i/forms/tasks/$taskId/schedule-kickoff-v1",
        "/_internal/i/forms/tasks/$taskId/schedule-mgmt-presentation-v0",
        "/_internal/i/forms/tasks/$taskId/start-initial-scan-v0",
        "/_internal/i/forms/tasks/$taskId/test-common-v0",
        "/_internal/i/forms/tasks/$taskId/test-exotic-v0",
        "/_internal/i/forms/tasks/$taskId/test-ssl-v0",
        "/_internal/i/forms/tasks/$taskId/upload-dialog-presentation",
        "/_internal/i/forms/tasks/$taskId/upload-kickoff-presentation",
        "/_internal/i/forms/tasks/$taskId/upload-mgmt-presentation",
        "/_internal/i/forms/tasks/$taskId/upload-pentest-report"
      ]
    },
    "/_internal/i/_pm-view": {
      "filePath": "_internal/i/_pm-view.tsx",
      "parent": "/_internal/i",
      "children": [
        "/_internal/i/_pm-view/pm-view/overdue-tasks",
        "/_internal/i/_pm-view/pm-view/"
      ]
    },
    "/_internal/i/_profile": {
      "filePath": "_internal/i/_profile.tsx",
      "parent": "/_internal/i",
      "children": [
        "/_internal/i/_profile/profile/general",
        "/_internal/i/_profile/profile/security"
      ]
    },
    "/_internal/i/_tasks": {
      "filePath": "_internal/i/_tasks.tsx",
      "parent": "/_internal/i",
      "children": [
        "/_internal/i/_tasks/tasks/my",
        "/_internal/i/_tasks/tasks/pool",
        "/_internal/i/_tasks/tasks/"
      ]
    },
    "/_internal/i/dashboard": {
      "filePath": "_internal/i/dashboard.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/run-example-script": {
      "filePath": "_internal/i/run-example-script.tsx",
      "parent": "/_internal/i"
    },
    "/_links/links": {
      "filePath": "_links/links",
      "parent": "/_links",
      "children": [
        "/_links/links/_c",
        "/_links/links/_i"
      ]
    },
    "/_links/links/_c": {
      "filePath": "_links/links/_c.tsx",
      "parent": "/_links/links",
      "children": [
        "/_links/links/_c/c/pentests/$projectId",
        "/_links/links/_c/c/tasks/$taskId"
      ]
    },
    "/_links/links/_i": {
      "filePath": "_links/links/_i.tsx",
      "parent": "/_links/links",
      "children": [
        "/_links/links/_i/i/customers/$customerId",
        "/_links/links/_i/i/tasks/",
        "/_links/links/_i/i/pentests/$projectId/targets",
        "/_links/links/_i/i/pentests/$projectId/",
        "/_links/links/_i/i/pentests/$projectId/questionnaires/$questionnaireId"
      ]
    },
    "/_admin/a/settings/karla-kolumna": {
      "filePath": "_admin/a/settings/karla-kolumna.tsx",
      "parent": "/_admin/a"
    },
    "/_admin/a/settings/mail": {
      "filePath": "_admin/a/settings/mail.tsx",
      "parent": "/_admin/a"
    },
    "/_admin/a/settings/matrix": {
      "filePath": "_admin/a/settings/matrix.tsx",
      "parent": "/_admin/a"
    },
    "/_internal/i/projects/create": {
      "filePath": "_internal/i/projects/create.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/upselling/offer-suggestions": {
      "filePath": "_internal/i/upselling/offer-suggestions.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/customers/": {
      "filePath": "_internal/i/customers/index.tsx",
      "parent": "/_internal/i"
    },
    "/_admin/a/_profile/profile/general": {
      "filePath": "_admin/a/_profile/profile/general.tsx",
      "parent": "/_admin/a/_profile"
    },
    "/_admin/a/_profile/profile/security": {
      "filePath": "_admin/a/_profile/profile/security.tsx",
      "parent": "/_admin/a/_profile"
    },
    "/_customer/c/_profile/profile/general": {
      "filePath": "_customer/c/_profile/profile/general.tsx",
      "parent": "/_customer/c/_profile"
    },
    "/_customer/c/_profile/profile/security": {
      "filePath": "_customer/c/_profile/profile/security.tsx",
      "parent": "/_customer/c/_profile"
    },
    "/_internal/i/_pm-view/pm-view/overdue-tasks": {
      "filePath": "_internal/i/_pm-view/pm-view/overdue-tasks.tsx",
      "parent": "/_internal/i/_pm-view"
    },
    "/_internal/i/_profile/profile/general": {
      "filePath": "_internal/i/_profile/profile/general.tsx",
      "parent": "/_internal/i/_profile"
    },
    "/_internal/i/_profile/profile/security": {
      "filePath": "_internal/i/_profile/profile/security.tsx",
      "parent": "/_internal/i/_profile"
    },
    "/_internal/i/_tasks/tasks/my": {
      "filePath": "_internal/i/_tasks/tasks/my.tsx",
      "parent": "/_internal/i/_tasks"
    },
    "/_internal/i/_tasks/tasks/pool": {
      "filePath": "_internal/i/_tasks/tasks/pool.tsx",
      "parent": "/_internal/i/_tasks"
    },
    "/_internal/i/customers/$customerId": {
      "filePath": "_internal/i/customers/$customerId",
      "parent": "/_internal/i",
      "children": [
        "/_internal/i/customers/$customerId/_customer"
      ]
    },
    "/_internal/i/customers/$customerId/_customer": {
      "filePath": "_internal/i/customers/$customerId/_customer.tsx",
      "parent": "/_internal/i/customers/$customerId",
      "children": [
        "/_internal/i/customers/$customerId/_customer/contacts",
        "/_internal/i/customers/$customerId/_customer/general",
        "/_internal/i/customers/$customerId/_customer/upselling"
      ]
    },
    "/_internal/i/pentests/$projectId": {
      "filePath": "_internal/i/pentests/$projectId",
      "parent": "/_internal/i",
      "children": [
        "/_internal/i/pentests/$projectId/_pentest",
        "/_internal/i/pentests/$projectId/targets"
      ]
    },
    "/_internal/i/pentests/$projectId/_pentest": {
      "filePath": "_internal/i/pentests/$projectId/_pentest.tsx",
      "parent": "/_internal/i/pentests/$projectId",
      "children": [
        "/_internal/i/pentests/$projectId/_pentest/contacts",
        "/_internal/i/pentests/$projectId/_pentest/files",
        "/_internal/i/pentests/$projectId/_pentest/notes",
        "/_internal/i/pentests/$projectId/_pentest/general/edit-ta",
        "/_internal/i/pentests/$projectId/_pentest/schedule/change-debrief-date",
        "/_internal/i/pentests/$projectId/_pentest/schedule/change-dialog-date",
        "/_internal/i/pentests/$projectId/_pentest/schedule/change-kickoff-date",
        "/_internal/i/pentests/$projectId/_pentest/schedule/change-mgmt-date",
        "/_internal/i/pentests/$projectId/_pentest/general/",
        "/_internal/i/pentests/$projectId/_pentest/schedule/"
      ]
    },
    "/_internal/i/pentests/$projectId/targets": {
      "filePath": "_internal/i/pentests/$projectId/targets.tsx",
      "parent": "/_internal/i/pentests/$projectId"
    },
    "/_internal/i/phishing/scripts/create-campaign": {
      "filePath": "_internal/i/phishing/scripts/create-campaign.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/phishing/scripts/generate-attack-status": {
      "filePath": "_internal/i/phishing/scripts/generate-attack-status.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/phishing/scripts/generate-presentation": {
      "filePath": "_internal/i/phishing/scripts/generate-presentation.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/phishing/scripts/generate-raw-csv": {
      "filePath": "_internal/i/phishing/scripts/generate-raw-csv.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/phishing/scripts/generate-training-status": {
      "filePath": "_internal/i/phishing/scripts/generate-training-status.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/questionnaires/groups/$groupId": {
      "filePath": "_internal/i/questionnaires/groups/$groupId.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/questionnaires/groups/create": {
      "filePath": "_internal/i/questionnaires/groups/create.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/questionnaires/questions/$questionId": {
      "filePath": "_internal/i/questionnaires/questions/$questionId.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/questionnaires/questions/create": {
      "filePath": "_internal/i/questionnaires/questions/create.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/questionnaires/templates/$questionnaireId": {
      "filePath": "_internal/i/questionnaires/templates/$questionnaireId.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/questionnaires/templates/create": {
      "filePath": "_internal/i/questionnaires/templates/create.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/phishing/scripts/generate-ir-presentation": {
      "filePath": "_internal/i/phishing/scripts/generate-ir-presentation.lazy.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/_pm-view/pm-view/": {
      "filePath": "_internal/i/_pm-view/pm-view/index.tsx",
      "parent": "/_internal/i/_pm-view"
    },
    "/_internal/i/_tasks/tasks/": {
      "filePath": "_internal/i/_tasks/tasks/index.tsx",
      "parent": "/_internal/i/_tasks"
    },
    "/_internal/i/phishing/scripts/": {
      "filePath": "_internal/i/phishing/scripts/index.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/questionnaires/groups/": {
      "filePath": "_internal/i/questionnaires/groups/index.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/questionnaires/questions/": {
      "filePath": "_internal/i/questionnaires/questions/index.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/questionnaires/templates/": {
      "filePath": "_internal/i/questionnaires/templates/index.tsx",
      "parent": "/_internal/i"
    },
    "/_customer/c/forms/tasks/$taskId/assign-project-contacts": {
      "filePath": "_customer/c/forms/tasks/$taskId/assign-project-contacts.tsx",
      "parent": "/_customer/c"
    },
    "/_customer/c/forms/tasks/$taskId/questionnaire": {
      "filePath": "_customer/c/forms/tasks/$taskId/questionnaire.tsx",
      "parent": "/_customer/c"
    },
    "/_customer/c/forms/tasks/$taskId/target": {
      "filePath": "_customer/c/forms/tasks/$taskId/target.tsx",
      "parent": "/_customer/c"
    },
    "/_customer/c/projects/pentests/$projectId": {
      "filePath": "_customer/c/projects/pentests/$projectId",
      "parent": "/_customer/c",
      "children": [
        "/_customer/c/projects/pentests/$projectId/_pentest"
      ]
    },
    "/_customer/c/projects/pentests/$projectId/_pentest": {
      "filePath": "_customer/c/projects/pentests/$projectId/_pentest.tsx",
      "parent": "/_customer/c/projects/pentests/$projectId",
      "children": [
        "/_customer/c/projects/pentests/$projectId/_pentest/contacts",
        "/_customer/c/projects/pentests/$projectId/_pentest/files",
        "/_customer/c/projects/pentests/$projectId/_pentest/general",
        "/_customer/c/projects/pentests/$projectId/_pentest/schedule"
      ]
    },
    "/_internal/i/customers/$customerId/_customer/contacts": {
      "filePath": "_internal/i/customers/$customerId/_customer/contacts.tsx",
      "parent": "/_internal/i/customers/$customerId/_customer"
    },
    "/_internal/i/customers/$customerId/_customer/general": {
      "filePath": "_internal/i/customers/$customerId/_customer/general.tsx",
      "parent": "/_internal/i/customers/$customerId/_customer"
    },
    "/_internal/i/customers/$customerId/_customer/upselling": {
      "filePath": "_internal/i/customers/$customerId/_customer/upselling.tsx",
      "parent": "/_internal/i/customers/$customerId/_customer"
    },
    "/_internal/i/forms/tasks/$taskId/add-http-findings-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/add-http-findings-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/confirm-found-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/confirm-found-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/create-services-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/create-services-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/enter-debrief-results-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/enter-debrief-results-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/enter-dialog-results-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/enter-dialog-results-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/enter-dialog-results-v1": {
      "filePath": "_internal/i/forms/tasks/$taskId/enter-dialog-results-v1.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/enter-kickoff-results-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/enter-kickoff-results-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/enter-kickoff-results-v1": {
      "filePath": "_internal/i/forms/tasks/$taskId/enter-kickoff-results-v1.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/enter-mgmt-results-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/enter-mgmt-results-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/enter-mgmt-results-v1": {
      "filePath": "_internal/i/forms/tasks/$taskId/enter-mgmt-results-v1.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/import-data-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/import-data-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/invite-customer-user-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/invite-customer-user-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/invite-debrief-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/invite-debrief-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/invite-dialog-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/invite-dialog-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/invite-mgmt-presentation-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/invite-mgmt-presentation-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/run-kraken-assi-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/run-kraken-assi-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/scan-shodan-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/scan-shodan-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/schedule-debrief-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/schedule-debrief-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/schedule-dialog-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/schedule-dialog-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/schedule-kickoff-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/schedule-kickoff-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/schedule-kickoff-v1": {
      "filePath": "_internal/i/forms/tasks/$taskId/schedule-kickoff-v1.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/schedule-mgmt-presentation-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/schedule-mgmt-presentation-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/start-initial-scan-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/start-initial-scan-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/test-common-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/test-common-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/test-exotic-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/test-exotic-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/test-ssl-v0": {
      "filePath": "_internal/i/forms/tasks/$taskId/test-ssl-v0.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/upload-dialog-presentation": {
      "filePath": "_internal/i/forms/tasks/$taskId/upload-dialog-presentation.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/upload-kickoff-presentation": {
      "filePath": "_internal/i/forms/tasks/$taskId/upload-kickoff-presentation.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/upload-mgmt-presentation": {
      "filePath": "_internal/i/forms/tasks/$taskId/upload-mgmt-presentation.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/forms/tasks/$taskId/upload-pentest-report": {
      "filePath": "_internal/i/forms/tasks/$taskId/upload-pentest-report.tsx",
      "parent": "/_internal/i"
    },
    "/_internal/i/pentests/$projectId/_pentest/contacts": {
      "filePath": "_internal/i/pentests/$projectId/_pentest/contacts.tsx",
      "parent": "/_internal/i/pentests/$projectId/_pentest"
    },
    "/_internal/i/pentests/$projectId/_pentest/files": {
      "filePath": "_internal/i/pentests/$projectId/_pentest/files.tsx",
      "parent": "/_internal/i/pentests/$projectId/_pentest"
    },
    "/_internal/i/pentests/$projectId/_pentest/notes": {
      "filePath": "_internal/i/pentests/$projectId/_pentest/notes.tsx",
      "parent": "/_internal/i/pentests/$projectId/_pentest"
    },
    "/_links/links/_c/c/pentests/$projectId": {
      "filePath": "_links/links/_c/c/pentests/$projectId.tsx",
      "parent": "/_links/links/_c"
    },
    "/_links/links/_c/c/tasks/$taskId": {
      "filePath": "_links/links/_c/c/tasks/$taskId.tsx",
      "parent": "/_links/links/_c"
    },
    "/_links/links/_i/i/customers/$customerId": {
      "filePath": "_links/links/_i/i/customers/$customerId.tsx",
      "parent": "/_links/links/_i"
    },
    "/_links/links/_i/i/tasks/": {
      "filePath": "_links/links/_i/i/tasks/index.tsx",
      "parent": "/_links/links/_i"
    },
    "/_customer/c/projects/pentests/$projectId/_pentest/contacts": {
      "filePath": "_customer/c/projects/pentests/$projectId/_pentest/contacts.tsx",
      "parent": "/_customer/c/projects/pentests/$projectId/_pentest"
    },
    "/_customer/c/projects/pentests/$projectId/_pentest/files": {
      "filePath": "_customer/c/projects/pentests/$projectId/_pentest/files.tsx",
      "parent": "/_customer/c/projects/pentests/$projectId/_pentest"
    },
    "/_customer/c/projects/pentests/$projectId/_pentest/general": {
      "filePath": "_customer/c/projects/pentests/$projectId/_pentest/general.tsx",
      "parent": "/_customer/c/projects/pentests/$projectId/_pentest"
    },
    "/_customer/c/projects/pentests/$projectId/_pentest/schedule": {
      "filePath": "_customer/c/projects/pentests/$projectId/_pentest/schedule.tsx",
      "parent": "/_customer/c/projects/pentests/$projectId/_pentest"
    },
    "/_internal/i/pentests/$projectId/_pentest/general/edit-ta": {
      "filePath": "_internal/i/pentests/$projectId/_pentest/general/edit-ta.tsx",
      "parent": "/_internal/i/pentests/$projectId/_pentest"
    },
    "/_internal/i/pentests/$projectId/_pentest/schedule/change-debrief-date": {
      "filePath": "_internal/i/pentests/$projectId/_pentest/schedule/change-debrief-date.tsx",
      "parent": "/_internal/i/pentests/$projectId/_pentest"
    },
    "/_internal/i/pentests/$projectId/_pentest/schedule/change-dialog-date": {
      "filePath": "_internal/i/pentests/$projectId/_pentest/schedule/change-dialog-date.tsx",
      "parent": "/_internal/i/pentests/$projectId/_pentest"
    },
    "/_internal/i/pentests/$projectId/_pentest/schedule/change-kickoff-date": {
      "filePath": "_internal/i/pentests/$projectId/_pentest/schedule/change-kickoff-date.tsx",
      "parent": "/_internal/i/pentests/$projectId/_pentest"
    },
    "/_internal/i/pentests/$projectId/_pentest/schedule/change-mgmt-date": {
      "filePath": "_internal/i/pentests/$projectId/_pentest/schedule/change-mgmt-date.tsx",
      "parent": "/_internal/i/pentests/$projectId/_pentest"
    },
    "/_links/links/_i/i/pentests/$projectId/targets": {
      "filePath": "_links/links/_i/i/pentests/$projectId/targets.tsx",
      "parent": "/_links/links/_i"
    },
    "/_internal/i/pentests/$projectId/_pentest/general/": {
      "filePath": "_internal/i/pentests/$projectId/_pentest/general/index.tsx",
      "parent": "/_internal/i/pentests/$projectId/_pentest"
    },
    "/_internal/i/pentests/$projectId/_pentest/schedule/": {
      "filePath": "_internal/i/pentests/$projectId/_pentest/schedule/index.tsx",
      "parent": "/_internal/i/pentests/$projectId/_pentest"
    },
    "/_links/links/_i/i/pentests/$projectId/": {
      "filePath": "_links/links/_i/i/pentests/$projectId/index.tsx",
      "parent": "/_links/links/_i"
    },
    "/_links/links/_i/i/pentests/$projectId/questionnaires/$questionnaireId": {
      "filePath": "_links/links/_i/i/pentests/$projectId/questionnaires/$questionnaireId.tsx",
      "parent": "/_links/links/_i"
    }
  }
}
ROUTE_MANIFEST_END */
