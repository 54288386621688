import React, { useState } from "react";
import { EditQuestionProps } from "src/components/internal/edit-question/edit-question";
import { useTranslation } from "react-i18next";
import { useForm, useStore } from "@tanstack/react-form";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { SimpleQuestionChoice } from "src/api/generated";
import LayoutEditQuestion from "src/components/internal/edit-question/layout-edit-question";
import { Button } from "src/components/base/button";
import Question from "src/components/questionnaire/question";
import { Description, Field, FieldGroup, Fieldset, Label, RequiredLabel } from "src/components/base/fieldset";
import { Divider } from "src/components/base/divider";
import { Input } from "src/components/base/input";
import { Textarea } from "src/components/base/textarea";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { ChevronDownIcon, ChevronUpIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid";
import BlockUnsavedChanges from "src/components/base/unsaved-changes";
import DialogEditChoice from "src/components/dialogs/internal/dialog-edit-choice";
import Form from "src/components/base/form";
import Console from "src/utils/console";

/**
 * The properties for {@link EditQuestionMultipleChoices}
 */
export type EditQuestionMultipleChoicesProps = EditQuestionProps<"MultipleChoices">;

/**
 * Edit question of type Multiple choices
 */
export default function EditQuestionMultipleChoices(props: EditQuestionMultipleChoicesProps) {
    const [t] = useTranslation();
    const { question, data } = props;

    const editForm = useForm({
        defaultValues: {
            name: question.name,
            notes: question.notes,
            title: question.title,
            description: question.description,
            choices: data.choices,
        },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            const res = await Api.internal.questionnaires.questions.update(question.uuid, {
                name: value.name,
                notes: value.notes,
                title: value.title,
                description: value.description,
                data: { kind: "MultipleChoices", choices: value.choices.map((choice) => choice.uuid) },
            });

            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        toast.success(t("toast.saved-changes"));
                        props.reloadQuestion().then(() => formApi.reset());
                    } else {
                        if (res.error.name) {
                            formApi.setFieldMeta("name", (meta) => {
                                meta.errors.push(t("error.name-in-use"));
                                return meta;
                            });
                        } else {
                            Console.log(res.error);
                        }
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });
    const isDirty = useStore(editForm.store, (state) => state.isDirty);

    const [newChoiceTitle, setNewChoiceTitle] = useState<string>("");
    const [newChoiceDescription, setNewChoiceDescription] = useState<string>("");

    const [editChoice, setEditChoice] = useState<SimpleQuestionChoice>();

    return (
        <LayoutEditQuestion
            headingChildren={
                <div className={"flex gap-6"}>
                    <Button plain={true} disabled={!isDirty} onClick={() => editForm.reset()}>
                        {t("button.reset")}
                    </Button>
                    <Button color={"blue"} disabled={!isDirty} onClick={editForm.handleSubmit}>
                        {t("button.save")}
                    </Button>
                </div>
            }
        >
            <editForm.Subscribe>
                {({ values }) => (
                    <Question
                        disabled={true}
                        question={{
                            uuid: question.uuid,
                            name: values.name,
                            notes: values.notes,
                            title: values.title,
                            description: values.description,
                            created: question.created,
                            data: { kind: "MultipleChoices", choices: values.choices },
                        }}
                    />
                )}
            </editForm.Subscribe>

            <Fieldset>
                <FieldGroup className={"row-span-2"}>
                    <Divider />

                    <editForm.Field name={"name"}>
                        {(fieldApi) => (
                            <Field className={"grid lg:grid-cols-[1fr_2fr]"}>
                                <span>
                                    <Label>{t("label.internal-name")}</Label>
                                    <Description>{t("description.internal-name")}</Description>
                                </span>
                                <Input
                                    className={"mt-3 place-self-start lg:mt-0"}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                            </Field>
                        )}
                    </editForm.Field>
                    <editForm.Field name={"notes"}>
                        {(fieldApi) => (
                            <Field className={"grid lg:grid-cols-[1fr_2fr]"}>
                                <span>
                                    <Label>{t("label.internal-notes")}</Label>
                                    <Description>{t("description.internal-notes")}</Description>
                                </span>
                                <Textarea
                                    className={"place-self-start"}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                            </Field>
                        )}
                    </editForm.Field>

                    <Divider soft={true} />

                    <editForm.Field name={"title"}>
                        {(fieldApi) => (
                            <Field className={"grid lg:grid-cols-[1fr_2fr]"}>
                                <Label>{t("label.questionnaire-title")}</Label>
                                <Input
                                    className={"place-self-start"}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                            </Field>
                        )}
                    </editForm.Field>
                    <editForm.Field name={"description"}>
                        {(fieldApi) => (
                            <Field className={"grid lg:grid-cols-[1fr_2fr]"}>
                                <Label>{t("label.questionnaire-description")}</Label>
                                <Textarea
                                    className={"place-self-start"}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                            </Field>
                        )}
                    </editForm.Field>

                    <editForm.Field name={"choices"}>
                        {(fieldApi) => (
                            <Field className={"grid lg:grid-cols-[1fr_2fr]"}>
                                <Label>{t("label.questionnaire-choices")}</Label>
                                <Form
                                    onSubmit={() => {
                                        Api.internal.questionnaires.questions
                                            .createChoice(question.uuid, {
                                                title: newChoiceTitle,
                                                description: newChoiceDescription,
                                            })
                                            .then((res) =>
                                                res.match(
                                                    (uuid) => {
                                                        props.reloadQuestion().then(() => {
                                                            fieldApi.handleChange([
                                                                ...fieldApi.state.value,
                                                                {
                                                                    uuid: uuid.uuid,
                                                                    title: newChoiceTitle,
                                                                    description: newChoiceDescription,
                                                                },
                                                            ]);
                                                            setNewChoiceTitle("");
                                                            setNewChoiceDescription("");
                                                        });
                                                    },
                                                    (err) => toast.error(err.message),
                                                ),
                                            );
                                    }}
                                >
                                    <Table dense={true} className={"mt-3 lg:mt-0"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableHeader>{t("label.title")}</TableHeader>
                                                <TableHeader className={"max-lg:hidden"}>
                                                    {t("label.description")}
                                                </TableHeader>
                                                <TableHeader className={"w-0"}>
                                                    <span className={"sr-only"}>{t("accessibility.actions")}</span>
                                                </TableHeader>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {fieldApi.state.value.map((choice, idx) => (
                                                <TableRow key={choice.uuid}>
                                                    <TableCell>{choice.title}</TableCell>
                                                    <TableCell className={"overflow-ellipsis max-lg:hidden"}>
                                                        {choice.description}
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className={"flex justify-end"}>
                                                            {idx !== 0 && (
                                                                <Button
                                                                    plain={true}
                                                                    onClick={() => {
                                                                        const choices = [
                                                                            ...fieldApi.state.value.slice(0, idx - 1),
                                                                            choice,
                                                                            fieldApi.state.value[idx - 1],
                                                                            ...fieldApi.state.value.slice(idx + 1),
                                                                        ];

                                                                        fieldApi.handleChange(choices);
                                                                    }}
                                                                >
                                                                    <ChevronUpIcon />
                                                                </Button>
                                                            )}
                                                            {idx !== fieldApi.state.value.length - 1 && (
                                                                <Button
                                                                    plain={true}
                                                                    onClick={() => {
                                                                        const choices = [
                                                                            ...fieldApi.state.value.slice(0, idx),
                                                                            fieldApi.state.value[idx + 1],
                                                                            choice,
                                                                            ...fieldApi.state.value.slice(idx + 2),
                                                                        ];

                                                                        fieldApi.handleChange(choices);
                                                                    }}
                                                                >
                                                                    <ChevronDownIcon />
                                                                </Button>
                                                            )}
                                                            <Button plain={true} onClick={() => setEditChoice(choice)}>
                                                                <PencilSquareIcon />
                                                            </Button>
                                                            <Button
                                                                plain={true}
                                                                onClick={() => {
                                                                    Api.internal.questionnaires.questions
                                                                        .deleteChoice(choice.uuid)
                                                                        .then((res) =>
                                                                            res.match(
                                                                                (res) => {
                                                                                    if (res.result === "Ok") {
                                                                                        const choices = [
                                                                                            ...fieldApi.state.value,
                                                                                        ];
                                                                                        choices.splice(idx, 1);
                                                                                        fieldApi.handleChange(choices);
                                                                                        props.reloadQuestion().then();
                                                                                    } else {
                                                                                        if (res.error.already_chosen) {
                                                                                            toast.error(
                                                                                                t(
                                                                                                    "internal.edit-questionnaire-questions.error.choice-already-chosen",
                                                                                                ),
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                },
                                                                                (err) => toast.error(err.message),
                                                                            ),
                                                                        );
                                                                }}
                                                            >
                                                                <TrashIcon />
                                                            </Button>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow key={"create"} className={"max-lg:hidden"}>
                                                <TableCell>
                                                    <Input
                                                        required={true}
                                                        value={newChoiceTitle}
                                                        onChange={(e) => setNewChoiceTitle(e.target.value)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Input
                                                        value={newChoiceDescription}
                                                        onChange={(e) => setNewChoiceDescription(e.target.value)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <div className={"flex justify-center"}>
                                                        <Button type={"submit"}>{t("button.create")}</Button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>

                                    <Fieldset className={"mt-12 lg:hidden"}>
                                        <FieldGroup>
                                            <Field>
                                                <RequiredLabel>{t("label.title")}</RequiredLabel>
                                                <Input
                                                    required={true}
                                                    value={newChoiceTitle}
                                                    onChange={(e) => setNewChoiceTitle(e.target.value)}
                                                />
                                            </Field>

                                            <Field>
                                                <Label>{t("label.description")}</Label>
                                                <Input
                                                    value={newChoiceDescription}
                                                    onChange={(e) => setNewChoiceDescription(e.target.value)}
                                                />
                                            </Field>

                                            <Button type={"submit"}>{t("button.create")}</Button>
                                        </FieldGroup>
                                    </Fieldset>
                                </Form>
                            </Field>
                        )}
                    </editForm.Field>
                </FieldGroup>
            </Fieldset>

            <BlockUnsavedChanges condition={isDirty} />

            <editForm.Field name={"choices"}>
                {(fieldApi) => {
                    return (
                        editChoice && (
                            <DialogEditChoice
                                open={true}
                                onClose={() => setEditChoice(undefined)}
                                choice={editChoice}
                                onFormSubmit={(c: SimpleQuestionChoice) => {
                                    const choices = fieldApi.state.value.map((choice) =>
                                        choice.uuid === c.uuid ? c : choice,
                                    );

                                    fieldApi.handleChange(choices);
                                    setEditChoice(undefined);
                                }}
                            />
                        )
                    );
                }}
            </editForm.Field>
        </LayoutEditQuestion>
    );
}
