import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import USER_CONTEXT from "src/context/user";
import { Button } from "src/components/base/button";
import {
    ComputerDesktopIcon,
    EyeIcon,
    EyeSlashIcon,
    MoonIcon,
    PencilSquareIcon,
    SunIcon,
    TrashIcon,
} from "@heroicons/react/20/solid";
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from "src/components/base/dropdown";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "src/components/base/dialog";
import { Input } from "src/components/base/input";
import {
    Description,
    ErrorMessage,
    Field,
    FieldGroup,
    Fieldset,
    Label,
    Legend,
    RequiredLabel,
} from "src/components/base/fieldset";
import Form from "src/components/base/form";
import { Divider } from "src/components/base/divider";
import LanguageSelect from "src/components/base/language-select";
import { useForm, useStore } from "@tanstack/react-form";
import { Subheading } from "src/components/base/heading";
import FileInput from "src/components/base/file-input";
import TabLayout from "src/components/base/tab-layout";
import { Tab, TabMenu } from "src/components/base/tab-menu";
import { Outlet } from "@tanstack/react-router";
import { Text } from "src/components/base/text";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { SimpleTotpKey, SimpleWebAuthnKey } from "src/api/generated";
import { Listbox, ListboxLabel, ListboxOption } from "src/components/base/listbox";
import UserAvatar from "src/components/user-avatar";
import DialogAddTotp from "src/components/dialogs/dialog-add-totp";
import { registerKey } from "src/api/auth";
import DialogSudoMode from "src/components/dialogs/dialog-sudo-mode";
import dayjs from "dayjs";
import { zxcvbn } from "@zxcvbn-ts/core";
import PasswordStrength from "src/components/base/pw-strength";
import DialogMissingDevice from "src/components/dialogs/dialog-missing-device";

/**
 * The properties for {@link ProfileLayout}
 */
export type ProfileProps = {};

/**
 * The profile
 */
export function ProfileLayout(props: ProfileProps) {
    const [t] = useTranslation();
    const { user } = React.useContext(USER_CONTEXT);

    return (
        <TabLayout
            heading={t("profile.heading.profile")}
            headingDescription={t("profile.description.profile")}
            tabs={
                <TabMenu>
                    <Tab
                        href={
                            user.permissions.role === "Internal"
                                ? "/i/profile/general"
                                : user.permissions.role === "Customer"
                                  ? "/c/profile/general"
                                  : "/a/profile/general"
                        }
                    >
                        {t("profile.label.general")}
                    </Tab>
                    <Tab
                        href={
                            user.permissions.role === "Internal"
                                ? "/i/profile/security"
                                : user.permissions.role === "Customer"
                                  ? "/c/profile/security"
                                  : "/a/profile/security"
                        }
                    >
                        {t("profile.label.security")}
                    </Tab>
                </TabMenu>
            }
        >
            <Outlet />
        </TabLayout>
    );
}

/**
 * The properties for {@link GeneralProfile}
 */
export type GeneralProfileProps = {};

/**
 * The general profile
 */
export function GeneralProfile(props: GeneralProfileProps) {
    const [t, i18n] = useTranslation();
    const { user, reset } = React.useContext(USER_CONTEXT);

    const theme = localStorage.getItem("theme");

    const profileForm = useForm({
        defaultValues: {
            mail: user.mail,
            phone: user.phone,
            display_name: user.display_name,
            preferredLanguage: user.preferred_lang,
            theme: theme ? theme : "system",
            matrixUsername: user.matrix_name,
        },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            let res;
            if (user.permissions.role === "Internal") {
                res = await Api.internal.users.me.update({
                    mail: value.mail,
                    phone: value.phone,
                    display_name: value.display_name,
                    preferred_lang: value.preferredLanguage,
                    matrix: value.matrixUsername,
                });
            } else if (user.permissions.role === "Customer") {
                res = await Api.customer.users.me.update({
                    mail: value.mail,
                    phone: value.phone,
                    display_name: value.display_name,
                    preferred_lang: value.preferredLanguage,
                });
            } else {
                res = await Api.admin.users.me.update({
                    mail: value.mail,
                    phone: value.phone,
                    display_name: value.display_name,
                    preferred_lang: value.preferredLanguage,
                });
            }

            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        i18n.changeLanguage(value.preferredLanguage.toLowerCase());
                        dayjs.locale(value.preferredLanguage.toLocaleLowerCase());
                        if (value.theme === "system") {
                            localStorage.removeItem("theme");
                        } else if (value.theme === "light") {
                            localStorage.setItem("theme", "light");
                        } else {
                            localStorage.setItem("theme", "dark");
                        }

                        if (
                            localStorage.theme === "dark" ||
                            (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)
                        ) {
                            document.documentElement.classList.add("dark");
                        } else {
                            document.documentElement.classList.remove("dark");
                        }

                        formApi.reset();
                        reset();
                    } else {
                        if (res.error.mail) {
                            formApi.setFieldMeta("mail", (meta) => {
                                meta.errors.push(t("profile.error.mail-in-use"));
                                return meta;
                            });
                        }

                        if ("matrix" in res.error) {
                            formApi.setFieldMeta("matrixUsername", (meta) => {
                                meta.errors.push(t("profile.error.invalid-matrix-username"));
                                return meta;
                            });
                        }
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });
    const profileFormDirty = useStore(profileForm.store, (store) => store.isDirty);

    const [openEditProfileImage, setOpenEditProfileImage] = React.useState(false);

    // Upload profile image
    const [profileImage, setProfileImage] = React.useState<Blob>();

    /**
     * Delete the profile image of the user
     */
    const deleteProfileImage = async () => {
        const res = await Api.common.user.profileImage.delete();

        res.match(
            () => reset(),
            (err) => toast.error(err.message),
        );
    };

    /**
     * Test the current matrix configuration
     */
    const testMatrix = async () => {
        const id = toast.loading(t("profile.toast.matrix-test-loading"));

        const res = await Api.internal.users.me.testMatrix();

        res.match(
            (res) => {
                if (res.result === "Ok") {
                    toast.update(id, {
                        type: "success",
                        autoClose: 2500,
                        isLoading: false,
                        render: t("profile.toast.matrix-test-ok"),
                    });
                } else {
                    if (res.error.no_profile) {
                        toast.update(id, {
                            type: "error",
                            autoClose: 2500,
                            isLoading: false,
                            render: t("profile.error.matrix-check-username"),
                        });
                    }
                    if (res.error.unavailable || res.error.matrix_error) {
                        toast.update(id, {
                            type: "error",
                            autoClose: 2500,
                            isLoading: false,
                            render: t("profile.error.matrix-unavailable"),
                        });
                    }
                    if (res.error.no_user) {
                        toast.update(id, {
                            type: "error",
                            autoClose: 2500,
                            isLoading: false,
                            render: t("profile.error.matrix-not-saved"),
                        });
                    }
                }
            },
            (err) =>
                toast.update(id, {
                    type: "error",
                    autoClose: 2500,
                    isLoading: false,
                    render: err.message,
                }),
        );
    };

    return (
        <>
            <div className={"flex flex-col-reverse justify-between gap-6 md:flex-row md:gap-20"}>
                <Form onSubmit={profileForm.handleSubmit} className={"w-full"}>
                    <Fieldset className={"flex flex-col gap-8"}>
                        <div
                            data-slot="control"
                            className="grid w-full grid-cols-1 items-center gap-x-4 gap-y-6 sm:grid-cols-2"
                        >
                            <profileForm.Field name={"display_name"}>
                                {(fieldApi) => (
                                    <Field
                                        className="grid grid-cols-[subgrid] gap-3 sm:col-span-2"
                                        disabled={user.permissions.role === "Internal"}
                                    >
                                        <Label>{t("label.name")}</Label>
                                        <Input
                                            type={"text"}
                                            value={fieldApi.state.value}
                                            onChange={(e) => fieldApi.handleChange(e.target.value)}
                                        />
                                    </Field>
                                )}
                            </profileForm.Field>

                            <profileForm.Field name={"mail"}>
                                {(fieldApi) => (
                                    <Field
                                        className="grid grid-cols-[subgrid] gap-3 sm:col-span-2"
                                        disabled={user.permissions.role === "Internal"}
                                    >
                                        <Label>{t("label.mail")}</Label>
                                        <span>
                                            <Input
                                                type={"email"}
                                                value={fieldApi.state.value}
                                                invalid={fieldApi.state.meta.errors.length > 0}
                                                onChange={(e) => fieldApi.handleChange(e.target.value)}
                                            />
                                            {fieldApi.state.meta.errors.map((err) => (
                                                <ErrorMessage>{err}</ErrorMessage>
                                            ))}
                                        </span>
                                    </Field>
                                )}
                            </profileForm.Field>

                            <profileForm.Field name={"phone"}>
                                {(fieldApi) => (
                                    <Field className="grid grid-cols-[subgrid] gap-3 sm:col-span-2">
                                        <Label>{t("label.phone")}</Label>
                                        <span>
                                            <Input
                                                type={"tel"}
                                                value={fieldApi.state.value}
                                                invalid={fieldApi.state.meta.errors.length > 0}
                                                onChange={(e) => fieldApi.handleChange(e.target.value)}
                                            />
                                            {fieldApi.state.meta.errors.map((err) => (
                                                <ErrorMessage>{err}</ErrorMessage>
                                            ))}
                                        </span>
                                    </Field>
                                )}
                            </profileForm.Field>
                        </div>

                        {user.permissions.role === "Internal" && (
                            <>
                                <Divider soft={true} />

                                <div className="grid w-full grid-cols-1 items-center gap-x-4 gap-y-6 sm:grid-cols-2">
                                    <profileForm.Field name={"matrixUsername"}>
                                        {(fieldApi) => (
                                            <Field className="grid grid-cols-[subgrid] gap-3 sm:col-span-2">
                                                <div>
                                                    <Label>{t("label.matrix-name")}</Label>
                                                    <Text>{t("description.matrix")} </Text>
                                                </div>
                                                <div className={"flex items-start gap-3"}>
                                                    <div className={"flex w-full flex-col gap-3"}>
                                                        <Input
                                                            value={fieldApi.state.value}
                                                            onChange={(e) => fieldApi.handleChange(e.target.value)}
                                                            invalid={fieldApi.state.meta.errors.length > 0}
                                                        />
                                                        {fieldApi.state.meta.errors.map((err) => (
                                                            <ErrorMessage>{err}</ErrorMessage>
                                                        ))}
                                                    </div>
                                                    <Button
                                                        disabled={profileFormDirty}
                                                        className={"h-9"}
                                                        onClick={async () => testMatrix()}
                                                    >
                                                        {t("button.test")}
                                                    </Button>
                                                </div>
                                            </Field>
                                        )}
                                    </profileForm.Field>
                                </div>
                            </>
                        )}

                        <Divider soft={true} />
                        <div className="grid w-full grid-cols-1 items-center gap-x-4 gap-y-6 sm:grid-cols-2">
                            <profileForm.Field name={"preferredLanguage"}>
                                {(fieldApi) => (
                                    <Field className="grid grid-cols-[subgrid] gap-3 sm:col-span-2">
                                        <Label>{t("label.preferred-lang")}</Label>
                                        <LanguageSelect lang={fieldApi.state.value} setLang={fieldApi.handleChange} />
                                    </Field>
                                )}
                            </profileForm.Field>
                        </div>

                        <Divider soft={true} />
                        <div className="grid w-full grid-cols-1 items-center gap-x-4 gap-y-6 sm:grid-cols-2">
                            <profileForm.Field name={"theme"}>
                                {(fieldApi) => (
                                    <Field className="grid grid-cols-[subgrid] gap-3 sm:col-span-2">
                                        <Label>{t("label.theme")}</Label>
                                        <Listbox value={fieldApi.state.value} onChange={fieldApi.handleChange}>
                                            <ListboxOption value={"system"}>
                                                <ComputerDesktopIcon />
                                                <ListboxLabel>{t("label.theme-system")}</ListboxLabel>
                                            </ListboxOption>
                                            <ListboxOption value={"light"}>
                                                <SunIcon />
                                                <ListboxLabel>{t("label.theme-light")}</ListboxLabel>
                                            </ListboxOption>
                                            <ListboxOption value={"dark"}>
                                                <MoonIcon />
                                                <ListboxLabel>{t("label.theme-dark")}</ListboxLabel>
                                            </ListboxOption>
                                        </Listbox>
                                    </Field>
                                )}
                            </profileForm.Field>
                        </div>

                        <Divider soft={true} />

                        <div className={"flex justify-end gap-6"}>
                            <Button plain={true} disabled={!profileFormDirty} onClick={() => profileForm.reset()}>
                                {t("button.reset")}
                            </Button>
                            <Button type={"submit"} color={"blue"} disabled={!profileFormDirty}>
                                {t("button.save")}
                            </Button>
                        </div>
                    </Fieldset>
                </Form>

                <div className={"flex w-full flex-col items-center gap-3 md:w-fit"}>
                    <Subheading>{t("profile.heading.profile-image")}</Subheading>
                    <UserAvatar className={"size-24 md:size-40"} />
                    <Dropdown>
                        <DropdownButton plain={true}>
                            <PencilSquareIcon />
                            <span>{t("button.edit")}</span>
                        </DropdownButton>
                        <DropdownMenu anchor={"bottom end"}>
                            <DropdownItem onClick={() => setOpenEditProfileImage(true)}>
                                <DropdownLabel>{t("button.edit")}</DropdownLabel>
                            </DropdownItem>
                            {user.has_profile_image && (
                                <DropdownItem onClick={deleteProfileImage}>
                                    <DropdownLabel>{t("button.delete")}</DropdownLabel>
                                </DropdownItem>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>

            {openEditProfileImage && (
                <Dialog open={true} onClose={() => setOpenEditProfileImage(false)}>
                    <Form
                        onSubmit={() => {
                            profileImage &&
                                Api.common.user.profileImage.upload(profileImage).then((res) =>
                                    res.match(
                                        () => {
                                            setOpenEditProfileImage(false);
                                            reset();
                                        },
                                        (err) => toast.error(err.message),
                                    ),
                                );
                        }}
                    >
                        <DialogTitle></DialogTitle>
                        <DialogBody>
                            <Field>
                                <RequiredLabel>{t("label.select-file")}</RequiredLabel>
                                <FileInput
                                    required={true}
                                    accept={"image/*"}
                                    onChange={(e) => setProfileImage(e.target.files ? e.target.files[0] : undefined)}
                                />
                            </Field>
                        </DialogBody>
                        <DialogActions>
                            <Button type={"submit"} color={"blue"}>
                                {t("profile.button.set-profile-image")}
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            )}
        </>
    );
}

/**
 * The properties for {@link SecurityProfile}
 */
export type SecurityProfileProps = {};

/**
 * The profile settings for security related options
 */
export default function SecurityProfile(props: SecurityProfileProps) {
    const [t] = useTranslation();
    const { user } = React.useContext(USER_CONTEXT);

    const [openSudoMode, setOpenSudoMode] = useState(false);

    const [passkeys, setPasskeys] = React.useState<Array<SimpleWebAuthnKey>>([]);
    const [totpApps, setTotpApps] = React.useState<Array<SimpleTotpKey>>([]);

    const [totpLabel, setTotpLabel] = React.useState("");
    const [openAddTotp, setOpenAddTotp] = React.useState(false);

    const [pklabel, setPklabel] = React.useState("");
    const [mfaPkLabel, setMfaPkLabel] = React.useState("");

    const [action, setAction] = React.useState<Promise<void>>();

    const [openMissingDevice, setOpenMissingDevice] = React.useState(false);

    /**
     * Refresh the current sudo state
     */
    const refreshSudoStatus = async () => {
        const res = await Api.common.sudoMode.status();

        res.match(
            (state) => {
                setOpenSudoMode(state !== "On");
            },
            (err) => toast.error(err.message),
        );
    };

    /**
     * Helper function to update the passkeys of the user
     */
    const refreshPasskeys = async () => {
        const res = await Api.common.user.webauthn.all();
        res.match(
            (list) => setPasskeys(list.list),
            (err) => toast.error(err.message),
        );
    };

    /**
     * Helper function to update the totp apps of the user
     */
    const refreshTotpApps = async () => {
        const res = await Api.common.user.totp.all();
        res.match(
            (list) => setTotpApps(list.list),
            (err) => toast.error(err.message),
        );
    };

    /**
     *  Function to register a new key for password-less authentication
     */
    const setKey = async () => {
        const res = await registerKey("user", {
            label: pklabel,
            canLogin: true,
        });

        res.match(
            (res) => {
                res.match(
                    (ok) => {
                        if (ok.result === "MissingDevice") {
                            setOpenMissingDevice(true);
                        } else if (ok.result === "RejectedDevice") {
                            toast.error(t("toast.rejected-device"));
                        } else if (ok.result === "Ok") {
                            toast.success(t("toast.set-security-key"));
                            setPklabel("");
                        }
                    },
                    (err) => {
                        if (err === "missing-sudo" && !openSudoMode) {
                            setAction(setKey);
                            setOpenSudoMode(true);
                        }
                    },
                );
                refreshPasskeys().then();
            },
            (err) => toast.error(err.message),
        );
    };

    /**
     *  Function to register a new key as Multi-factor
     */
    const setMfaKey = async () => {
        const res = await registerKey("user", {
            label: mfaPkLabel,
            canLogin: false,
        });

        res.match(
            (res) => {
                res.match(
                    () => {
                        setMfaPkLabel("");
                        toast.success(t("toast.set-mfa"));
                    },
                    (err) => {
                        if (err === "missing-sudo" && !openSudoMode) {
                            setAction(setMfaKey);
                            setOpenSudoMode(true);
                        }
                    },
                );
                refreshPasskeys().then();
            },
            (err) => toast.error(err.message),
        );
    };

    /**
     *  Function to delete a passkey
     *
     * @param key passkey to delete
     */
    const deleteKey = async (key: SimpleWebAuthnKey) => {
        const res = await Api.common.user.webauthn.delete(key.uuid);
        res.match(
            (res) => {
                if (res.result === "Ok") {
                    toast.success(t("toast.deleted-pk"));
                    refreshPasskeys().then();
                } else if (res.error.missing_sudo && !openSudoMode) {
                    setAction(async () => {
                        await deleteKey(key);
                    });
                    setOpenSudoMode(true);
                }
            },
            (err) => {
                toast.error(err.message);
            },
        );
    };

    /**
     *  Function to delete a totp app
     *
     * @param app App to delete
     */
    const deleteTotp = async (app: SimpleTotpKey) => {
        const res = await Api.common.user.totp.delete(app.uuid);
        res.match(
            (res) => {
                if (res.result === "Ok") {
                    toast.success(t("toast.deleted-totp"));
                    refreshTotpApps().then();
                } else if (res.error.missing_sudo && !openSudoMode) {
                    setAction(async () => await deleteTotp(app));
                    setOpenSudoMode(true);
                }
            },
            (err) => {
                toast.error(err.message);
            },
        );
    };

    const setPassword = useForm({
        defaultValues: {
            password: "",
            password2: "",
            showPassword: false,
        },
        /**
         * Api call to set new Password
         *
         * @param root0 value
         * @param root0.value values put in default value
         * @param root0.formApi form state
         */
        onSubmit: async ({ value, formApi }) => {
            /**
             * Set the password
             */
            const setPassword = async () => {
                const res = await Api.common.user.setPassword(value.password);
                res.match(
                    (res) => {
                        if (res.result === "Ok") {
                            toast.success(t("toast.set-pw"));
                            formApi.reset();
                        } else if (res.error.missing_sudo && !openSudoMode) {
                            setAction(setPassword);
                            setOpenSudoMode(true);
                        }
                    },
                    (err) => toast.error(err.message),
                );
            };

            await setPassword();
        },
    });
    const showPassword = useStore(setPassword.store, (state) => state.values.showPassword);

    useEffect(() => {
        if (user.is_local) {
            refreshPasskeys().then();
            refreshTotpApps().then();
            refreshSudoStatus().then();
        }
    }, []);

    if (!user.is_local) {
        return <Text>{t("profile.label.nothing-todo-here")}</Text>;
    }

    const pass_less = passkeys.filter((x) => x.can_login);
    const mfa_pk = passkeys.filter((x) => !x.can_login);

    return (
        <div className={"flex flex-col gap-3"}>
            <Subheading>{t("profile.heading.password-less")}</Subheading>
            <Text>{t("profile.description.password-less")}</Text>

            <Form className={"max-w-sm"} onSubmit={setKey}>
                <Fieldset>
                    <FieldGroup>
                        <Field>
                            <Label>{t("label.label")}</Label>
                            <Input
                                value={pklabel}
                                onChange={(e) => setPklabel(e.target.value)}
                                required={true}
                                type={"text"}
                            />
                        </Field>

                        <Button type={"submit"}>{t("button.register-security-key")}</Button>
                    </FieldGroup>
                </Fieldset>
            </Form>

            {pass_less.length > 0 && (
                <>
                    <Subheading className={"mt-6"}>{t("profile.heading.existing-security-keys")}</Subheading>

                    <Table dense={true}>
                        <TableHead>
                            <TableRow>
                                <TableHeader>{t("label.label")}</TableHeader>
                                <TableHeader>{t("label.created-at")}</TableHeader>
                                <TableHeader className={"w-0"}>
                                    <span className={"sr-only"}>{t("accessibility.actions")}</span>
                                </TableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pass_less.map((pk) => (
                                <TableRow key={pk.uuid}>
                                    <TableCell>{pk.label}</TableCell>
                                    <TableCell>{new Date(pk.created_at).toLocaleDateString()}</TableCell>
                                    <TableCell>
                                        <Button
                                            plain={true}
                                            onClick={() => {
                                                deleteKey(pk).then();
                                            }}
                                        >
                                            <TrashIcon />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}

            <Divider className={"my-3"} />

            <Subheading>{t("profile.heading.password")}</Subheading>
            <Text>{t("profile.description.password")}</Text>
            <Form onSubmit={setPassword.handleSubmit} className={"max-w-sm"}>
                <Fieldset>
                    <FieldGroup>
                        <setPassword.Field
                            name={"password"}
                            validators={{
                                // eslint-disable-next-line
                                onChange: ({ value }) => {
                                    if (value !== "" && zxcvbn(value).score < 4) {
                                        return t("profile.text.stronger-password");
                                    }
                                    return undefined;
                                },
                            }}
                        >
                            {(field) => (
                                <Field>
                                    <div className={"mt-3 flex gap-3"}>
                                        <Input
                                            autoComplete={"new-password"}
                                            value={field.state.value}
                                            placeholder={t("profile.description.new-pw")}
                                            onChange={(e) => field.handleChange(e.target.value)}
                                            type={showPassword ? "text" : "password"}
                                            invalid={field.state.meta.errors.length !== 0}
                                            onBlur={field.handleBlur}
                                            required={true}
                                        />
                                        <setPassword.Field name={"showPassword"}>
                                            {(field) => (
                                                <Button
                                                    plain={true}
                                                    onClick={() => field.handleChange((prev) => !prev)}
                                                >
                                                    {field.state.value ? <EyeSlashIcon /> : <EyeIcon />}
                                                </Button>
                                            )}
                                        </setPassword.Field>
                                    </div>
                                    {field.state.meta.errors.map((error) => (
                                        <ErrorMessage className={"mt-3"}>{error}</ErrorMessage>
                                    ))}

                                    <Suspense fallback={<div></div>}>
                                        <PasswordStrength password={field.state.value} />
                                    </Suspense>
                                </Field>
                            )}
                        </setPassword.Field>
                        <setPassword.Field
                            name={"password2"}
                            validators={{
                                onChangeListenTo: ["password"],
                                // eslint-disable-next-line
                                onChange: ({ value, fieldApi }) => {
                                    if (
                                        fieldApi.getMeta().isDirty &&
                                        value !== fieldApi.form.getFieldValue("password")
                                    ) {
                                        return t("profile.text.password-mismatch");
                                    }
                                    return undefined;
                                },
                            }}
                        >
                            {(field) => (
                                <Field className={"mr-12"}>
                                    <Input
                                        required={true}
                                        type={"password"}
                                        value={field.state.value}
                                        placeholder={t("label.reenter-password")}
                                        invalid={field.state.meta.errors.length !== 0}
                                        onBlur={field.handleBlur}
                                        onChange={(e) => field.handleChange(e.target.value)}
                                    />
                                    {field.state.meta.errors.map((error) => (
                                        <ErrorMessage>{error}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </setPassword.Field>

                        <setPassword.Subscribe
                            selector={(state) => [state.canSubmit]}
                            children={([canSubmit]) => (
                                <Button
                                    color={"blue"}
                                    className={"place-self-start"}
                                    type={"submit"}
                                    disabled={!canSubmit}
                                >
                                    {t("button.set-password")}
                                </Button>
                            )}
                        />
                    </FieldGroup>
                </Fieldset>
            </Form>

            <Divider className={"my-3"} />

            <Subheading>{t("profile.heading.mfa")}</Subheading>
            <Text>{t("profile.description.mfa")}</Text>
            <div className={"mt-6 grid gap-12 lg:grid-cols-2"}>
                <div className={"flex flex-col gap-3"}>
                    <Form onSubmit={setMfaKey}>
                        <Fieldset>
                            <Legend>{t("profile.heading.webauthn")}</Legend>
                            <FieldGroup>
                                <Field>
                                    <Label>{t("label.label")}</Label>
                                    <Description>{t("description.label-security-key")}</Description>
                                    <Input
                                        autoComplete={"webauthn"}
                                        required={true}
                                        value={mfaPkLabel}
                                        onChange={(ev) => setMfaPkLabel(ev.target.value)}
                                    />
                                </Field>

                                <Button type={"submit"} className={"w-full"}>
                                    {t("button.register-security-key")}
                                </Button>
                            </FieldGroup>
                        </Fieldset>
                    </Form>

                    {mfa_pk.length > 0 && (
                        <>
                            <Subheading className={"mt-6"}>{t("profile.heading.existing-security-keys")}</Subheading>
                            <Table dense={true}>
                                <TableHead>
                                    <TableRow>
                                        <TableHeader>{t("label.label")}</TableHeader>
                                        <TableHeader>{t("label.created-at")}</TableHeader>
                                        <TableHeader className={"w-0"}>
                                            <span className={"sr-only"}>{t("accessibility.actions")}</span>
                                        </TableHeader>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {mfa_pk.map((pk) => (
                                        <TableRow key={pk.uuid}>
                                            <TableCell>{pk.label}</TableCell>
                                            <TableCell>{new Date(pk.created_at).toLocaleDateString()}</TableCell>
                                            <TableCell>
                                                <Button
                                                    plain={true}
                                                    onClick={() => {
                                                        deleteKey(pk).then();
                                                    }}
                                                >
                                                    <TrashIcon />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </>
                    )}
                </div>

                <div className={"flex flex-col gap-3"}>
                    <Form onSubmit={() => setOpenAddTotp(true)}>
                        <Fieldset>
                            <Legend>{t("profile.heading.totp")}</Legend>
                            <FieldGroup>
                                <Field>
                                    <Label>{t("label.label")}</Label>
                                    <Description>{t("description.label-totp")}</Description>
                                    <Input
                                        required={true}
                                        value={totpLabel}
                                        onChange={(e) => {
                                            setTotpLabel(e.target.value);
                                        }}
                                    />
                                </Field>

                                <Button type={"submit"} className={"w-full"}>
                                    {t("button.add-totp")}
                                </Button>
                            </FieldGroup>
                        </Fieldset>
                    </Form>

                    {totpApps.length > 0 && (
                        <>
                            <Subheading className={"mt-6"}>{t("profile.heading.existing-totp-apps")}</Subheading>
                            <Table dense={true}>
                                <TableHead>
                                    <TableRow>
                                        <TableHeader>{t("label.label")}</TableHeader>
                                        <TableHeader>{t("label.created-at")}</TableHeader>
                                        <TableHeader className={"w-0"}>
                                            <span className={"sr-only"}>{t("accessibility.actions")}</span>
                                        </TableHeader>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {totpApps.map((app) => (
                                        <TableRow key={app.uuid}>
                                            <TableCell>{app.label}</TableCell>
                                            <TableCell>{new Date(app.created_at).toLocaleDateString()}</TableCell>
                                            <TableCell>
                                                <Button
                                                    plain={true}
                                                    onClick={() => {
                                                        deleteTotp(app).then();
                                                    }}
                                                >
                                                    <TrashIcon />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </>
                    )}
                </div>
            </div>

            {openSudoMode && (
                <DialogSudoMode
                    open={true}
                    onSubmit={async () => {
                        setOpenSudoMode(false);

                        if (action !== undefined) {
                            await action;
                            setAction(undefined);
                        }
                    }}
                    onClose={() => {}}
                />
            )}

            {openAddTotp && (
                <DialogAddTotp
                    endpoint={"profile"}
                    open={true}
                    onSubmit={() => {
                        refreshTotpApps().then();
                        setTotpLabel("");
                        toast.success(t("toast.set-totp"));
                        setOpenAddTotp(false);
                    }}
                    onClose={() => setOpenAddTotp(false)}
                    label={totpLabel}
                    mail={user.mail}
                />
            )}

            {openMissingDevice && (
                <DialogMissingDevice
                    open={true}
                    onClose={() => setOpenMissingDevice(false)}
                    onRetry={() => {
                        setOpenMissingDevice(false);
                        setKey().then();
                    }}
                />
            )}
        </div>
    );
}
